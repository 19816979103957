import React, { Component } from "react";
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Slide from '@mui/material/Slide';
import axios from "axios";
import Grid from '@mui/material/Grid';
import BoltIcon from '@mui/icons-material/Bolt';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import * as locales from 'react-date-range/dist/locale';

export default class ProjetPeriode extends Component {

    constructor(props) {
        super(props);
        this.state = {
            anchorEl: null,

        }
        this.handleClick = this.handleClick.bind(this)
        this.handleSelect = this.handleSelect.bind(this)
    }

    handleClick(event){
        this.setState({anchorEl: event.currentTarget})
    }

    handleSelect(ranges){
        this.setState(
            {startDate: ranges.selection.startDate, endDate:ranges.selection.endDate},
            () => this.props.onChange(ranges.selection.startDate, ranges.selection.endDate)
        )
    }

    render(){

        const {anchorEl} = this.state
        
        const selectionRange = {
            startDate: this.props.startDate,
            endDate: this.props.endDate,
            key: 'selection',
        }

        const displaydate = (value) => {
            var date = new Date(value);
            if(date.getMonth() == 0){
                return date.getDate() + " janvier " + date.getFullYear()
            }
            if(date.getMonth() == 1){
                return date.getDate() + " Février " + date.getFullYear()
            }
            if(date.getMonth() == 2){
                return date.getDate() + " Mars " + date.getFullYear()
            }
            if(date.getMonth() == 3){
                return date.getDate() + " Avril " + date.getFullYear()
            }
            if(date.getMonth() == 4){
                return date.getDate() + " Mai " + date.getFullYear()
            }
            if(date.getMonth() == 5){
                return date.getDate() + " Juin " + date.getFullYear()
            }
            if(date.getMonth() == 6){
                return date.getDate() + " Juillet " + date.getFullYear()
            }
            if(date.getMonth() == 7){
                return date.getDate() + " Août " + date.getFullYear()
            }
            if(date.getMonth() == 8){
                return date.getDate() + " Septembre " + date.getFullYear()
            }
            if(date.getMonth() == 9){
                return date.getDate() + " Octobre " + date.getFullYear()
            }
            if(date.getMonth() == 10){
                return date.getDate() + " Novembre " + date.getFullYear()
            }
            if(date.getMonth() == 11){
                return date.getDate() + " Décembre " + date.getFullYear()
            }
        }

        return (
            <React.Fragment>
                <Card elevation={1}  sx={{height:70, p:2, backgroundColor:'#ffffff', borderRadius:3, display: 'flex', alignItems: 'center',}}>
                    <CalendarTodayIcon  sx={{color:"#2f4cdd", fontSize:34 }} />

                    <Box sx={{display: 'flex', flexDirection:'column', alignItems: 'left', }} >
                        <Typography sx={{color:'#46505b', pl:1}}>Période</Typography>
                        <Typography sx={{color:'gray', pl:1, fontSize:14}}>Du {displaydate(this.props.startDate)} - au {displaydate(this.props.endDate)}</Typography>
                    </Box>

                    <Box sx={{ flexGrow: 1 }} />

                    <IconButton onClick={this.handleClick} >
                        <ExpandMoreIcon />
                    </IconButton>

                    <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={() => {this.setState({ anchorEl: null})}}
                        PaperProps={{
                            sx: {
                                mt:4,
                                ml: '-10px',
                            }
                        }}
                    >
                        <DateRangePicker
                            ranges={[selectionRange]}
                            onChange={this.handleSelect}
                            direction="horizontal"
                            preventSnapRefocus={true}
                            moveRangeOnFirstSelection={false}
                            months={2}
                            scroll = {{enabled: true}}
                            locale={locales['fr']}
                        />
                    </Menu>
                </Card>
            </React.Fragment>
        )

    }

}