import React, { Component } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Asside from "./Asside"
import Dashbord from "../Pages/Dashboard"
import Projets from "../Pages/Projets"
import Filess from "../Pages/Filess"
import Borderau from "../Pages/Borderau"
import Materiels from "../Pages/Materiels"
import Personnels from "../Pages/Personnels"
import Fournitures from "../Pages/Fournitures"
import SousTraitants from "../Pages/SousTraitants"
import Caisse from "../Pages/Caisse"
import GestCarburants from "../Pages/GestCarburants"
import Approvisionnement from "../Pages/Approvisionnement"
import MaterielsUse from "../Pages/MaterielsUse" 
import PersonnelsUse from "../Pages/PersonnelsUse"
import FournituresUse from "../Pages/FournituresUse"
import SousTraitantsUse from "../Pages/SousTraitantsUse"
import CaissesUse from "../Pages/CaisseUse"
import Rapportsjournaliers from "../Pages/Rapportsjournaliers"
import Rapports from "../Pages/Rapports"
import Carburants from "../Pages/Carburants"
import Prdetailles from "../Pages/Prdetailles"
import Prmoyens from "../Pages/Prmoyens"
import Rdepenses from "../Pages/Rdepenses"
import Rdprix from "../Pages/Rdprix"
import Utilisations from "../Pages/Utilisations"
import Efficiences from "../Pages/Efficiences"
import Statistiques from "../Pages/Statistiques"
import Utilisateurs from "../Pages/Utilisateurs"
import Profil from "../Pages/Profil"
import Depense from "../Pages/Depense"
import Piecesusures from "../Pages/PiecesUsures"
import ApproPusure from "../Pages/ApproPusure"
import PieceusureUse from "../Pages/PieceusureUse"
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import { styled } from '@mui/material/styles';


const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const drawerWidth = 320;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: `-${drawerWidth}px`,
        ...(open && {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: 0,
            width: `calc(100% - ${drawerWidth}px)`,
        }),
    }),
);

export default class Layout extends Component {

    constructor(props) {
        super(props)
        this.state = {
            open: true,
            projetId: null,
            startDate: new Date(),
            endDate: new Date(),
            role: null
        }
        this.eventhandler = this.eventhandler.bind(this)
        this.openChange= this.openChange.bind(this)
        this.projetIdChange = this.projetIdChange.bind(this)
    }

    componentDidMount() {
       const role = JSON.parse(localStorage.getItem("ROLE"));
        if (role === null) {
            window.location.href = '/';
        } else {
            this.setState({ role: role });
        }
        if (localStorage.getItem("ID") !== null) {
            this.setState({projetId: JSON.parse(localStorage.getItem("ID"))})
        }
    }

    eventhandler(data, start, end){ 
        this.setState({
            projetId: data,
            startDate: start,
            endDate: end
        })
    }

    projetIdChange(projetId, startDate, endDate){ this.setState({ projetId: projetId, startDate:startDate, endDate: endDate }) }

    openChange(open){
        this.setState({ open: open })
    }
    
    render() {

        const { open, projetId, role, startDate, endDate } = this.state

        return (
            <BrowserRouter>
                <Box sx={{ display: 'flex', bgcolor:'#f2f4fa' }}>
                    <CssBaseline />
                    
                    <Asside  onChange={this.openChange}/>       

                    <Main open={open}  sx={{ pt:10 }}>   
                        <DrawerHeader />
                        
                        <Routes >
                            {role === 'Administrateur' && <React.Fragment>
                                <Route 
                                    path="dashboard" 
                                    element={<Dashbord  projetId={projetId} onChange={this.projetIdChange} />} 
                                />

                                <Route 
                                    path="projets" 
                                    element={<Projets projetId={projetId} onChange={this.projetIdChange} />}
                                /> 

                                <Route 
                                    path="files" 
                                    element={<Filess projetId={projetId} onChange={this.projetIdChange} />}
                                />                                
                                
                                <Route 
                                    path="borderau" 
                                    element={<Borderau projetId={projetId} onChange={this.projetIdChange} />}
                                /> 

                                <Route 
                                    path="materiels" 
                                    element={<Materiels projetId={projetId} onChange={this.projetIdChange} />}
                                />

                                <Route 
                                    path="personnels" 
                                    element={<Personnels projetId={projetId} onChange={this.projetIdChange} />}
                                />

                                <Route 
                                    path="fournitures" 
                                    element={<Fournitures projetId={projetId} onChange={this.projetIdChange} />}
                                />

                                <Route 
                                    path="sous-traitants" 
                                    element={<SousTraitants projetId={projetId} onChange={this.projetIdChange} />}
                                />

                                <Route 
                                    path="pieces-usure" 
                                    element={<Piecesusures projetId={projetId} onChange={this.projetIdChange} />}
                                />

                                <Route 
                                    path="caisse"  
                                    element={<Caisse projetId={projetId} onChange={this.projetIdChange} />}
                                />

                                <Route 
                                    path="depenses"  
                                    element={<Depense projetId={projetId} onChange={this.projetIdChange} />}
                                />

                                <Route 
                                    path="use-piece-usure"  
                                    element={<PieceusureUse projetId={projetId} onChange={this.projetIdChange} />}
                                />

                                <Route 
                                    path="ravitaillement" 
                                    element={<GestCarburants projetId={projetId} onChange={this.projetIdChange} />}
                                />

                                <Route 
                                    path="approvisionnement" 
                                    element={<Approvisionnement projetId={projetId} onChange={this.projetIdChange} />}
                                />

                                <Route 
                                    path="appro-pusure" 
                                    element={<ApproPusure projetId={projetId} onChange={this.projetIdChange} />}
                                />

                                <Route 
                                    path="use-materiels" 
                                    element={<MaterielsUse projetId={projetId} onChange={this.projetIdChange} />}
                                />

                                <Route 
                                    path="use-personnels" 
                                    element={<PersonnelsUse projetId={projetId} onChange={this.projetIdChange} />}
                                />

                                <Route 
                                    path="use-fournitures" 
                                    element={<FournituresUse projetId={projetId} onChange={this.projetIdChange} />}
                                />

                                <Route 
                                    path="use-sous-traitans" 
                                    element={<SousTraitantsUse projetId={projetId} onChange={this.projetIdChange} />}
                                />

                                <Route 
                                    path="use-caisse" 
                                    element={<CaissesUse projetId={projetId} onChange={this.projetIdChange} />}
                                />

                                <Route 
                                    path="rapports" 
                                    element={<Rapports projetId={projetId} onChange={this.projetIdChange} />}
                                />

                                <Route 
                                    path="carburants" 
                                    element={<Carburants projetId={projetId} onChange={this.projetIdChange} />}
                                />

                                <Route 
                                    path="rapportsjournaliers" 
                                    element={<Rapportsjournaliers projetId={projetId} onChange={this.projetIdChange} />}
                                />

                                <Route 
                                    path="prdetailles" 
                                    element={<Prdetailles projetId={projetId} startDate={startDate} endDate={endDate} onChange={this.projetIdChange} />}
                                />

                                <Route 
                                    path="prmoyens" 
                                    element={<Prmoyens projetId={projetId} startDate={startDate} endDate={endDate} onChange={this.projetIdChange} />}
                                />

                                <Route 
                                    path="rdepenses" 
                                    element={<Rdepenses projetId={projetId} startDate={startDate} endDate={endDate} onChange={this.projetIdChange} />} 
                                />

                                <Route 
                                    path="rdprix" 
                                    element={<Rdprix projetId={projetId} startDate={startDate} endDate={endDate} onChange={this.projetIdChange} />} 
                                />
                                
                                <Route 
                                    path="utilisations" 
                                    element={<Utilisations projetId={projetId} onChange={this.projetIdChange} />}
                                />

                                <Route 
                                    path="efficiences" 
                                    element={<Efficiences projetId={projetId} startDate={startDate} endDate={endDate} onChange={this.projetIdChange} />}
                                />

                                <Route 
                                    path="statistiques" 
                                    element={<Statistiques projetId={projetId} startDate={startDate} endDate={endDate} onChange={this.projetIdChange} />}
                                />

                                <Route 
                                    path="utilisateurs" 
                                    element={<Utilisateurs projetId={projetId} onChange={this.projetIdChange} />}
                                />

                                <Route 
                                    path="profil" 
                                    element={<Profil projetId={projetId} onChange={this.projetIdChange} />}
                                />
                            </React.Fragment>}

                            {role === 'Gestion des matériels' && <React.Fragment>
                                <Route 
                                    path="borderau" 
                                    element={<Borderau projetId={projetId} onChange={this.projetIdChange} />}
                                />

                                <Route 
                                    path="materiels" 
                                    element={<Materiels projetId={projetId} onChange={this.projetIdChange} />}
                                />

                                <Route 
                                    path="ravitaillement" 
                                    element={<GestCarburants projetId={projetId} onChange={this.projetIdChange} />}
                                />

                                <Route 
                                    path="use-materiels" 
                                    element={<MaterielsUse projetId={projetId} onChange={this.projetIdChange} />}
                                />

                                <Route 
                                    path="profil" 
                                    element={<Profil projetId={projetId} onChange={this.projetIdChange} />}
                                />                     
                            </React.Fragment>}

                            {role === 'Gestion des personnels' && <React.Fragment>
                                <Route 
                                    path="borderau" 
                                    element={<Borderau projetId={projetId} onChange={this.projetIdChange} />}
                                />

                                <Route 
                                    path="personnels" 
                                    element={<Personnels projetId={projetId} onChange={this.projetIdChange} />}
                                />

                                <Route 
                                    path="use-personnels" 
                                    element={<PersonnelsUse projetId={projetId} onChange={this.projetIdChange} />}
                                />

                                <Route 
                                    path="profil" 
                                    element={<Profil projetId={projetId} onChange={this.projetIdChange} />}
                                />                                       
                            </React.Fragment>}

                            {role === 'Gestion des matériaux' && <React.Fragment>
                                <Route 
                                    path="borderau" 
                                    element={<Borderau projetId={projetId} onChange={this.projetIdChange} />} 
                                />

                                <Route 
                                    path="fournitures" 
                                    element={<Fournitures projetId={projetId} onChange={this.projetIdChange} />} 
                                />

                                <Route 
                                    path="approvisionnement" 
                                    element={<Approvisionnement projetId={projetId} onChange={this.projetIdChange} />} 
                                />

                                <Route 
                                    path="use-fournitures" 
                                    element={<FournituresUse projetId={projetId} onChange={this.projetIdChange} />} 
                                />

                                <Route 
                                    path="profil" 
                                    element={<Profil projetId={projetId} onChange={this.projetIdChange} />} 
                                />                                       
                            </React.Fragment>}

                            {role === 'Gestion des sous-traitant' && <React.Fragment>
                                <Route 
                                    path="borderau" 
                                    element={<Borderau projetId={projetId} onChange={this.projetIdChange} />}
                                />
                              
                                <Route 
                                    path="sous-traitants" 
                                    element={<SousTraitants projetId={projetId} onChange={this.projetIdChange} />}
                                />  

                                <Route 
                                    path="use-sous-traitans" 
                                    element={<SousTraitantsUse projetId={projetId} onChange={this.projetIdChange} />}
                                />                            
 
                                <Route 
                                    path="profil" 
                                    element={<Profil projetId={projetId} onChange={this.projetIdChange} />}
                                />       
                            </React.Fragment>}

                            {role === 'Gestion de la caisse' && <React.Fragment>
                                <Route 
                                    path="borderau" 
                                    element={<Borderau projetId={projetId} onChange={this.projetIdChange} />} 
                                />

                                <Route 
                                    path="caisse"  
                                    element={<Caisse projetId={projetId} onChange={this.projetIdChange} />} 
                                />

                                <Route 
                                    path="depenses"  
                                    element={<Depense projetId={projetId} onChange={this.projetIdChange} />} 
                                />

                                <Route 
                                    path="use-caisse" 
                                    element={<CaissesUse projetId={projetId} onChange={this.projetIdChange} />}
                                />

                                <Route 
                                    path="profil" 
                                    element={<Profil projetId={projetId} onChange={this.projetIdChange} />}
                                />                                       
                            </React.Fragment>}         
                        </Routes>

                        {role !== 'Administrateur' &&
                            <Box className="card" style={{ height: 630 }} >
                                <h3 style={{margin:"auto", fontSize:"32px !important"}}>Accès non autorisé !</h3>
                            </Box>  
                        }
                    </Main>
                </Box>
            </BrowserRouter>
        )
    }
}