import React, { Component } from "react";
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Slide from '@mui/material/Slide';
import axios from "axios";
import Grid from '@mui/material/Grid';
import BoltIcon from '@mui/icons-material/Bolt';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import * as locales from 'react-date-range/dist/locale';

export default class ProjetMenu extends Component {

    constructor(props) {
        super(props);
        this.state = {
            projects:[],
            projetAuthorized: [],

            anchorEl: null,

            projetId: this.props.projetId,

            search: ''
        }
        this.handleClick = this.handleClick.bind(this)
        this.onchangesearch = this.onchangesearch.bind(this)
    }

    componentDidMount() {
        const projetAuthorized = JSON.parse(localStorage.getItem("PROJETS")); 
        const token = JSON.parse(localStorage.getItem("TOKEN"));        
        
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        }

        axios.get('https://api-ml.projectmanagementsolutions.net/api/projects', config)
        .then(response => {
            this.setState({ projects: response.data.projects, projetAuthorized: JSON.parse(projetAuthorized) });
        }).catch(error => {
            alert(error);
        });
    }

    handleClick(event){
        this.setState({anchorEl: event.currentTarget})
    }

    onchangesearch(e){
        this.setState({ search: e.target.value });
    }

    selectProjet(id){
        const project = this.state.projects.filter(c => c["id"] === id)        
        localStorage.setItem("ID", id); 
                           
        let resultat = []
        this.state.projects.map((answer) => {
            if(this.state.projetAuthorized.filter(c => c["id"] === answer.id)[0] !== undefined){                        
                resultat = resultat.concat(answer)
            }
        })

        if(resultat.filter(c => c["id"] === id)[0] !== undefined  ){
            this.setState({
                search:'',
                projetId: id,
                anchorEl: null
            },
            () => {
                if (this.props.onChange) {
                    this.props.onChange(
                        this.state.projetId
                    );
                }
            }
            
            )
        }

    }   


    render() {

        const { anchorEl, search, projetId, projetAuthorized} = this.state

        const projects = () => {
            let resultat = []
            this.state.projects.map((answer) => {
                if(projetAuthorized.filter(c => c["id"] === answer.id)[0] !== undefined){
                    resultat = resultat.concat(answer)
                }  
            })
            
            if(search !== ''){
                const solv = resultat.filter(elem => { return elem.name.toLowerCase().indexOf(search.toLowerCase()) !== -1;})
                return solv
            }
            return resultat
        }   

        return (<React.Fragment>
            <Card elevation={1}  sx={{height:70, p:2, backgroundColor:'#ffffff', borderRadius:3, display: 'flex', alignItems: 'center',}}>
                <BoltIcon  sx={{color:"#2f4cdd", fontSize:34 }} />

                <Typography sx={{color:'46505b'}}>Projets</Typography>

                <Box sx={{ flexGrow: 1 }} />

                <IconButton onClick={this.handleClick}>
                    <ExpandMoreIcon />
                </IconButton>

                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={() => {this.setState({ anchorEl: null})}}
                    PaperProps={{
                        sx: {
                            width: 640,
                            overflow: 'visible',
                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                            mt:4,
                            ml: '-10px',
                            '& .MuiAvatar-root': {
                                width: 32,
                                height: 32,
                                ml: -0.5,
                                mr: 1,
                            },
                            '&:before': {
                                content: '""',
                                display: 'block',
                                position: 'absolute',
                                top: 0,
                                right: 30,
                                width: 10,
                                height: 10,
                                bgcolor: 'background.paper',
                                transform: 'translateY(-50%) rotate(45deg)',
                            }
                        }
                    }}
                >
                    <TextField
                        margin="normal"
                        sx={{ m: 1, width:`calc(100% - 16px)`, bgcolor:'#e9eff6', borderRadius:32 }}
                        fullWidth
                        label="Rechercher"
                        name="search"
                        type='text'
                        onChange={this.onchangesearch}
                        InputProps={{
                            startAdornment:
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        }}
                    />

                    {projects().map((projet, index) => (
                        <MenuItem 
                            onClick={() =>this.selectProjet(projet.id)} 
                            sx={projetId === projet.id ? {color:'#2f4cdd'} : null}
                        >
                            {projet.name.substring(0, 60)} | {projet.country + " - " + projet.location}
                        </MenuItem>
                    ))}
                </Menu>

            </Card>
        </React.Fragment>)

    }

}