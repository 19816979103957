import React, { Component } from "react";
import axios from 'axios';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import { DataGrid } from '@mui/x-data-grid';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import ReactToPrint from 'react-to-print';
import Tooltip  from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import PrintIcon from '@mui/icons-material/Print';
import Dropzone from 'react-dropzone'
import { CSVLink } from "react-csv";
import Avatar from '@mui/material/Avatar';
import AnnouncementOutlinedIcon from '@mui/icons-material/AnnouncementOutlined';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import csbup from "../../Assets/Images/csbup.png"
import csbdown from '../../Assets/Images/csbdown.png'
import csvup from '../../Assets/Images/csvup.png'
import ProjetMenu  from "../../Layouts/ProjetMenu";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default class Borderau extends Component { 

    constructor(props) {
        super(props)
        this.state = {
            bordereaux:[],
            projects:[],


            open:false, 
            isDid:false,
            isNotDit:false,
            voir: false,
            openEdit: false,
            importation: false,
            loading: true,

            projetId: this.props.projetId,

            id:null,
            n_prix: null,
            designation: null,
            unite: "J", 
            prix: 0, 
            quantite: 0, 
            gm: 0, 
            carburant: 0, 
            p_usure: 0, 
            mo: 0, 
            bp: 0, 
            mtx: 0, 
            straitant: 0, 

            selectedFile: null,

            path:"",
            role: null,
            message:'',
        }
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.idchange = this.idchange.bind(this)
    }

    componentDidMount() { 
        this.setState({ role : JSON.parse(localStorage.getItem("ROLE"))})
        const token = JSON.parse(localStorage.getItem("TOKEN"))
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };
        axios.get('https://api-ml.projectmanagementsolutions.net/api/bordereau', config)
        .then(response => {            
            this.setState({ 
                projects: response.data.projects,
                bordereaux: response.data.bordereaux,
                loading: false
            });
        }).catch(error => {
            console.log("error", error)
        });
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleSubmit(event) {
        event.preventDefault()
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);        
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };

        const bodyParameters = {
            id_projet: this.state.projetId, 
            n_prix: this.state.n_prix, 
            designation: this.state.designation, 
            unite: this.state.unite, 
            prix: this.state.prix, 
            quantite: this.state.quantite, 
            gm: 0, 
            carburant: 0, 
            p_usure: 0, 
            mo: 0, 
            bp: 0, 
            mtx: 0, 
            straitant: 0, 
        };  

        axios.post( 
            'https://api-ml.projectmanagementsolutions.net/api/bordereau',
            bodyParameters,
            config
        ).then(response => {       
            console.log("return", response.data)     
            this.setState({ message:"Numéro de prix ajouté avec succès", isDid: true, open:false });
            this.componentDidMount()            
        }).catch(error => {
            this.setState({ message:"Erreur d'enregistrement", isNotDit: true });
            console.log("error", error)
        });
    }

    delete(id){
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);        
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };
        axios.delete( 
            'https://api-ml.projectmanagementsolutions.net/api/bordereau/' + id,
            config
        ).then(response => {     
            this.setState({message:"Numéro de prix supprimé avec succès", isDid: true });
            this.componentDidMount()
            
        }).catch(error => {
            this.setState({ message:"Erreur de suppression", isNotDit: true });
            console.log("error", error)
        });    

    }

    fileDrop(acceptedFiles){
        console.log("dddddddddd", acceptedFiles[0]);
        this.setState({selectedFile: acceptedFiles[0],  path: acceptedFiles[0].path})
    }

    onFileUpload(){ 
        // Create an object of formData 
        const formData = new FormData(); 
        // Update the formData object 
        formData.append("File", this.state.selectedFile)
        formData.append("id_project", this.state.projetId)
        axios.post('https://api-ml.projectmanagementsolutions.net/api/bordereau-import', formData)
        .then(response => {      
            this.setState({message: "Importé avec succès", isDid: true, importation:false });
            this.componentDidMount()
            
        }).catch(error => {
            this.setState({ message:"Erreur d'importation", isNotDit: true });
            console.log("error", error)
        });     
    }

    editTable(props){        
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);        
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        }
        let bodyParameters = {}
        if(props.field === "n_prix"){
            bodyParameters = {
                id_projet: props.row.id_projet, 
                n_prix: props.value, 
                designation: props.row.designation, 
                unite: props.row.unite, 
                prix: props.row.prix, 
                quantite: props.row.quantite, 
                gm: props.row.gm, 
                carburant: props.row.carburant, 
                p_usure: props.row.p_usure, 
                mo: props.row.mo, 
                bp: props.row.bp, 
                mtx: props.row.mtx,  
                straitant: props.row.straitant, 
            }  
        }else if(props.field === "designation"){
            bodyParameters = {
                id_projet: props.row.id_projet, 
                n_prix: props.row.n_prix, 
                designation: props.value, 
                unite: props.row.unite, 
                prix: props.row.prix, 
                quantite: props.row.quantite, 
                gm: props.row.gm, 
                carburant: props.row.carburant, 
                p_usure: props.row.p_usure, 
                mo: props.row.mo, 
                bp: props.row.bp, 
                mtx: props.row.mtx,  
                straitant: props.row.straitant, 
            }  
        }else if(props.field === "unite"){
            bodyParameters = {
                id_projet: props.row.id_projet, 
                n_prix: props.row.n_prix, 
                designation: props.row.designation, 
                unite: props.value, 
                prix: props.row.prix, 
                quantite: props.row.quantite, 
                gm: props.row.gm, 
                carburant: props.row.carburant, 
                p_usure: props.row.p_usure, 
                mo: props.row.mo, 
                bp: props.row.bp, 
                mtx: props.row.mtx,  
                straitant: props.row.straitant, 
            }  
        }else if(props.field === "prix"){
            bodyParameters = {
                id_projet: props.row.id_projet, 
                n_prix: props.row.n_prix, 
                designation: props.row.designation, 
                unite: props.row.unite, 
                prix: props.value, 
                quantite: props.row.quantite, 
                gm: props.row.gm, 
                carburant: props.row.carburant, 
                p_usure: props.row.p_usure, 
                mo: props.row.mo, 
                bp: props.row.bp, 
                mtx: props.row.mtx,  
                straitant: props.row.straitant, 
            }  
        }else if(props.field === "quantite"){
            bodyParameters = {
                id_projet: props.row.id_projet, 
                n_prix: props.row.n_prix, 
                designation: props.row.designation, 
                unite: props.row.unite, 
                prix: props.row.prix, 
                quantite: props.value, 
                gm: props.row.gm, 
                carburant: props.row.carburant, 
                p_usure: props.row.p_usure, 
                mo: props.row.mo, 
                bp: props.row.bp, 
                mtx: props.row.mtx,  
                straitant: props.row.straitant, 
            }  
        }

        axios.put( 
            'https://api-ml.projectmanagementsolutions.net/api/bordereau/' + props.id,
            bodyParameters,
            config
        ).then(response => {                     
            this.setState({ message:"Numéro de prix modifié avec succès", isDid: true});
            this.componentDidMount()          
        }).catch(error => {
            this.setState({ message:"Erreur dans le modification", isNotDit: true });
            console.log("error", error)
        });    
        
    }

    idchange(id){ 
        this.setState({
            projetId: id
        })
    }

    render() { 

        const { message, projetId, role, path, loading, importation, openEdit, id, voir, n_prix, isNotDit, isDid, designation, unite, prix,  quantite, gm, carburant, p_usure, mo, bp, mtx, straitant, open, bordereaux, projects } = this.state

        const id_project = projetId

        let bordereau = []
        if(id_project === null){
            bordereau = []
        }else{
            bordereau = bordereaux.filter(c => c["id_projet"] === id_project)
        }

        const getTotal = ()=> {
            let total = 0
            bordereau.map((answer) => {
                total = total + answer.prix * answer.quantite
            })
            return total
        }

        const displayTotal = (nprix) => {
            let total = 0
            let data = bordereau.filter(x => x.n_prix.startsWith(nprix.charAt(0)))
            data.map((answer) => {
                total = total + (answer.prix * answer.quantite)
            })
            return total
        }

        let columns = []
        if(role === 'Administrateur'){
            columns = [
                { 
                    field: 'n_prix', 
                    headerName: 'N° prix',
                    editable: true,
                    flex: 1/16,
                    renderCell: (cellValues) => {
                        if( (cellValues.row.n_prix % 100) === 0 ) {
                            return <strong>{cellValues.row.n_prix}</strong>
                        }else{
                            return cellValues.row.n_prix
                        }                                       
                    }
                },
                { 
                    field: 'designation', 
                    headerName: 'Désignation',
                    flex: 4/8,
                    editable: true,
                    renderCell: (cellValues) => {
                        if( (cellValues.row.n_prix % 100) === 0 ) {
                            return <strong>{cellValues.row.designation}</strong>
                        }else{
                            return cellValues.row.designation
                        }                                       
                    }
                },
                { 
                    field: 'unite', 
                    headerName: 'Unité',
                    flex: 1/16,
                    editable: true,
                    renderCell: (cellValues) => {
                        if( (cellValues.row.n_prix % 100) === 0 ) {
                            return ""
                        }else{
                            return cellValues.row.unite
                        }                                       
                    }
                },
                { 
                    field: 'quantite', 
                    headerName: 'Quantité',
                    flex: 1/8,
                    type: 'number',
                    editable: true,
                    renderCell: (cellValues) => {
                        if( (cellValues.row.n_prix % 100) === 0 ) {
                            return ""
                        }else{
                            return cellValues.row.quantite.toLocaleString()  
                        }                                       
                    }
                },
                { 
                    field: 'prix', 
                    headerName: 'Prix unitaire',
                    editable: true,
                    type: 'number',
                    flex: 1/8,
                    renderCell: (cellValues) => {
                        if( (cellValues.row.n_prix % 100) === 0 ) {
                            return ""
                        }else{
                            return cellValues.row.prix.toLocaleString()  
                        }                                       
                    }
                },
                
                { 
                    headerName: 'Montant',
                    flex: 1/8,
                    type: 'number',
                    renderCell: (cellValues) => {
                        if( (cellValues.row.n_prix % 100) === 0 ) {
                            return ""
                        }else{
                            return (cellValues.row.prix * cellValues.row.quantite).toLocaleString()       
                        }                                       
                    }
                },
                { 
                    field: 'total', 
                    headerName: 'Total',
                    flex: 1/8,
                    type: 'number',
                    renderCell: (cellValues) => {
                        if( (cellValues.row.n_prix % 100) === 0 ) {
                            return <strong>{displayTotal(cellValues.row.n_prix).toLocaleString()}</strong>  
                        }else{
                            return ""
                        }                                       
                    }
                },
                { 
                    field: 'delete', 
                    headerName: '',
                    flex: 1/8,
                    renderCell: (cellValues) => {
                        return (
                            <Button
                                onClick={() => { if (window.confirm('Êtes-vous sûr de le supprimer ?')){ this.delete(cellValues.row.id) }}}
                                variant="contained"
                                sx={{background:'#eff0f2', color:'black', height:30, ':hover':{background:'#e6374c', color:'white'}}}
                            >
                                Supprimer
                            </Button>
                        )
                        
                    }
                }
            ]
        }else{
            columns = [
                { 
                    field: 'n_prix', 
                    headerName: 'N° prix',
                    flex: 1/6,
                    renderCell: (cellValues) => {
                        if( (cellValues.row.n_prix % 100) === 0 ) {
                            return <strong>{cellValues.row.n_prix}</strong>
                        }else{
                            return cellValues.row.n_prix
                        }                                       
                    }
                },
                { 
                    field: 'designation', 
                    headerName: 'Désignation',
                    flex: 3/3,
                    renderCell: (cellValues) => {
                        if( (cellValues.row.n_prix % 100) === 0 ) {
                            return <strong>{cellValues.row.designation}</strong>
                        }else{
                            return cellValues.row.designation
                        }                                       
                    }
                },
                { 
                    field: 'unite', 
                    headerName: 'Unité',
                    flex: 1/6,
                    renderCell: (cellValues) => {
                        if( (cellValues.row.n_prix % 100) === 0 ) {
                            return ""
                        }else{
                            return cellValues.row.unite
                        }                                       
                    }
                }
            ]
        }

        const headers = [
            { label: "N prix", key: "n_prix" },
            { label: "Désignation", key: "designation" },
            { label: "Unité", key: "unite" },
            { label: "Prix", key: "prix" },
            { label: "Quantité", key: "quantite" },
            { label: "GM", key: "gm" },
            { label: "Carburant", key: "carburant" },
            { label: "P usure", key: "pusure" },
            { label: "Mo", key: "mo" },
            { label: "Mtx", key: "mtx" },
            { label: "S-traitant", key: "straitant" }
        ]

        return ( 
            <React.Fragment>
                <Grid sx={{ flexGrow: 1, mb:2}} container spacing={3} >
                    <Grid item xs={12} sm={6} md={2} >
                        <Button 
                            variant="contained" 
                            sx={{bgcolor:'#2f4cdd', textTransform: 'none', fontSize:16, height:50}} 
                            fullWidth
                            startIcon={<Avatar  sx={{ bgcolor: '#4777f4', height:28, width:28 }}><AddIcon sx={{fontSize:26}} /></Avatar>}
                            onClick={() => this.setState({ open: true })}
                        >
                            Ajouter num. de prix
                        </Button>
                    </Grid>

                    <Grid item xs={12} sm={6} md={8} >
                        <Box style={{display: 'flex', flexDirection:'row'}}>
                            <Button 
                                variant="contained" 
                                sx={{bgcolor:'#5d95ed', height:50, ':hover':{background:'#5d95ed'}}}
                                onClick={() => this.setState({ importation: true })}
                            >
                                <img src={csbup} style={{width:40}}/>
                            </Button>

                            <CSVLink 
                                data={bordereau} 
                                headers={headers} 
                                filename={"bordereaux.csv"} 
                                className="btn" 
                                style={{height:50, backgroundColor:"#5d95ed", marginLeft:24, borderRadius:4, width:70}}
                            >
                                <img src={csbdown} style={{width:40, marginTop:'-6px', marginLeft:'-6px'}}/>
                            </CSVLink> 

                            <ReactToPrint
                                trigger={() => {
                                    return  (
                                        <Button 
                                            variant="contained" 
                                            sx={{bgcolor:'#5d95ed', height:50, ml:3}}
                                        >
                                            <PrintIcon sx={{fontSize:40}} /> 
                                        </Button>
                                    );
                                }}
                                content={() => this.componentRef}
                            />                             

                            <Tooltip 
                                title={<React.Fragment>
                                    <Typography color="inherit" sx={{fontSize:20}} >Bordereau des prix</Typography>
                                    <Typography color="inherit" sx={{fontSize:16}} >
                                    Le bordereau de prix est composé de la liste des numéros de prix utilisés pour le prix de revient suivie de leurs differentes désignations 
                                    et d' autres informations sur les prix (Unité; prix budget, etc ... à remplir) 
                                    </Typography>
                                </React.Fragment>
                                }
                            >                        
                                <Button 
                                    variant="contained" 
                                    sx={{bgcolor:'#5d95ed', height:50, ml:3}}
                                >
                                    <AnnouncementOutlinedIcon/>
                                </Button>
                            </Tooltip>
                        </Box>
                    </Grid>

                    <Grid item xs={12} sm={6} md={2} >
                        <ProjetMenu projetId={id_project} onChange={this.idchange} />
                    </Grid>                    
                </Grid> 

                <Grid item xs={12} sm={6} md={12} >
                    <div className="card" style={{ height: 570, width: '100%',borderRadius:16 }}>
                        <DataGrid
                            rows={bordereau}
                            columns={columns}
                            onCellEditCommit={(props, event) => this.editTable(props)}
                            loading={loading}
                            elevation={10}
                            sx={{
                                boxShadow: 2,
                                borderRadius:4,
                                '.MuiDataGrid-columnSeparator': {
                                    display: 'none',
                                    },
                                    '&.MuiDataGrid-root': {
                                    border: 'none',
                                    },
                                '.css-1jbbcbn-MuiDataGrid-columnHeaderTitle': {
                                    color:'black',
                                    fontWeight: 600,
                                    fontSize:18
                                }
                            }}
                        
                        />                        
                    </div>

                    {role === 'Administrateur' && 
                        <div className="card" style={{marginTop:16, padding:"24px 64px 16px 16px", textAlign:'right'}}> 
                            <p>Montant Total: {getTotal().toLocaleString()}</p>                            
                        </div>
                    }
                </Grid> 

                <div  style={{ display: "none" }}>                
                    <table className="table table-striped table-hover"  ref={el => (this.componentRef = el)}>
                        <thead>
                            <tr>
                                <th scope="col">N° prix</th>
                                <th scope="col">Désignation</th>
                                <th scope="col">Unité</th>
                                <th scope="col">Quantité</th>
                                <th scope="col">Prix unitaire</th>
                            </tr>
                        </thead>
                        <tbody>
                            {bordereau.map((answer) => {
                                return (
                                    <tr>
                                        <td>{answer.n_prix}</td>
                                        <td>{answer.designation}</td>
                                        <td>{answer.unite}</td>
                                        <td>{answer.quantite}</td>
                                        <td>{answer.prix}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>                

                <Dialog
                    fullWidth={true}
                    maxWidth={"sm"}
                    open={open}
                    onClose={() => this.setState({ open: false })}      
                    sx={{'.css-tlc64q-MuiPaper-root-MuiDialog-paper':{bgcolor:'#fafafa'}}}                  
                >
                    <Box style={{display: 'flex', flexDirection:'row', justifyContent:'space-between', padding:32}} >
                        <Typography 
                            component="h1" 
                            variant="h1" 
                            sx={{color:"#313339", fontSize:26}}
                        >
                            Nouveau numéro de prix
                        </Typography>

                        <IconButton onClick={() =>  this.setState({ open: false })} >   
                            <CloseIcon /> 
                        </IconButton>
                    </Box>

                    <form role="form" className="text-start" onSubmit={this.handleSubmit}>
                        
                        <DialogContent >
                            <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                                <Grid item xs={12} md={12}>
                                    <div className="input-group input-group-outline mb-3">
                                        <TextField
                                            required
                                            id="outlined-required"
                                            label="N° prix"
                                            name="n_prix"
                                            placeholder="001"
                                            style={{ width: '100%' }}
                                            value={n_prix}
                                            onChange={this.handleChange}
                                            sx={{bgcolor:'#e9eff6'}}
                                        />
                                    </div>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <div className="input-group input-group-outline mb-4">
                                        <TextField
                                            required
                                            id="outlined-required"
                                            label="Désignation"
                                            name="designation"
                                            style={{ width: '100%' }}
                                            value={designation}
                                            onChange={this.handleChange}
                                            sx={{bgcolor:'#e9eff6'}}
                                        />
                                    </div>
                                </Grid>   

                                <Grid item xs={12} md={6}>
                                    <div className="input-group input-group-outline mb-4">
                                        <TextField
                                            required
                                            id="outlined-required"
                                            label="unité"
                                            name="unite"
                                            style={{ width: '100%' }}
                                            value={unite}
                                            onChange={this.handleChange}
                                            sx={{bgcolor:'#e9eff6'}}
                                        />
                                    </div>
                                </Grid>       

                                <Grid item xs={12} md={6}>
                                    <div className="input-group input-group-outline mb-4">
                                        <TextField
                                            required
                                            id="outlined-required"
                                            label="Prix"
                                            name="prix"
                                            type="number"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            style={{ width: '100%' }}
                                            value={prix}
                                            onChange={this.handleChange}
                                            sx={{bgcolor:'#e9eff6'}}
                                        />
                                    </div>
                                </Grid>     

                                <Grid item xs={12} md={6}>
                                    <div className="input-group input-group-outline mb-4">
                                        <TextField
                                            required
                                            id="outlined-required"
                                            label="Quantite"
                                            name="quantite"
                                            type="number"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            style={{ width: '100%' }}
                                            value={quantite}
                                            onChange={this.handleChange}
                                            sx={{bgcolor:'#e9eff6'}}
                                        />
                                    </div>
                                </Grid>                          
                            </Grid>
                        </DialogContent>

                        <Button  
                            type="submit" 
                            fullWidth
                            variant="contained" 
                            sx={{
                                color:"white", 
                                textTransform: 'none', 
                                fontSize:20, 
                                height:50, 
                                backgroundColor:'#888da0',  
                                borderRadius:0, 
                                ':hover':{backgroundColor:'#2f4cdd'} 
                            }}
                            endIcon={<ChevronRightIcon sx={{fontSize:20}} />}
                        >
                                Cliquer pour enregistrer le nouveau numéro de prix
                        </Button>                        
                    </form>                
                </Dialog>

                <Dialog
                    fullWidth={true}
                    maxWidth={"sm"}
                    open={importation}
                    onClose={() => this.setState({ importation: false })}      
                    sx={{'.css-tlc64q-MuiPaper-root-MuiDialog-paper':{bgcolor:'#fafafa'}}}              
                >
                    <Box style={{display: 'flex', flexDirection:'row', justifyContent:'space-between', padding:32}} >
                        <Typography 
                            component="h1" 
                            variant="h1" 
                            sx={{color:"#313339", fontSize:26, pl:16}}
                        >
                            Importer un fichier CSV 
                        </Typography>

                        <IconButton onClick={() =>  this.setState({ importation: false })} >   
                            <CloseIcon /> 
                        </IconButton>
                    </Box>

                    <DialogContent style={{textAlign: "center",  padding:'0px 170px' }}>
                        <Dropzone onDrop={acceptedFiles  => this.fileDrop(acceptedFiles)}>
                            {({getRootProps, getInputProps}) => (
                                <section>
                                <div {...getRootProps()}>
                                    <input {...getInputProps()} />

                                    <img src={csvup} style={{ width: 260, marginLeft:'-50px', marginTop:80, marginBottom:20}} />
                                    
                                    {path === "" ? <p style={{marginTop:64}}>Faites glisser et déposez le fichier ici, ou cliquez pour sélectionner le fichier</p> : <p>{path}</p>}
                                </div>
                                </section>
                            )}
                        </Dropzone>
                    </DialogContent>

                    {path === "" ? null :
                        <Button  
                            onClick={() => this.onFileUpload()}
                            fullWidth
                            variant="contained" 
                            sx={{
                                color:"white", 
                                textTransform: 'none', 
                                fontSize:20, 
                                height:50, 
                                backgroundColor:'#888da0',  
                                borderRadius:0, 
                                ':hover':{backgroundColor:'#2f4cdd'} 
                            }}
                            endIcon={<ChevronRightIcon sx={{fontSize:20}} />}
                        >
                            Cliquer pour Importer le fichier CSV
                        </Button>
                    }                                
                </Dialog>

                <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={isDid} autoHideDuration={6000} onClose={() => this.setState({ isDid: false })} >
                    <Alert severity="success" sx={{ width: '100%' }} onClose={() => this.setState({ isDid: false })} >{message}</Alert>
                </Snackbar>

                <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={isNotDit} autoHideDuration={6000} onClose={() => this.setState({ isNotDit: false })} >
                    <Alert severity="error" sx={{ width: '100%' }} onClose={() => this.setState({ isNotDit: false })} >{message}</Alert>
                </Snackbar>
            </React.Fragment>
             
        )
    }
}