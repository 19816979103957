import React, { Component } from "react";
import axios from 'axios';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import { DataGrid } from '@mui/x-data-grid';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import ReactToPrint from 'react-to-print';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import PrintIcon from '@mui/icons-material/Print';
import Dropzone from 'react-dropzone';
import { CSVLink } from "react-csv";
import Avatar from '@mui/material/Avatar';
import AnnouncementOutlinedIcon from '@mui/icons-material/AnnouncementOutlined';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import csbup from "../../Assets/Images/csbup.png"
import csbdown from '../../Assets/Images/csbdown.png'
import csvup from '../../Assets/Images/csvup.png'

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default class Fournitures extends Component {

    constructor(props) {
        super(props)
        this.state = {
            fournitures:[],

            open:false,
            isDid:false,
            isNotDit:false,
            importation: false,
            voir: false,
            openEdit: false,
            loading: true,
            
            id:null,
            code:"",
            designation:"",
            unite: "T",

            selectedFile: null,
            path:"",
            message:""
        }
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleChange = this.handleChange.bind(this)
    }

    componentDidMount() {     
        const token = JSON.parse(localStorage.getItem("TOKEN"))
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };
        axios.get('https://api-ml.projectmanagementsolutions.net/api/fourniture', config)
        .then(response => {    
            this.setState({ 
                fournitures: response.data.fournitures,
                loading: false
            });
        }).catch(error => {
            console.log("error", error)
        });
    }    

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleSubmit(event) {
        event.preventDefault()
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);        
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };

        const bodyParameters = {
            code: this.state.code, 
            designation: this.state.designation, 
            unite: this.state.unite
        };

        axios.post( 
            'https://api-ml.projectmanagementsolutions.net/api/fourniture',
            bodyParameters,
            config
        ).then(response => {       
            console.log("return", response.data)     
            this.setState({ message:"Fourniture ajoutée avec succès", isDid: true, open:false });
            this.componentDidMount()            
        }).catch(error => {
            this.setState({ message:"Erreur d'enregistrement", isNotDit: true });
            console.log("error", error)
        });
    }

    fileDrop(acceptedFiles){
        this.setState({selectedFile: acceptedFiles[0],  path: acceptedFiles[0].path})
    }

    onFileUpload(){  
        const formData = new FormData();
        formData.append("File", this.state.selectedFile)
        axios.post('https://api-ml.projectmanagementsolutions.net/api/fourniture-import', formData)
        .then(response => {                             
            this.setState({message: "Importé avec succès", isDid: true, importation:false });
            this.componentDidMount()            
        }).catch(error => {
            this.setState({ message:"Erreur d'importation", isNotDit: true });
            console.log("error", error)
        });     
    }

    delete(id){
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);        
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };
        axios.delete( 
            'https://api-ml.projectmanagementsolutions.net/api/fourniture/' + id,
            config
        ).then(response => {      
            this.setState({message:"Fourniture supprimée avec succès", isDid: true }); 
            this.componentDidMount()
            
        }).catch(error => {
            this.setState({ message:"Erreur de suppression", isNotDit: true });
            console.log("error", error)
        });
    }

    editTable(props){
        console.log('Ahmed', props)
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);        
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };    
        let bodyParameters = {}
        if(props.field === "code"){
            bodyParameters = {
                code: props.value, 
                designation: props.row.designation,
                unite: props.row.unite
            };
        }else if(props.field === "designation"){
            bodyParameters = {
                code: props.row.code, 
                designation: props.value,
                unite: props.row.unite
            };
        }else if(props.field === "unite"){
            bodyParameters = {
                code: props.row.code, 
                designation: props.row.designation,
                unite: props.value
            };
        }
        axios.put( 
            'https://api-ml.projectmanagementsolutions.net/api/fourniture/' + props.id,
            bodyParameters,
            config
        ).then(response => {   
            this.setState({ message:"Fourniture modifiée avec succès", isDid: true});
            this.componentDidMount()          
        }).catch(error => {
            this.setState({ message:"Erreur dans le modification", isNotDit: true });
            console.log("error", error)
        });
    }

    render() {

        const { message, loading,  open, importation, unite, path, isDid, isNotDit, code, designation, fournitures} = this.state
        
        const columns = [
            { 
                field: 'code', 
                headerName: 'Code',
                editable: true,
                flex: 1/8
            },
            { 
                field: 'designation', 
                headerName: 'Désignation',
                editable: true,
                flex: 4/4
            },            
            { 
                field: 'unite', 
                headerName: 'Unité',
                editable: true,
                flex: 1/8
            },
            { 
                field: 'supprimer', 
                headerName: '',
                flex: 1/8,
                renderCell: (cellValues) => {
                    return (
                        <Button
                            onClick={() => { if (window.confirm('Êtes-vous sûr de le supprimer ?')){ this.delete(cellValues.row.id) }}}
                            variant="contained"
                            sx={{background:'#eff0f2', color:'black', height:30, ':hover':{background:'#e6374c', color:'white'}}}
                        >
                            Supprimer
                        </Button>
                    )
                    
                }
            }
        ]

        const headers = [
            { label: "Code", key: "code" },
            { label: "Désignation", key: "designation" },
            { label: "Unité", key: "unite" }
        ];

        return ( 
            <React.Fragment>
                <Grid sx={{ flexGrow: 1, mb:2}} container spacing={3} >
                    <Grid item xs={12} sm={6} md={2} >
                        <Button 
                            variant="contained" 
                            sx={{bgcolor:'#2f4cdd', textTransform: 'none', fontSize:16, height:50}} 
                            fullWidth
                            startIcon={<Avatar  sx={{ bgcolor: '#4777f4', height:28, width:28 }}><AddIcon sx={{fontSize:26}} /></Avatar>}
                            onClick={() => this.setState({ open: true })}
                        >
                            Ajouter une fourniture
                        </Button>
                    </Grid>

                    <Grid item xs={12} sm={6} md={8} >
                        <Box style={{display: 'flex', flexDirection:'row'}}>
                            <Button 
                                variant="contained" 
                                sx={{bgcolor:'#5d95ed', height:50, ':hover':{background:'#5d95ed'}}}
                                onClick={() => this.setState({ importation: true })}
                            >
                                <img src={csbup} style={{width:40}}/>
                            </Button>

                            <CSVLink 
                                data={fournitures} 
                                headers={headers} 
                                filename={"fournitures.csv"} 
                                className="btn" 
                                style={{height:50, backgroundColor:"#5d95ed", marginLeft:24, borderRadius:4, width:70}}
                            >
                                <img src={csbdown} style={{width:40, marginTop:'-6px', marginLeft:'-6px'}}/>
                            </CSVLink> 

                            <ReactToPrint
                                trigger={() => {
                                    return  (
                                        <Button 
                                            variant="contained" 
                                            sx={{bgcolor:'#5d95ed', height:50, ml:3}}
                                        >
                                            <PrintIcon sx={{fontSize:40}} /> 
                                        </Button>
                                    );
                                }}
                                content={() => this.componentRef}
                            />  

                            <Tooltip 
                                title={<React.Fragment>
                                    <Typography color="inherit" sx={{fontSize:20}} >Fournitures</Typography>
                                    <Typography color="inherit" sx={{fontSize:16}} >
                                    Les fournitures sont des matériaux utilisés par le chantier pour la réalisation du projet. On listera 
                                    dans la table des matériaux, l'ensemble des matériaux suivi de leurs différent caractéristiques. On 
                                    donnera un code pour chaque matériau.
                                    </Typography>
                                </React.Fragment>
                                }
                            >                        
                                <Button 
                                    variant="contained" 
                                    sx={{bgcolor:'#5d95ed', height:50, ml:3}}
                                >
                                    <AnnouncementOutlinedIcon/>
                                </Button>
                            </Tooltip>  
                        </Box>
                    </Grid>

                    <Grid item xs={false} sm={false} md={2} /> 

                    <Grid item xs={12} sm={6} md={12} >
                        <div className="card" style={{ height: 570, width: '100%',borderRadius:16 }}>
                            <DataGrid
                                rows={fournitures}
                                columns={columns}
                                onCellEditCommit={(props, event) => this.editTable(props)}
                                elevation={10}
                                loading={loading}
                                sx={{
                                    boxShadow: 2,
                                    borderRadius:4,
                                    '.MuiDataGrid-columnSeparator': {
                                        display: 'none',
                                        },
                                        '&.MuiDataGrid-root': {
                                        border: 'none',
                                        },
                                    '.css-1jbbcbn-MuiDataGrid-columnHeaderTitle': {
                                        color:'black',
                                        fontWeight: 600,
                                        fontSize:18
                                    }
                                }}
                            />
                        </div> 
                    </Grid>
                </Grid>

                <div  style={{ display: "none" }}>                
                    <table className="table table-striped table-hover"  ref={el => (this.componentRef = el)}>
                        <thead>
                            <tr>
                                <th scope="col">Code</th>
                                <th scope="col">Désignation</th>
                                <th scope="col">Unité</th>
                            </tr>
                        </thead>
                        <tbody>
                            {fournitures.map((answer) => {
                                return (
                                    <tr>
                                        <td>{answer.code}</td>
                                        <td>{answer.designation}</td>
                                        <td>{answer.unite}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>

                <Dialog
                    fullWidth={true}
                    maxWidth={"sm"}
                    open={open}
                    onClose={() => this.setState({ open: false })}                    
                >
                    <Box style={{display: 'flex', flexDirection:'row', justifyContent:'space-between', padding:32}} >
                        <Typography 
                            component="h1" 
                            variant="h1" 
                            sx={{color:"#313339", fontSize:26}}
                        >
                            Nouvelle fourniture
                        </Typography>

                        <IconButton onClick={() =>  this.setState({ open: false })} >   
                            <CloseIcon /> 
                        </IconButton>
                    </Box>

                    <form role="form" className="text-start" onSubmit={this.handleSubmit}>
                        <DialogContent >
                            <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                                <Grid item xs={12} md={12}>
                                    <div className="input-group input-group-outline mb-3">
                                        <TextField
                                            required
                                            id="outlined-required"
                                            label="Code"
                                            name="code"
                                            style={{ width: '100%' }}
                                            value={code}
                                            onChange={this.handleChange}
                                            sx={{bgcolor:'#e9eff6'}}
                                        />
                                    </div>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <div className="input-group input-group-outline mb-3">
                                        <TextField
                                            required
                                            id="outlined-required"
                                            label="Désignation"
                                            name="designation"
                                            style={{ width: '100%' }}
                                            value={designation}
                                            onChange={this.handleChange}
                                            sx={{bgcolor:'#e9eff6'}}
                                        />
                                    </div>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <div className="input-group input-group-outline mb-4">
                                        <TextField
                                            required
                                            id="outlined-required"
                                            label="Unité"
                                            name="unite"
                                            style={{ width: '100%' }}
                                            value={unite}
                                            onChange={this.handleChange}
                                            sx={{bgcolor:'#e9eff6'}}
                                        />
                                    </div>
                                </Grid>                                                      
                            </Grid>
                        </DialogContent>

                        <Button  
                            type="submit" 
                            fullWidth
                            variant="contained" 
                            sx={{
                                color:"white", 
                                textTransform: 'none', 
                                fontSize:20, 
                                height:50, 
                                backgroundColor:'#888da0',  
                                borderRadius:0, 
                                ':hover':{backgroundColor:'#2f4cdd'} 
                            }}
                            endIcon={<ChevronRightIcon sx={{fontSize:20}} />}
                        >
                            Cliquer pour enregistrer la nouvelle fourniture
                        </Button>
                    </form>                    
                </Dialog> 

                <Dialog
                    fullWidth={true}
                    maxWidth={"sm"}
                    open={importation}
                    onClose={() => this.setState({ importation: false })}  
                    sx={{'.css-tlc64q-MuiPaper-root-MuiDialog-paper':{bgcolor:'#fafafa'}}}                      
                >
                    <Box style={{display: 'flex', flexDirection:'row', justifyContent:'space-between', padding:32}} >
                        <Typography 
                            component="h1" 
                            variant="h1" 
                            sx={{color:"#313339", fontSize:26, pl:16}}
                        >
                            Importer un fichier CSV 
                        </Typography>

                        <IconButton onClick={() =>  this.setState({ importation: false })} >   
                            <CloseIcon /> 
                        </IconButton>
                    </Box>

                    <DialogContent style={{textAlign: "center",  padding:'0px 170px' }}>
                        <Dropzone onDrop={acceptedFiles  => this.fileDrop(acceptedFiles)}>
                            {({getRootProps, getInputProps}) => (
                                <section>
                                <div {...getRootProps()}>
                                    <input {...getInputProps()} />

                                    <img src={csvup} style={{ width: 260, marginLeft:'-50px', marginTop:80, marginBottom:20}} />
                                    
                                    {path === "" ? <p style={{marginTop:64}}>Faites glisser et déposez le fichier ici, ou cliquez pour sélectionner le fichier</p> : <p>{path}</p>}
                                </div>
                                </section>
                            )}
                        </Dropzone>                                    
                    </DialogContent>

                    {path === "" ? null :
                        <Button  
                            onClick={() => this.onFileUpload()}
                            fullWidth
                            variant="contained" 
                            sx={{
                                color:"white", 
                                textTransform: 'none', 
                                fontSize:20, 
                                height:50, 
                                backgroundColor:'#888da0',  
                                borderRadius:0, 
                                ':hover':{backgroundColor:'#2f4cdd'} 
                            }}
                            endIcon={<ChevronRightIcon sx={{fontSize:20}} />}
                        >
                            Cliquer pour Importer le fichier CSV
                        </Button>
                    }                                            
                </Dialog>

                <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={isDid} autoHideDuration={6000} onClose={() => this.setState({ isDid: false })} >
                    <Alert severity="success" sx={{ width: '100%' }} onClose={() => this.setState({ isDid: false })} >{message}</Alert>
                </Snackbar>

                <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={isNotDit} autoHideDuration={6000} onClose={() => this.setState({ isNotDit: false })} >
                    <Alert severity="error" sx={{ width: '100%' }} onClose={() => this.setState({ isNotDit: false })} >{message}</Alert>
                </Snackbar> 
            </React.Fragment>
        ) 
    }
}