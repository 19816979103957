import React, { Component } from "react"; 
import axios from 'axios';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { DataGrid } from '@mui/x-data-grid';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import DatePicker from 'react-date-picker';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import ReactToPrint from 'react-to-print';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import AddIcon from '@mui/icons-material/Add';
import PrintIcon from '@mui/icons-material/Print';
import Slide from '@mui/material/Slide';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CloseIcon from '@mui/icons-material/Close';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const HtmlTooltip = styled(({ className, ...props }) => (
<Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'white',
    color: 'black',
    maxWidth: 400,
    fontSize: 14,
    border: '1px solid #dadde9',
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


export default class Rapportsjournaliers extends Component {

    constructor(props) {
        super(props)
        this.state = {
            rapports: [],
            projects:[],
            bordereaux: [],
            rapport_materiels:[],
            rapport_fournitures: [],
            approvisionnements: [],
            rapport_personnels: [],
            rapport_traitants:[],
            rapport_caisses:[],
            materiels:[],
            personnels:[],
            fournitures: [],
            traitants: [],

            borderauselected:[],

            id_rapport: null,
            isDid: false,
            isNotDit:false,
            errorMateriel:false,      
            errorFourniture: false,      

            open:false, 
            openRapport: false,
            openMateriel:false,
            openPersonnel: false,
            openEditPersonnel: false,
            openFourniture: false,
            openTraitant: false,
            openEditTraitant: false,
            openCaisse: false,
            openEditCaisse:false,

            fait: new Date(),
            temps: "Beau",
            id_borderau:null,
            quantite: 1,
            observation:"",

            id_rapport_materiel:null,
            // materiel            
            id_materiel:null,
            hj_materiel:0,
            carburant_materiel:0,
            arret_materiel:0,
            immobilisation:0,
            fait_materiel: new Date(),
            //personnel
            id_rapportpersonnel: null,
            id_personnel: null,
            heure_personnel: 0,
            // Fourniture
            id_fourniture: null,
            quantite_fourniture: 0,
            //Sous Traitant
            id_rapport_traitant: null,
            id_traitant: null,
            quantite_traitant:0,
            // Caisse
            id_caisse: null,
            designation_caisse: "",
            somme_caisse: 0
        }

        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.handleEditerSubmit = this.handleEditerSubmit.bind(this)
        this.handleSubmitMateriel = this.handleSubmitMateriel.bind(this)
        this.handleSubmitPersonnel = this.handleSubmitPersonnel.bind(this)
        this.handleSubmitFourniture = this.handleSubmitFourniture.bind(this)
        this.handleSubmitTraitant = this.handleSubmitTraitant.bind(this)
        this.handleSubmitCaisse = this.handleSubmitCaisse.bind(this)
        this.handleEditerCaiseSubmit = this.handleEditerCaiseSubmit.bind(this)
        this.handleEditTraitantSubmit = this.handleEditTraitantSubmit.bind(this)
        this.handleEditerPersonnelSubmit = this.handleEditerPersonnelSubmit.bind(this)
    }

    componentDidMount() {
        const token = JSON.parse(localStorage.getItem("TOKEN"))
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };
        axios.get('https://api-ml.projectmanagementsolutions.net/api/rapport', config)
        .then(response => {
            this.setState({ 
                rapports: response.data.rapports,
                projects: response.data.projects,
                bordereaux: response.data.bordereaux,
                rapport_materiels: response.data.rapport_materiels,
                materiels: response.data.materiels,
                rapport_fournitures: response.data.rapport_fournitures,
                approvisionnements: response.data.approvisionnements,
                rapport_personnels: response.data.rapport_personnels,
                personnels: response.data.personnels,
                fournitures: response.data.fournitures,
                rapport_traitants: response.data.rapport_traitants,
                traitants: response.data.traitants,
                rapport_caisses: response.data.rapport_caisses
            });
        }).catch(error => {
            console.log("error", error)
        });
    }

    handleSubmit(event) {
        event.preventDefault()
        const fait = Math.floor(new Date(this.state.fait).getTime() / 1000)
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);        
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };        

        const bodyParameters = {
            fait: fait,
            id_projet: this.props.projetId,
            id_bordereau: this.state.id_borderau, 
            temps: this.state.temps,
            quantite: this.state.quantite,
            observation: this.state.observation
        };        
        axios.post( 
            'https://api-ml.projectmanagementsolutions.net/api/rapport',
            bodyParameters,
            config
        ).then(response => {        
            console.log("return", response.data)    
            this.setState({ isDid: true, open:false });
            this.componentDidMount()
            
        }).catch(error => {
            this.setState({ isNotDit: true });
            console.log("error", error)
        });

    }

    handleEditerSubmit(event) {
        event.preventDefault()
        const fait = Math.floor(new Date(this.state.fait).getTime() / 1000)
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);        
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };        

        const bodyParameters = {
            fait: fait,
            id_projet: this.props.projetId,
            id_bordereau: this.state.id_borderau, 
            temps: this.state.temps,
            quantite: this.state.quantite,
            observation: this.state.observation
        };      
        
        axios.put( 
            'https://api-ml.projectmanagementsolutions.net/api/rapport/' + this.state.id_rapport,
            bodyParameters,
            config
        ).then(response => {                     
            this.setState({ isDid: true });
            this.componentDidMount()          
        }).catch(error => {
            this.setState({ isNotDit: true });
            console.log("error", error)
        });
    }

    handleSubmitMateriel(event) {
        event.preventDefault()

        const fait = Math.floor(new Date().getTime() / 1000)
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);        
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };   

        const materiel = this.state.materiels.filter(c => c["id"] === this.state.id_materiel)
        
        const bodyParameters = {
            fait: fait,
            id_projet: this.props.projetId,
            id_rapport: this.state.id_rapport,
            id_materiel: this.state.id_materiel, 
            hj: Number(this.state.hj_materiel),
            carburant: Number(this.state.carburant_materiel),
            arret: Number(this.state.arret_materiel),
            taux: materiel[0].taux,
            immobilisation: this.state.immobilisation
        };  

        axios.post( 
            'https://api-ml.projectmanagementsolutions.net/api/rapport-materiel',
            bodyParameters,
            config
        ).then(response => {        
            console.log("return", response.data)    
            this.setState({ isDid: true, openMateriel:false });
            this.componentDidMount()
            
        }).catch(error => {
            this.setState({ errorMateriel: true });
            console.log("error", error)
        });
    
    }

    handleSubmitPersonnel(event) {
        event.preventDefault()

        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);        
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };   

        const personnel = this.state.personnels.filter(c => c["id"] === this.state.id_personnel)

        const bodyParameters = {
            id_projet: this.props.projetId,
            id_rapport: this.state.id_rapport,
            id_personnel: this.state.id_personnel, 
            heures: this.state.heure_personnel,
            taux: personnel[0].taux,
        };       

        axios.post( 
            'https://api-ml.projectmanagementsolutions.net/api/rapport-personnel',
            bodyParameters,
            config
        ).then(response => {        
            console.log("return", response.data)    
            this.setState({ isDid: true, openPersonnel:false });
            this.componentDidMount()
            
        }).catch(error => {
            this.setState({ errorMateriel: true });
            console.log("error", error)
        });
    
    }

    handleEditerPersonnelSubmit(event) {
        event.preventDefault()
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);        
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };
        const personnel = this.state.personnels.filter(c => c["id"] === this.state.id_personnel)
        const bodyParameters = {
            id_personnel: this.state.id_personnel, 
            heures: this.state.heure_personnel,
            taux: personnel[0].taux,
        };
        axios.put( 
            'https://api-ml.projectmanagementsolutions.net/api/rapport-personnel/' + this.state.id_rapportpersonnel,
            bodyParameters,
            config
        ).then(response => {        
            console.log("return", response.data)    
            this.setState({ isDid: true, openEditPersonnel:false });
            this.componentDidMount()            
        }).catch(error => {
            this.setState({ errorMateriel: true });
            console.log("error", error)
        });    
    }

    handleSubmitFourniture(event) {
        event.preventDefault()
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);        
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };   

        const fourniture = this.state.fournitures.filter(c => c["id"] === this.state.id_fourniture )

        const bodyParameters = {
            id_projet: this.props.projetId,
            id_rapport: this.state.id_rapport,
            id_fourniture: this.state.id_fourniture, 
            quantite: this.state.quantite_fourniture
        };       

        axios.post( 
            'https://api-ml.projectmanagementsolutions.net/api/rapport-fourniture',
            bodyParameters,
            config
        ).then(response => {        
            console.log("return", response.data)    
            this.setState({ isDid: true, openFourniture:false });
            this.componentDidMount()            
        }).catch(error => {
            this.setState({ errorFourniture: true });
            console.log("error", error)
        });
    
    }

    handleSubmitCaisse(event) {
        event.preventDefault()

        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);        
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };         

        const bodyParameters = {
            id_projet: this.props.projetId,
            id_rapport: this.state.id_rapport,
            designation: this.state.designation_caisse,
            somme: this.state.somme_caisse
        };       

        axios.post( 
            'https://api-ml.projectmanagementsolutions.net/api/rapport-caisse',
            bodyParameters,
            config
        ).then(response => {        
            console.log("return", response.data)    
            this.setState({ isDid: true, openCaisse:false });
            this.componentDidMount()            
        }).catch(error => {
            this.setState({ errorMateriel: true });
            console.log("error", error)
        });
    
    }

    handleEditerCaiseSubmit(event) {
        event.preventDefault()
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);        
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };
        const bodyParameters = {
            id_projet: this.props.projetId,
            id_rapport: this.state.id_rapport,
            designation: this.state.designation_caisse,
            somme: this.state.somme_caisse
        };
        axios.put('https://api-ml.projectmanagementsolutions.net/api/rapport-caisse/' + this.state.id_caisse,
            bodyParameters,
            config
        ).then(response => {        
            console.log("return", response.data)    
            this.setState({ isDid: true, openEditCaisse:false });
            this.componentDidMount()            
        }).catch(error => {
            this.setState({ errorMateriel: true });
            console.log("error", error)
        });    
    }

    handleSubmitTraitant(event) {
        event.preventDefault()
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);        
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };
        const traitant = this.state.traitants.filter(c => c["id"] === this.state.id_traitant)
        const bodyParameters = {
            id_projet: this.props.projetId,
            id_rapport: this.state.id_rapport,
            id_traitant: this.state.id_traitant, 
            quantite: this.state.quantite_traitant,
            taux: traitant[0].prix,
        };
        axios.post( 
            'https://api-ml.projectmanagementsolutions.net/api/rapport-traitant',
            bodyParameters,
            config
        ).then(response => {        
            console.log("return", response.data)    
            this.setState({ isDid: true, openTraitant:false });
            this.componentDidMount()            
        }).catch(error => {
            this.setState({ errorMateriel: true });
            console.log("error", error)
        });    
    }

    handleEditTraitantSubmit(event) {
        event.preventDefault()
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);        
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };
        const traitant = this.state.traitants.filter(c => c["id"] === this.state.id_traitant)
        const bodyParameters = {
            id_projet: this.props.projetId,
            id_rapport: this.state.id_rapport,
            id_traitant: this.state.id_traitant, 
            quantite: this.state.quantite_traitant,
            taux: traitant[0].prix,
        };
        axios.put( 
            'https://api-ml.projectmanagementsolutions.net/api/rapport-traitant/' + this.state.id_rapport_traitant,
            bodyParameters,
            config
        ).then(response => {        
            console.log("return", response.data)    
            this.setState({ isDid: true, openEditTraitant:false });
            this.componentDidMount()            
        }).catch(error => {
            this.setState({ errorMateriel: true });
            console.log("error", error)
        });    
    }

    handleChangeDateStart(date){ this.setState({fait: date})}

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });

        if(e.target.name === "id_borderau"){
            const borderauselected = this.state.bordereaux.filter(c => c["id"] === e.target.value)
            this.setState({ borderauselected: borderauselected[0] });
        }
    }

    editcaisse(id){
        const caisse = this.state.rapport_caisses.filter(c => c["id"] === id)
        this.setState({
            id_caisse: id,
            id_projet: this.props.projetId,
            designation_caisse: caisse[0].	designation,
            somme_caisse: caisse[0].somme,
            openEditCaisse: true
        })
    }

    editsoustraitant(id){
        const traitant = this.state.rapport_traitants.filter(c => c["id"] === id)
        this.setState({
            id_rapport_traitant: id,
            id_traitant: traitant[0].id_traitant,
            quantite_traitant: traitant[0].quantite,
            openEditTraitant: true
        })
    }

    editpersonnel(id){
        const item = this.state.rapport_personnels.filter(c => c["id"] === id)
        this.setState({
            id_rapportpersonnel: id,
            id_personnel: item[0].id_personnel,
            heure_personnel: item[0].heures,
            openEditPersonnel: true
        })
    }

    delete(id){
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);        
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };
        axios.delete( 
            'https://api-ml.projectmanagementsolutions.net/api/rapport/' + id,
            config
        ).then(response => {                     
            this.setState({ isDid: true, voir:false });
            this.componentDidMount()
            
        }).catch(error => {
            this.setState({ isNotDit: true });
            console.log("error", error)
        });
    }

    showRapport(id){
        const rapport = this.state.rapports.filter(c => c["id"] === id)
        this.setState({
            id_rapport: id,
            fait: new Date(rapport[0].fait *1000),
            id_borderau: rapport[0].id_bordereau, 
            temps: rapport[0].temps,
            borderauselected: this.state.bordereaux.filter(c => c["id"] === rapport[0].id_bordereau)[0],
            quantite: rapport[0].quantite,
            observation: rapport[0].observation,
            openRapport: true
        })
    }

    deleteMateriel(id){
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);        
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };
        axios.delete( 
            'https://api-ml.projectmanagementsolutions.net/api/rapport-materiel/' + id,
            config
        ).then(response => {                     
            this.setState({ isDid: true });
            this.componentDidMount()
            
        }).catch(error => {
            this.setState({ isNotDit: true });
            console.log("error", error)
        });
    }

    deletepersonnel(id){
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);        
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };
        axios.delete( 
            'https://api-ml.projectmanagementsolutions.net/api/rapport-personnel/' + id,
            config
        ).then(response => {                     
            this.setState({ isDid: true });
            this.componentDidMount()
            
        }).catch(error => {
            this.setState({ isNotDit: true });
            console.log("error", error)
        });
    }

    deletetraitant(id){
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);        
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };
        axios.delete( 
            'https://api-ml.projectmanagementsolutions.net/api/rapport-traitant/' + id,
            config
        ).then(response => {                     
            this.setState({ isDid: true });
            this.componentDidMount()
            
        }).catch(error => {
            this.setState({ isNotDit: true });
            console.log("error", error)
        });
    }

    deletecaisse(id){
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);        
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };
        axios.delete( 
            'https://api-ml.projectmanagementsolutions.net/api/rapport-caisse/' + id,
            config
        ).then(response => {                     
            this.setState({ isDid: true });
            this.componentDidMount()            
        }).catch(error => {
            this.setState({ isNotDit: true });
            console.log("error", error)
        });
    }

    deleteFourniture(id){
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);        
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };
        axios.delete( 
            'https://api-ml.projectmanagementsolutions.net/api/rapport-fourniture/' + id,
            config
        ).then(response => {             
            this.setState({ isDid: true });
            this.componentDidMount()            
        }).catch(error => {
            this.setState({ isNotDit: true });
            console.log("error", error)
        });
    }

    deleteMateriel(id){
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);        
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };
        axios.delete( 
            'https://api-ml.projectmanagementsolutions.net/api/rapport-materiel/' + id,
            config
        ).then(response => {   
            console.log("return", response.data)   
            this.setState({ isDid: true });
            this.componentDidMount()            
        }).catch(error => {
            this.setState({ isNotDit: true });
            console.log("error", error)
        });
    }


    render() {

        const { immobilisation, openEditPersonnel, openEditTraitant, openEditCaisse, errorFourniture, quantite_fourniture, fournitures, id_fourniture, approvisionnements, openFourniture, rapport_fournitures, designation_caisse, somme_caisse,  openCaisse, rapport_caisses, id_traitant, quantite_traitant, openTraitant, traitants, rapport_traitants, heure_personnel, id_personnel, openPersonnel, personnels, rapport_personnels, errorMateriel, id_materiel, carburant_materiel, arret_materiel, hj_materiel, rapports, rapport_materiels, materiels, openMateriel, openRapport, id_rapport, isDid, isNotDit, open, fait, temps, bordereaux, id_borderau, borderauselected, quantite, observation } = this.state

        const id_project = this.props.projetId
        
        const bordereauxToSelect = bordereaux.filter(c => c["id_projet"] === id_project)

        const rapport_materiel = () => {
            let resultat = []
            resultat = rapport_materiels.filter(c => c["id_rapport"] === id_rapport)
            let pp = resultat.filter( (ele, ind) => ind === resultat.findIndex( elem => elem.id_materiel === ele.id_materiel))
            return pp
        }

        const rapport_fourniture = () => {
            let resultat = []
            resultat = rapport_fournitures.filter(c => c["id_rapport"] === id_rapport) 
            let pp = resultat.filter( (ele, ind) => ind === resultat.findIndex( elem => elem.id_fourniture	 === ele.id_fourniture	))
            return pp
        }

        const rapport_personnel = () => {
            let resultat = []
            resultat = rapport_personnels.filter(c => c["id_rapport"] === id_rapport)
            let pp = resultat.filter( (ele, ind) => ind === resultat.findIndex( elem => elem.id_personnel === ele.id_personnel))
            return pp
        }

        const rapport_traitant = () => {
            let resultat = []
            resultat = rapport_traitants.filter(c => c["id_rapport"] === id_rapport)
            let pp = resultat.filter( (ele, ind) => ind === resultat.findIndex( elem => elem.id_traitant === ele.id_traitant))
            return pp
        }

        const rapport_caisse = rapport_caisses.filter(c => c["id_rapport"] === id_rapport)        

        let rapport = []
        if(id_project === null){
            rapport = []
        }else{
            rapport = rapports.filter(c => c["id_projet"] === id_project)
        }

        let materiel = []
        if(id_project === null){
            materiel = []
        }else{
            materiel = materiels.filter(c => c["id_projet"] === id_project)
        }
        

        let personnel = []
        if(id_project === null){
            personnel = []
        }else{
            personnel = personnels.filter(c => c["id_projet"] === id_project)
        }

        let traitant = []
        if(id_project === null){
            traitant = []
        }else{
            traitant = traitants.filter(c => c["id_projet"] === id_project)
        }

        let approvisionnement = []
        if(id_project === null){
            approvisionnement = []
        }else{
            approvisionnement = approvisionnements.filter(c => c["id_projet"] === id_project)
        }


        const displayCreation = (timestamp) => {
            var date = new Date(timestamp * 1000);
            return date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear()
        }

        const displayBordereau = (id_borderau) => {
            let resultat = ""
            resultat = bordereaux.filter(c => c["id"] === id_borderau)[0].n_prix           
            return "#" + resultat
        }

        const displayBordereauDesign = (id_borderau) => {
            let resultat = ""
            resultat = bordereaux.filter(c => c["id"] === id_borderau)[0].designation           
            return resultat
        }

        const displayGM = (id) => {
            let resultat = ""
            resultat = materiels.filter(c => c["id"] === id)[0].gm           
            return resultat
        }

        const displayType  = (id) => {
            let resultat = ""
            resultat = materiels.filter(c => c["id"] === id)[0].type           
            return resultat
        }

        const displayCategorie = (id) => {
            let resultat = ""
            resultat = personnels.filter(c => c["id"] === id)[0].categorie           
            return resultat
        }

        const displayCode= (id) => {
            let resultat = ""
            resultat = traitants.filter(c => c["id"] === id)[0].code           
            return resultat
        }

        const displayFournitureCode= (id) => {
            let resultat = ""
            resultat =  fournitures.filter(c => c["id"] === id)[0].code           
            return resultat
        }

        const displayFournitureDesign = (id) => {
            let resultat = ""
            resultat =  fournitures.filter(c => c["id"] === id)[0].designation           
            return resultat
        }

        const columns = [
            { 
                field: 'fait', 
                headerName: 'Date du  rapport',
                flex: 1/2,
                renderCell: (cellValues) => {return  displayCreation(cellValues.row.fait);}
            },
            { 
                field: 'n_prix', 
                headerName: 'N° prix',
                flex: 1/2,
                renderCell: (cellValues) => {return  displayBordereau(cellValues.row.id_bordereau);}
            },
            { 
                field: 'designation', 
                headerName: 'Désignation',
                flex: 2,
                renderCell: (cellValues) => {return  displayBordereauDesign(cellValues.row.id_bordereau);}
            },
            { 
                field: 'Voir', 
                headerName: 'Voir',
                flex: 1/4,
                renderCell: (cellValues) => {
                    return (
                        <IconButton 
                            style={{ color: 'black' }} 
                            onClick={() => this.showRapport(cellValues.row.id) }
                        >
                            <AddIcon />
                        </IconButton> 
                    )
                    
                }
            },
            { 
                field: 'supprimer', 
                headerName: 'Supprimer',
                flex: 1/4,
                renderCell: (cellValues) => {
                    return (
                        <IconButton 
                            style={{ color: '#d32f2f' }} 
                            onClick={() => { if (window.confirm('Êtes-vous sûr de le supprimer ?')){ this.delete(cellValues.row.id) }}}
                        >
                            <DeleteOutlinedIcon />
                        </IconButton> 
                    )
                    
                }
            }
        ]

        const columnsMateriel = [
            { 
                field: 'gm', 
                headerName: 'N° GM',
                flex: 2/7,
                renderCell: (cellValues) => {return  displayGM(cellValues.row.id_materiel)}
            },
            { 
                field: 'type', 
                headerName: 'Matériel',
                flex: 3/7,
                renderCell: (cellValues) => {return  displayType(cellValues.row.id_materiel)}
            },            
            { 
                field: 'carburant', 
                headerName: 'Carburant',
                flex: 1/7,
                renderCell: (cellValues) => {return  displaycar(cellValues.row.id_materiel, cellValues.row.id_rapport).toLocaleString()}
            },
            { 
                field: 'hj', 
                headerName: 'Utilisation',
                flex: 1/7,
                renderCell: (cellValues) => {return  displayHJ(cellValues.row.id_materiel, cellValues.row.id_rapport).toLocaleString()}
            },
            { 
                field: 'arret', 
                headerName: "Arrêt",
                flex: 1/7,
                renderCell: (cellValues) => {return  displayAR(cellValues.row.id_materiel, cellValues.row.id_rapport).toLocaleString()}
            },     
            { 
                field: 'immobilisation', 
                headerName: "Immobilisation",
                flex: 1/7,
                renderCell: (cellValues) => {return  displayImm(cellValues.row.id_materiel, cellValues.row.id_rapport).toLocaleString()}
            },             
            { 
                field: 'supprimer', 
                headerName: 'Supprimer',
                flex: 1/7,
                renderCell: (cellValues) => {
                    return (
                        <IconButton 
                            style={{ color: '#d32f2f' }} 
                            onClick={() => { if (window.confirm('Êtes-vous sûr de le supprimer ?')){ this.deleteMateriel(cellValues.row.id) }}}
                        >
                            <DeleteOutlinedIcon />
                        </IconButton> 
                    )
                    
                }
            }
        ]

        const columnsFourniture =  [
            { 
                field: 'code', 
                headerName: 'Code',
                flex: 1/3,                
                renderCell: (cellValues) => {return  displayFournitureCode(cellValues.row.id_fourniture)}
            },
            { 
                field: 'quantite', 
                headerName: 'Quantité',
                flex: 1/2,
                renderCell: (cellValues) => {return  displayQtéMaterieaux(cellValues.row.id_fourniture, cellValues.row.id_rapport).toLocaleString()}
            },        
            { 
                field: 'supprimer', 
                headerName: 'Supprimer',
                flex: 1/3,
                renderCell: (cellValues) => {
                    return (
                        <IconButton 
                            style={{ color: '#d32f2f' }} 
                            onClick={() => { if (window.confirm('Êtes-vous sûr de le supprimer ?')){ this.deleteFourniture(cellValues.row.id) }}}
                        >
                            <DeleteOutlinedIcon />
                        </IconButton> 
                    )
                    
                }
            }
        ]

        const columnsPersonnel = [
            { 
                field: 'categorie', 
                headerName: 'Catégorie',
                flex: 1/3,
                renderCell: (cellValues) => { 
                    return <Button variant="text" style={{textTransform: 'none', color: '#d32f2f'}}  onClick={() => this.editpersonnel(cellValues.row.id)} >{displayCategorie(cellValues.row.id_personnel)}</Button> 
                }
            },            
            { 
                field: 'heures', 
                headerName: 'Heures',
                flex: 1/4,
                renderCell: (cellValues) => {return  displayHeurePersonnel(cellValues.row.id_personnel, cellValues.row.id_rapport).toLocaleString()}
            },      
            { 
                field: 'supprimer', 
                headerName: 'Supprimer',
                flex: 1/3,
                renderCell: (cellValues) => {
                    return (
                        <IconButton 
                            style={{ color: '#d32f2f' }} 
                            onClick={() => { if (window.confirm('Êtes-vous sûr de le supprimer ?')){ this.deletepersonnel(cellValues.row.id) }}}
                        >
                            <DeleteOutlinedIcon />
                        </IconButton> 
                    )
                }
            }
        ]

        const columnsTraitant = [
            { 
                field: 'code', 
                headerName: 'Code',
                flex: 1/3,
                renderCell: (cellValues) => { 
                    return <Button variant="text" style={{textTransform: 'none', color: '#d32f2f'}}  onClick={() => this.editsoustraitant(cellValues.row.id)} >{displayCode(cellValues.row.id_traitant)}</Button> 
                }
            },            
            { 
                field: 'quantite', 
                headerName: 'Quantité',
                flex: 1/3,
                renderCell: (cellValues) => {return  displayQuantiteTraitant(cellValues.row.id_traitant, cellValues.row.id_rapport).toLocaleString()}
            },      
            { 
                field: 'supprimer', 
                headerName: 'Supprimer',
                flex: 1/3,
                renderCell: (cellValues) => {
                    return (
                        <IconButton 
                            style={{ color: '#d32f2f' }} 
                            onClick={() => { if (window.confirm('Êtes-vous sûr de le supprimer ?')){ this.deletetraitant(cellValues.row.id) }}}
                        >
                            <DeleteOutlinedIcon />
                        </IconButton> 
                    )
                }
            }
        ]

        const columnsCaisse = [
            { 
                field: 'designation', 
                headerName: 'Désignation',
                flex: 1/2,
                renderCell: (cellValues) => { 
                    return <Button variant="text" style={{textTransform: 'none', color: '#d32f2f'}}  onClick={() => this.editcaisse(cellValues.row.id)} >{cellValues.row.designation}</Button> 
                }
            },            
            { 
                field: 'somme', 
                headerName: 'Montant',
                flex: 1/4,
            },      
            { 
                field: 'supprimer', 
                headerName: 'Supprimer',
                flex: 1/4,
                renderCell: (cellValues) => {
                    return (
                        <IconButton 
                            style={{ color: '#d32f2f' }} 
                            onClick={() => { if (window.confirm('Êtes-vous sûr de le supprimer ?')){ this.deletecaisse(cellValues.row.id) }}}
                        >
                            <DeleteOutlinedIcon />
                        </IconButton> 
                    )
                }
            }
        ]

        const displayQuantiteTraitant = (id_traitant, id_rapport) => {
            let resultat = 0
            let item = []
            let items= []
            item = rapport_traitants.filter(c => c["id_traitant"] === id_traitant )
            items =  item.filter(c => c["id_rapport"] === id_rapport )
            items.map((answer) => {                 
                resultat = resultat + answer.quantite
            })       
            return resultat
        }

        const displayQtéMaterieaux = (id_fourniture, id_rapport) => {
            let resultat = 0
            let item = []
            let items= []
            item = rapport_fournitures.filter(c => c["id_fourniture"] === id_fourniture )
            items =  item.filter(c => c["id_rapport"] === id_rapport )
            items.map((answer) => {                 
                resultat = resultat + answer.quantite
            })       
            return resultat
        }

        const displayHeurePersonnel = (id_personnel, id_rapport) => {
            let resultat = 0
            let item = []
            let items= []
            item = rapport_personnels.filter(c => c["id_personnel"] === id_personnel )
            items =  item.filter(c => c["id_rapport"] === id_rapport )
            items.map((answer) => {                 
                resultat = resultat + answer.heures
            })       
            return resultat
        }

        const displayImm = (id_materiel, id_rapport) => {
            let resultat = 0
            let item = []
            let items= []
            item = rapport_materiels.filter(c => c["id_materiel"] === id_materiel )
            items =  item.filter(c => c["id_rapport"] === id_rapport )
            items.map((answer) => {                 
                resultat = resultat + answer.immobilisation
            })       
            return resultat
        }

        const displayAR = (id_materiel, id_rapport) => {
            let resultat = 0
            let item = []
            let items= []
            item = rapport_materiels.filter(c => c["id_materiel"] === id_materiel )
            items =  item.filter(c => c["id_rapport"] === id_rapport )
            items.map((answer) => {                 
                resultat = resultat + answer.arret
            })       
            return resultat
        }

        const displayHJ = (id_materiel, id_rapport) => {
            let resultat = 0
            let item = []
            let items= []
            item = rapport_materiels.filter(c => c["id_materiel"] === id_materiel )
            items =  item.filter(c => c["id_rapport"] === id_rapport )
            items.map((answer) => {                 
                resultat = resultat + answer.hj
            })       
            return resultat
        }

        const displaycar = (id_materiel, id_rapport) => {
            let resultat = 0
            let item = []
            let items= []
            item = rapport_materiels.filter(c => c["id_materiel"] === id_materiel )
            items =  item.filter(c => c["id_rapport"] === id_rapport )
            items.map((answer) => {                 
                resultat = resultat + answer.carburant
            })       
            return resultat
        }

        const displayMcar = () => {
            let resultat = 0
            let items = []
            let item = []
            rapport_materiel().map((answer) => { 
                item = rapport_materiels.filter(c => c["id_materiel"] === answer.id_materiel )
                items =  item.filter(c => c["id_rapport"] === id_rapport )
                items.map((answer) => {                 
                    resultat = resultat + (answer.carburant * answer.prix)
                })            
            })            
            return resultat
        }

        const displayMmater = () => {
            let resultat = 0
            let items = []
            let item = []
            rapport_materiel().map((answer) => { 
                item = rapport_materiels.filter(c => c["id_materiel"] === answer.id_materiel )
                items =  item.filter(c => c["id_rapport"] === id_rapport )
                items.map((answer) => {                 
                    resultat = resultat + (answer.hj * answer.taux)
                })            
            }) 
            return resultat
        }

        const displayMper = () => {
            let resultat = 0
            let item = []
            let items = []
            rapport_personnel().map((answer) => { 
                item = rapport_personnels.filter(c => c["id_personnel"] === answer.id_personnel )
                items =  item.filter(c => c["id_rapport"] === id_rapport )
                items.map((answer) => {                 
                    resultat = resultat + (answer.heures * answer.taux)
                })            
            }) 
            return resultat
        }

        const displayMfour = () => {
            let resultat = 0
            let item = []
            let items = []
            rapport_fourniture().map((answer) => { 
                item = rapport_fournitures.filter(c => c["id_fourniture"] === answer.id_fourniture )
                items =  item.filter(c => c["id_rapport"] === id_rapport )
                items.map((answer) => {                 
                    resultat = resultat + (answer.quantite * answer.taux)
                })            
            }) 
            return resultat
        }

        const displayMtraitant = () => {
            let resultat = 0
            let item = []
            let items = []
            rapport_traitant().map((answer) => { 
                item = rapport_traitants.filter(c => c["id_traitant"] === answer.id_traitant )
                items =  item.filter(c => c["id_rapport"] === id_rapport )
                items.map((answer) => {                 
                    resultat = resultat + (answer.quantite * answer.taux)
                })            
            }) 
            return resultat
        }

        const displayMcaiss = () => {
            let resultat = 0
            rapport_caisse.map((answer) => {
                resultat = resultat + answer.somme
            })
            return resultat
        }

        
        return (
            <div>               
                <HtmlTooltip
                    title={
                        <React.Fragment>
                            <Typography color="inherit">Rapports de chantier</Typography>
                            Les rapports d'activités de chaque échelon sont rédigés chaque jour sur un forulaire conformement au prix du bordereau. 
                            Un échelon peut produire plusieurs rapports par jour selon que les opérations éffectuées concernant plusieurs prix du bordereau. 
                            Le rapport de chantier comportent les informations de date, de prix, de quantite executées, des matériels utilisés, du personnel, 
                            des matériaux etc .....
                        </React.Fragment>
                    }
                >
                    <Button variant="outlined" startIcon={<HelpOutlineIcon />} style={{marginTop:"-150px", color:"white", backgroundColor:"black"}}>
                        Aide
                    </Button>
                </HtmlTooltip>

                <div className="card" style={{ height:630, width: '100%', marginTop:"-40px" }} >
                    <DataGrid
                        rows={rapport}
                        columns={columns}
                        pageSize={10}
                        rowsPerPageOptions={[10]}                        
                    />
                </div>                

                {/* Rapport big popup */}
                <Dialog
                    fullScreen
                    open={openRapport}
                    onClose={() => this.setState({openRapport:false})}
                    TransitionComponent={Transition}
                >
                    <AppBar sx={{ position: 'relative' }} color="error">
                        <Toolbar>
                            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">Rapports de chantier </Typography>


                            <Button 
                                autoFocus 
                                //color="inherit" 
                                style={{marginRight:16, backgroundColor:"black", color:"white"}}
                                startIcon={<AddIcon />} 
                                onClick={() => this.setState({openMateriel:true})}
                            >Materiel</Button>  

                            <Button 
                                autoFocus 
                                //color="inherit" 
                                style={{marginRight:16, backgroundColor:"black", color:"white"}}
                                startIcon={<AddIcon />} 
                                onClick={() => this.setState({openPersonnel:true})}
                            >Personnel</Button>  

                            <Button 
                                autoFocus 
                                //color="inherit" 
                                style={{marginRight:16, backgroundColor:"black", color:"white"}}
                                startIcon={<AddIcon />} 
                                onClick={() => this.setState({openFourniture:true})}
                            >Fourniture</Button>  

                            <Button 
                                autoFocus 
                                //color="inherit" 
                                style={{marginRight:16, backgroundColor:"black", color:"white"}}
                                startIcon={<AddIcon />} 
                                onClick={() => this.setState({openTraitant:true})}
                            >S-traitant</Button>

                            <Button 
                                autoFocus 
                                //color="inherit" 
                                style={{marginRight:16, backgroundColor:"black", color:"white"}}
                                startIcon={<AddIcon />} 
                                onClick={() => this.setState({openCaisse:true})}
                            >Caisse</Button> 

                            <ReactToPrint
                                trigger={() => {
                                    return  (
                                        <Button 
                                            autoFocus 
                                            //color="inherit" 
                                            style={{marginRight:16, backgroundColor:"black", color:"white"}}  
                                            startIcon={<PrintIcon />}
                                        >
                                            Imprimer
                                        </Button>
                                    );
                                }}
                                content={() => this.componentRef}
                            />  

                            <Button 
                                autoFocus 
                                //color="inherit" 
                                style={{backgroundColor:"black", color:"white"}}
                                endIcon={<CloseIcon />} 
                                onClick={() => this.setState({openRapport:false})}
                            >Fermer</Button>
                        </Toolbar>
                    </AppBar>

                    <form role="form" className="text-start" onSubmit={this.handleEditerSubmit}  >
                        <DialogContent ref={el => (this.componentRef = el)} >
                            <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                                <Grid item xs={12} md={4}>
                                    <div className="input-group input-group-outline mb-4">  
                                        <InputLabel id="demo-simple-select-label">Date</InputLabel>
                                        <DatePicker 
                                            selected={fait}
                                            onChange={(date) => this.handleChangeDateStart(date)}
                                            value={fait}
                                            id="demo-simple-select"
                                            maxDate={new Date()}
                                            style={{height:50,  marginTop:"-20px"}}
                                        />
                                    </div>
                                </Grid>

                                <Grid item xs={12} md={8} style={{textAlign: "right"}}> 
                                    <FormControl >
                                        <FormLabel id="demo-row-radio-buttons-group-label">Temps</FormLabel>

                                        <RadioGroup
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="row-radio-buttons-group"
                                            onChange={this.handleChange}
                                        >
                                            <FormControlLabel 
                                                value="Beau" 
                                                name="temps" 
                                                control={<Radio  sx={{color: "#ec4b48", '&.Mui-checked': {color: "#ec4b48"},}}/>} 
                                                label="Beau" 
                                                checked={temps === "Beau"}
                                            />
                                            <FormControlLabel 
                                                value="Pluvieux" 
                                                name="temps" 
                                                control={<Radio  sx={{color: "#ec4b48", '&.Mui-checked': {color: "#ec4b48"},}}/>} 
                                                label="Pluvieux" 
                                                checked={temps === "Pluvieux"}
                                            />
                                            <FormControlLabel 
                                                value="Nuageux" 
                                                name="temps" 
                                                control={<Radio  sx={{color: "#ec4b48", '&.Mui-checked': {color: "#ec4b48"},}}/>} 
                                                label="Nuageux" 
                                                checked={temps === "Nuageux"}
                                            />
                                        </RadioGroup>                                        
                                    </FormControl>                                
                                </Grid>

                                <Grid item xs={12} md={2}> 
                                    <FormControl sx={{ m: 1 }} style={{ width: '100%', marginTop:'-1px', marginLeft:'-1px' }}>
                                        <InputLabel id="demo-simple-select-helper-label">N° prix</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-helper-label"
                                            id="demo-simple-select-helper"
                                            value={id_borderau}
                                            label="N° prix"
                                            name="id_borderau"
                                            style={{ width: '100%' }}
                                            onChange={this.handleChange}
                                        >        
                                            {bordereauxToSelect.map((answer) => { 
                                                return  <MenuItem value={answer.id}>{answer.n_prix}</MenuItem>
                                            })}       
                                        </Select>
                                    </FormControl>

                                </Grid>

                                <Grid item xs={12} md={4}> 
                                    <TextField
                                        id="outlined-required"
                                        label="Désignation"
                                        name="designation"
                                        variant="outlined"
                                        style={{ width: '100%' }}
                                        value={borderauselected.designation || ''}
                                    />

                                </Grid>

                                <Grid item xs={12} md={2}> 
                                    <TextField
                                        id="outlined-required"
                                        label="Prix unitaitre"
                                        style={{ width: '100%' }}
                                        type="number"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        value={borderauselected.prix || ''}
                                    />
                                </Grid>

                                <Grid item xs={12} md={2}> 
                                    <TextField
                                        id="outlined-required"
                                        label="Quantité"
                                        style={{ width: '100%' }}
                                        type="number"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        value={quantite}
                                        name="quantite"
                                        onChange={this.handleChange}
                                    />
                                </Grid>

                                <Grid item xs={12} md={2}> 
                                    <TextField
                                        id="outlined-required"
                                        label="Unité"
                                        style={{ width: '100%' }}
                                        value={borderauselected.unite || ''}
                                    />
                                </Grid>

                                <Grid item xs={12} md={4}>
                                    <h1 style={{textAlign: "center", fontSize:24}}>MATERIEL</h1>
                                    <div className="card" style={{ height: 300, width: '100%'}} >
                                        <DataGrid
                                            rows={rapport_materiel()}
                                            columns={columnsMateriel}
                                            pageSize={10}
                                            rowsPerPageOptions={[10]}
                                        />
                                    </div>
                                </Grid>

                                <Grid item xs={12} md={2}>
                                    <h1 style={{textAlign: "center", fontSize:24}}>PERSONNEL</h1>

                                    <div className="card" style={{ height: 300, width: '100%'}} >
                                        <DataGrid
                                            rows={rapport_personnel()}
                                            columns={columnsPersonnel}
                                            pageSize={10}
                                            rowsPerPageOptions={[10]}
                                        />
                                    </div>
                                </Grid>


                                <Grid item xs={12} md={2}>
                                    <h1 style={{textAlign: "center", fontSize:24}}>FOURNITURE</h1>
                                    <div className="card" style={{ height: 300, width: '100%'}} >
                                        <DataGrid
                                            rows={rapport_fourniture()}
                                            columns={columnsFourniture}
                                            pageSize={10}
                                            rowsPerPageOptions={[10]}
                                        />
                                    </div>
                                </Grid>

                                <Grid item xs={12} md={2}>
                                    <h1 style={{textAlign: "center", fontSize:24}}>S-TRAITANT</h1>
                                    <div className="card" style={{ height: 300, width: '100%'}} >
                                        <DataGrid
                                            rows={rapport_traitant()}
                                            columns={columnsTraitant}
                                            pageSize={10}
                                            rowsPerPageOptions={[10]}
                                        />
                                    </div>
                                </Grid>

                                <Grid item xs={12} md={2}>
                                    <h1 style={{textAlign: "center", fontSize:24}}>CAISSE</h1>
                                    <div className="card" style={{ height: 300, width: '100%'}} >
                                        <DataGrid
                                            rows={rapport_caisse}
                                            columns={columnsCaisse}
                                            pageSize={10}
                                            rowsPerPageOptions={[10]}
                                        />
                                    </div>
                                </Grid>

                                <Grid item xs={12} md={12}><Typography color="inherit">Déboursé par poste</Typography></Grid>

                                <Grid item xs={12} md={3/2}>                                    
                                    <div className="card" style={{ width: '100%'}} >
                                        <TextField
                                            id="outlined-required"
                                            label="Montant Carburant"
                                            variant="outlined"
                                            style={{ width: '100%' }}
                                            value={displayMcar().toLocaleString() || ''}
                                        />
                                    </div>
                                </Grid>

                                <Grid item xs={12} md={3/2}>                                    
                                    <div className="card" style={{ width: '100%'}} >
                                        <TextField
                                            id="outlined-required"
                                            label="Montant Matériel"
                                            variant="outlined"
                                            style={{ width: '100%' }}
                                            value={displayMmater().toLocaleString() || ''}
                                        />
                                    </div>
                                </Grid>

                                <Grid item xs={12} md={2} align="right">                                    
                                    <div className="card" style={{ width: '100%'}} >
                                        <TextField
                                            id="outlined-required"
                                            label="Montant Personnel"
                                            variant="outlined"
                                            style={{ width: '100%' }}
                                            value={displayMper().toLocaleString() || ''}
                                        />
                                    </div>
                                </Grid>

                                <Grid item xs={12} md={3} align="right">                                    
                                    <div className="card" style={{ width: '100%'}} >
                                        <TextField
                                            id="outlined-required"
                                            label="Montant Fourniture"
                                            variant="outlined"
                                            style={{ width: '100%' }}
                                            value={displayMfour().toLocaleString() || ''}
                                        />
                                    </div>
                                </Grid>

                                <Grid item xs={12} md={2} align="right">                                    
                                    <div className="card" style={{ width: '100%'}} >
                                        <TextField
                                            id="outlined-required"
                                            label="Montant S-traitant"
                                            variant="outlined"
                                            style={{ width: '100%' }}
                                            value={displayMtraitant().toLocaleString() || ''}
                                        />
                                    </div>
                                </Grid>

                                <Grid item xs={12} md={2} align="right">                                    
                                    <div className="card" style={{ width: '100%'}} >
                                        <TextField
                                            id="outlined-required"
                                            label="Montant Caisse"
                                            variant="outlined"
                                            style={{ width: '100%' }}
                                            value={displayMcaiss().toLocaleString() || ''}
                                        />
                                    </div>
                                </Grid>

                                <Grid item xs={12} md={6} style={{marginTop:32}}> 
                                    <TextField
                                        id="outlined-multiline-static"
                                        label="Observation"
                                        multiline
                                        rows={4}
                                        value={observation}
                                        style={{ width: '100%' }}
                                        name="observation"
                                        onChange={this.handleChange}
                                    />
                                </Grid>

                                <Grid item xs={12} md={6} style={{marginTop:32}} align="right"> 
                                    <TextField
                                        id="outlined-required"
                                        label="Total déboursés"
                                        variant="outlined"
                                        style={{ width: '40%' }}
                                        value={(displayMcar() + displayMmater() + displayMper() + displayMfour() + displayMtraitant() + displayMcaiss()).toLocaleString() || ''}
                                    />

                                    <br/> 

                                    <TextField
                                        id="outlined-required"
                                        label="Prix de revient"
                                        variant="outlined"
                                        style={{ width: '40%', marginTop:16 }}
                                        value={((displayMcar() + displayMmater() + displayMper() + displayMfour() + displayMtraitant() + displayMcaiss())/quantite).toLocaleString() || ''}
                                    />
                                </Grid>
                            </Grid>
                        </DialogContent>

                        <DialogActions>
                            <Button onClick={() => this.setState({ openRapport: false })} style={{color:"black"}} >Annuler</Button>

                            <Button  type="submit" className="bg-gradient-danger" autoFocus style={{color:"white"}}> Modifier </Button>
                        </DialogActions>
                    </form>
                </Dialog>

                {/* Rapport Materiel */}
                <Dialog
                    fullWidth={true}
                    maxWidth={"lg"}
                    open={openMateriel}
                    onClose={() => this.setState({ openMateriel: false })}                    
                >
                    <DialogTitle style={{textAlign: "center"}}>Rapport Matériel</DialogTitle>

                    <form role="form" className="text-start" onSubmit={this.handleSubmitMateriel}>
                        <DialogContent>
                            <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <FormControl style={{ width: '100%' }}>
                                        <InputLabel id="demo-simple-select-helper-label">N° GM</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-helper-label"
                                            id="demo-simple-select-helper"
                                            value={id_materiel || ""}
                                            label="N° GM"
                                            name="id_materiel"
                                            style={{ width: '100%' }}
                                            onChange={this.handleChange}
                                        >        
                                            {materiel.map((answer) => { 
                                                return  <MenuItem value={answer.id}>{answer.gm}</MenuItem>
                                            })}       
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <FormControl style={{ width: '100%' }}>
                                        <InputLabel id="demo-simple-select-helper-label">Matériel</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-helper-label"
                                            id="demo-simple-select-helper"
                                            value={id_materiel || ""}
                                            label="N° GM"
                                            name="id_materiel"
                                            style={{ width: '100%' }}
                                            onChange={this.handleChange}
                                        >        
                                            {materiel.map((answer) => { 
                                                return  <MenuItem value={answer.id}>{answer.type}</MenuItem>
                                            })}       
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} md={6}> 
                                    <TextField
                                        id="outlined-required"
                                        label="Heures d'utilisation"
                                        style={{ width: '100%' }}
                                        type="number"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        value={hj_materiel}
                                        name="hj_materiel"
                                        onChange={this.handleChange}
                                    />
                                </Grid>
                                
                                <Grid item xs={12} md={6}> 
                                    <TextField
                                        id="outlined-required"
                                        label="Carburant utilisé"
                                        style={{ width: '100%' }}
                                        type="number"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        value={carburant_materiel}
                                        name="carburant_materiel"
                                        onChange={this.handleChange}
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}> 
                                    <TextField
                                        id="outlined-required"
                                        label="Heures de panne"
                                        style={{ width: '100%' }}
                                        type="number"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        value={arret_materiel}
                                        name="arret_materiel"
                                        onChange={this.handleChange}
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}> 
                                    <TextField
                                        id="outlined-required"
                                        label="Heures d'immobilisation"
                                        style={{ width: '100%' }}
                                        type="number"
                                        InputLabelProps={{shrink: true}}
                                        value={immobilisation}
                                        name="immobilisation"
                                        onChange={this.handleChange}
                                    />
                                </Grid>
                            </Grid>

                        </DialogContent>

                        <DialogActions>
                            <Button onClick={() => this.setState({ openMateriel: false })} style={{color:"black"}} >Annuler</Button>

                            <Button  type="submit" className="bg-gradient-danger" autoFocus style={{color:"white"}}> Enregistrer </Button>
                        </DialogActions>
                        
                    </form>
                </Dialog>

                
                {/* Rapport Personnel */}
                <Dialog
                    fullWidth={true}
                    maxWidth={"lg"}
                    open={openPersonnel}
                    onClose={() => this.setState({ openPersonnel: false })}                    
                >
                    <DialogTitle style={{textAlign: "center"}}>Rapport Personnel</DialogTitle>

                    <form role="form" className="text-start" onSubmit={this.handleSubmitPersonnel}>
                        <DialogContent>
                            <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <FormControl style={{ width: '100%' }}>
                                        <InputLabel id="demo-simple-select-helper-label">Catégorie</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-helper-label"
                                            id="demo-simple-select-helper"
                                            value={id_personnel || ""}
                                            label="Catégorie"
                                            name="id_personnel"
                                            style={{ width: '100%' }}
                                            onChange={this.handleChange}
                                        >        
                                            {personnel.map((answer) => { 
                                                return  <MenuItem value={answer.id}>{answer.categorie}</MenuItem>
                                            })}       
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <FormControl style={{ width: '100%' }}>
                                        <InputLabel id="demo-simple-select-helper-label">Personnel</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-helper-label"
                                            id="demo-simple-select-helper"
                                            value={id_personnel || ""}
                                            label="Catégorie"
                                            name="id_personnel"
                                            style={{ width: '100%' }}
                                            onChange={this.handleChange}
                                        >        
                                            {personnel.map((answer) => { 
                                                return  <MenuItem value={answer.id}>{answer.description}</MenuItem>
                                            })}       
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} md={12}> 
                                    <TextField
                                        id="outlined-required"
                                        label="Heures"
                                        style={{ width: '100%' }}
                                        type="number"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        value={heure_personnel}
                                        name="heure_personnel"
                                        onChange={this.handleChange}
                                    />
                                </Grid>
                            </Grid>
                        </DialogContent>

                        <DialogActions>
                            <Button onClick={() => this.setState({ openPersonnel: false })} style={{color:"black"}} >Annuler</Button>

                            <Button  type="submit" className="bg-gradient-danger" autoFocus style={{color:"white"}}> Enregistrer </Button>
                        </DialogActions>

                    </form>
                </Dialog>

                {/* Editer Rapport Personnel */}
                <Dialog
                    fullWidth={true}
                    maxWidth={"lg"}
                    open={openEditPersonnel}
                    onClose={() => this.setState({ openEditPersonnel: false })}                    
                >
                    <DialogTitle style={{textAlign: "center"}}>Editer rapport personnel</DialogTitle>

                    <form role="form" className="text-start" onSubmit={this.handleEditerPersonnelSubmit}>
                        <DialogContent>
                            <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <FormControl style={{ width: '100%' }}>
                                        <InputLabel id="demo-simple-select-helper-label">Catégorie</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-helper-label"
                                            id="demo-simple-select-helper"
                                            value={id_personnel || ""}
                                            label="Catégorie"
                                            name="id_personnel"
                                            style={{ width: '100%' }}
                                            onChange={this.handleChange}
                                        >        
                                            {personnel.map((answer) => { 
                                                return  <MenuItem value={answer.id}>{answer.categorie}</MenuItem>
                                            })}       
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <FormControl style={{ width: '100%' }}>
                                        <InputLabel id="demo-simple-select-helper-label">Personnel</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-helper-label"
                                            id="demo-simple-select-helper"
                                            value={id_personnel || ""}
                                            label="Catégorie"
                                            name="id_personnel"
                                            style={{ width: '100%' }}
                                            onChange={this.handleChange}
                                        >        
                                            {personnel.map((answer) => { 
                                                return  <MenuItem value={answer.id}>{answer.description}</MenuItem>
                                            })}       
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} md={12}> 
                                    <TextField
                                        id="outlined-required"
                                        label="Heures"
                                        style={{ width: '100%' }}
                                        type="number"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        value={heure_personnel}
                                        name="heure_personnel"
                                        onChange={this.handleChange}
                                    />
                                </Grid>
                            </Grid>
                        </DialogContent>

                        <DialogActions>
                            <Button onClick={() => this.setState({ openEditPersonnel: false })} style={{color:"black"}} >Annuler</Button>

                            <Button  type="submit" className="bg-gradient-danger" autoFocus style={{color:"white"}}> Enregistrer </Button>
                        </DialogActions>

                    </form>
                </Dialog>

                {/* Rapport Fourniture */}                
                <Dialog
                    fullWidth={true}
                    maxWidth={"lg"}
                    open={openFourniture}
                    onClose={() => this.setState({ openFourniture: false })}                    
                >
                    <DialogTitle style={{textAlign: "center"}}>Rapport Fourniture</DialogTitle>

                    <form role="form" className="text-start" onSubmit={this.handleSubmitFourniture}>
                        <DialogContent>
                            <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <FormControl style={{ width: '100%' }}>
                                        <InputLabel id="demo-simple-select-helper-label">Code</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-helper-label"
                                            id="demo-simple-select-helper"
                                            value={id_fourniture || ''}
                                            label="Catégorie"
                                            name="id_fourniture"
                                            style={{ width: '100%' }}
                                            onChange={this.handleChange}
                                        >        
                                            {approvisionnement.map((answer) => { 
                                                return  <MenuItem value={answer.id_fourniture}>{displayFournitureCode(answer.id_fourniture)}</MenuItem>
                                            })}       
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <FormControl style={{ width: '100%' }}>
                                        <InputLabel id="demo-simple-select-helper-label">Désignation</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-helper-label"
                                            id="demo-simple-select-helper"
                                            value={id_fourniture || ''}
                                            label="Désignation"
                                            name="id_fourniture"
                                            style={{ width: '100%' }}
                                            onChange={this.handleChange}
                                        >        
                                            {approvisionnement.map((answer) => { 
                                                return  <MenuItem value={answer.id_fourniture}>{displayFournitureDesign(answer.id_fourniture)}</MenuItem>
                                            })}       
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} md={12}> 
                                    <TextField
                                        id="outlined-required"
                                        label="Quantité"
                                        style={{ width: '100%' }}
                                        type="number"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        value={quantite_fourniture}
                                        name="quantite_fourniture"
                                        onChange={this.handleChange}
                                    />
                                </Grid>
                            </Grid>
                        </DialogContent>

                        <DialogActions>
                            <Button onClick={() => this.setState({ openFourniture: false })} style={{color:"black"}} >Annuler</Button>

                            <Button  type="submit" className="bg-gradient-danger" autoFocus style={{color:"white"}}> Enregistrer </Button>
                        </DialogActions>

                    </form>
                </Dialog>

                {/* Rapport Traitant */}
                <Dialog
                    fullWidth={true}
                    maxWidth={"lg"}
                    open={openTraitant}
                    onClose={() => this.setState({ openTraitant: false })}                    
                >
                    <DialogTitle style={{textAlign: "center"}}>Rapport Sous-traitant</DialogTitle>
                    <form role="form" className="text-start" onSubmit={this.handleSubmitTraitant} >
                        <DialogContent>
                            <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <FormControl style={{ width: '100%' }}>
                                        <InputLabel id="demo-simple-select-helper-label">Code</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-helper-label"
                                            id="demo-simple-select-helper"
                                            value={id_traitant || ""}
                                            label="Code"
                                            name="id_traitant"
                                            style={{ width: '100%' }}
                                            onChange={this.handleChange}
                                        >        
                                            {traitant.map((answer) => { 
                                                return  <MenuItem value={answer.id}>{answer.code}</MenuItem>
                                            })}       
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <FormControl style={{ width: '100%' }}>
                                        <InputLabel id="demo-simple-select-helper-label">Sous-traitant</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-helper-label"
                                            id="demo-simple-select-helper"
                                            value={id_traitant || ""}
                                            label="Code"
                                            name="id_traitant"
                                            style={{ width: '100%' }}
                                            onChange={this.handleChange}
                                        >        
                                            {traitant.map((answer) => { 
                                                return  <MenuItem value={answer.id}>{answer.designation}</MenuItem>
                                            })}       
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} md={12}> 
                                    <TextField
                                        id="outlined-required"
                                        label="Quantité"
                                        style={{ width: '100%' }}
                                        type="number"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        value={quantite_traitant}
                                        name="quantite_traitant"
                                        onChange={this.handleChange}
                                    />
                                </Grid>
                            </Grid>
                        </DialogContent>

                        <DialogActions>
                            <Button onClick={() => this.setState({ openTraitant: false })} style={{color:"black"}} >Annuler</Button>
                            <Button  type="submit" className="bg-gradient-danger" autoFocus style={{color:"white"}}> Enregistrer </Button>
                        </DialogActions>
                    </form>
                </Dialog>
                
                {/* Editer Rapport Traitant */}
                <Dialog
                    fullWidth={true}
                    maxWidth={"lg"}
                    open={openEditTraitant}
                    onClose={() => this.setState({ openEditTraitant: false })}                    
                >
                    <DialogTitle style={{textAlign: "center"}}>Editer rapport Sous-traitant</DialogTitle>
                    <form role="form" className="text-start" onSubmit={this.handleEditTraitantSubmit} >
                        <DialogContent>
                            <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                            <Grid item xs={12} md={6}>
                                    <FormControl style={{ width: '100%' }}>
                                        <InputLabel id="demo-simple-select-helper-label">Code</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-helper-label"
                                            id="demo-simple-select-helper"
                                            value={id_traitant || ""}
                                            label="Code"
                                            name="id_traitant"
                                            style={{ width: '100%' }}
                                            onChange={this.handleChange}
                                        >        
                                            {traitant.map((answer) => { 
                                                return  <MenuItem value={answer.id}>{answer.code}</MenuItem>
                                            })}       
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <FormControl style={{ width: '100%' }}>
                                        <InputLabel id="demo-simple-select-helper-label">Sous-traitant</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-helper-label"
                                            id="demo-simple-select-helper"
                                            value={id_traitant || ""}
                                            label="Code"
                                            name="id_traitant"
                                            style={{ width: '100%' }}
                                            onChange={this.handleChange}
                                        >        
                                            {traitant.map((answer) => { 
                                                return  <MenuItem value={answer.id}>{answer.designation}</MenuItem>
                                            })}       
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} md={12}> 
                                    <TextField
                                        id="outlined-required"
                                        label="Quantité"
                                        style={{ width: '100%' }}
                                        type="number"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        value={quantite_traitant}
                                        name="quantite_traitant"
                                        onChange={this.handleChange}
                                    />
                                </Grid>
                            </Grid>
                        </DialogContent>

                        <DialogActions>
                            <Button onClick={() => this.setState({ openEditTraitant: false })} style={{color:"black"}} >Annuler</Button>
                            <Button  type="submit" className="bg-gradient-danger" autoFocus style={{color:"white"}}> Enregistrer </Button>
                        </DialogActions>
                    </form>
                </Dialog>

                {/* Rapport Caisse */}
                <Dialog
                    fullWidth={true}
                    maxWidth={"lg"}
                    open={openCaisse}
                    onClose={() => this.setState({ openCaisse: false })}                    
                >
                    <DialogTitle style={{textAlign: "center"}}>Rapport Caisse</DialogTitle>
                    <form role="form" className="text-start" onSubmit={this.handleSubmitCaisse} >
                        <DialogContent>
                            <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                                <Grid item xs={12} md={6}> 
                                    <TextField
                                        id="outlined-required"
                                        label="Désignation"
                                        style={{ width: '100%' }}
                                        value={designation_caisse}
                                        name="designation_caisse"
                                        onChange={this.handleChange}
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}> 
                                    <TextField
                                        id="outlined-required"
                                        label="Somme"
                                        style={{ width: '100%' }}
                                        type="number"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        value={somme_caisse}
                                        name="somme_caisse"
                                        onChange={this.handleChange}
                                    />
                                </Grid>
                            </Grid>
                        </DialogContent>

                        <DialogActions>
                            <Button onClick={() => this.setState({ openCaisse: false })} style={{color:"black"}} >Annuler</Button>
                            <Button  type="submit" className="bg-gradient-danger" autoFocus style={{color:"white"}}> Enregistrer </Button>
                        </DialogActions>
                    </form>
                </Dialog>

                {/* Edit Rapport Caisse */}
                <Dialog
                    fullWidth={true}
                    maxWidth={"lg"}
                    open={openEditCaisse}
                    onClose={() => this.setState({ openEditCaisse: false })}                    
                >
                    <DialogTitle style={{textAlign: "center"}}>Editer rapport caisse</DialogTitle>
                    <form role="form" className="text-start" onSubmit={this.handleEditerCaiseSubmit} >
                        <DialogContent>
                            <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                                <Grid item xs={12} md={6}> 
                                    <TextField
                                        id="outlined-required"
                                        label="Désignation"
                                        style={{ width: '100%' }}
                                        value={designation_caisse}
                                        name="designation_caisse"
                                        onChange={this.handleChange}
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}> 
                                    <TextField
                                        id="outlined-required"
                                        label="Somme"
                                        style={{ width: '100%' }}
                                        type="number"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        value={somme_caisse}
                                        name="somme_caisse"
                                        onChange={this.handleChange}
                                    />
                                </Grid>
                            </Grid>
                        </DialogContent>

                        <DialogActions>
                            <Button onClick={() => this.setState({ openEditCaisse: false })} style={{color:"black"}} >Annuler</Button>
                            <Button  type="submit" className="bg-gradient-danger" autoFocus style={{color:"white"}}> Enregistrer </Button>
                        </DialogActions>
                    </form>
                </Dialog>


                <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={isDid} autoHideDuration={6000} onClose={() => this.setState({ isDid: false })} >
                    <Alert severity="success" sx={{ width: '100%' }} onClose={() => this.setState({ isDid: false })} >
                        Ajouté avec succès
                    </Alert>
                </Snackbar>

                <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={isNotDit} autoHideDuration={6000} onClose={() => this.setState({ isNotDit: false })} >
                    <Alert severity="error" sx={{ width: '100%' }} onClose={() => this.setState({ isNotDit: false })} >
                        Erreur d'enregistrement
                    </Alert>
                </Snackbar> 

                <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={errorMateriel} autoHideDuration={6000} onClose={() => this.setState({ errorMateriel: false })} >
                    <Alert severity="error" sx={{ width: '100%' }} onClose={() => this.setState({ errorMateriel: false })} >
                        Carburant insuffisant                    
                    </Alert>
                </Snackbar> 

                <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={errorFourniture} autoHideDuration={6000} onClose={() => this.setState({ errorFourniture: false })} >
                    <Alert severity="error" sx={{ width: '100%' }} onClose={() => this.setState({ errorFourniture: false })} >
                        Fourniture insuffisante                    
                    </Alert>
                </Snackbar> 
            </div> 
        )
    }
} 
