import React, { Component } from "react"; 
import axios from 'axios';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import { DataGrid } from '@mui/x-data-grid';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import ReactToPrint from 'react-to-print';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import PrintIcon from '@mui/icons-material/Print';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import CloseIcon from '@mui/icons-material/Close';
import Avatar from '@mui/material/Avatar';
import AnnouncementOutlinedIcon from '@mui/icons-material/AnnouncementOutlined';
import Box from '@mui/material/Box';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ProjetMenu  from "../../Layouts/ProjetMenu"; 
import Datee from "../../Components/App/Datee"

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
})

export default class SousTraitantsUse extends Component {

    constructor(props) {
        super(props)
        this.state = {   
            projects: [],
            rapports: [],
            bordereaux: [],
            borderauselected:[],
            rapport_traitants:[],
            traitants: [],

            projetId: this.props.projetId,
            id_rapport: null,
            id_borderau: null,

            isDid: false,
            isNotDit: false,
            openTraitant: false, 
            openEditTraitant: false,

            fait: new Date(),
            temps: "Beau",

            quantite: 0,
            observation: "",

            id_traitant: null,
            quantite_traitant:0
        }
        this.handleChange = this.handleChange.bind(this)
        this.handleSubmitTraitant = this.handleSubmitTraitant.bind(this)
        this.handleEditTraitantSubmit = this.handleEditTraitantSubmit.bind(this)
        this.idchange = this.idchange.bind(this)        
        this.dateChange = this.dateChange.bind(this)
    }

    componentDidMount() {
        const token = JSON.parse(localStorage.getItem("TOKEN"))
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };
        axios.get('https://api-ml.projectmanagementsolutions.net/api/rapport', config)
        .then(response => {
            this.setState({
                projects: response.data.projects,
                rapports: response.data.rapports,
                bordereaux: response.data.bordereaux,
                rapport_traitants: response.data.rapport_traitants,
                traitants: response.data.traitants
            });
        }).catch(error => {
            console.log("error", error)
        });
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
        if(e.target.name === "id_borderau"){
            const borderauselected = this.state.bordereaux.filter(c => c["id"] === e.target.value)
            this.setState({ borderauselected: borderauselected[0] });
            this.handleSubmit(e.target.value, this.state.fait)
        }        
    }

    handleSubmit(id_borderau, date){
        const fait = Math.floor(new Date(date).getTime() / 1000)
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);        
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };        

        const bodyParameters = {
            fait: fait,
            id_projet: this.props.projetId,
            id_bordereau: id_borderau, 
            temps: this.state.temps,
            quantite: this.state.quantite,
            observation: this.state.observation
        };        
        axios.post( 
            'https://api-ml.projectmanagementsolutions.net/api/rapport',
            bodyParameters,
            config
        ).then(response => {        
            console.log("return", response.data)    
            this.setState({id_rapport: response.data.id, observation: response.data.observation, quantite:response.data.quantite, temps:response.data.temps });
            this.componentDidMount()            
        }).catch(error => {
            this.setState({ isNotDit: true });
            console.log("error", error)
        });
    }

    handleSubmitTraitant(event) {
        event.preventDefault()
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);        
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };

        let hoursbyday = this.state.projects.filter(c => c["id"] === this.props.projetId)[0]
        hoursbyday = hoursbyday.hoursbyday 

        const traitant = this.state.traitants.filter(c => c["id"] === this.state.id_traitant)[0]
        const unite = traitant.unite

        let bodyParameters = {}
        if(unite === "Heure"){
            bodyParameters = {
                id_projet: this.props.projetId,
                id_rapport: this.state.id_rapport,
                id_traitant: this.state.id_traitant, 
                quantite: this.state.quantite_traitant,
                taux: traitant.prix,
            }
        }else if(unite === "Jour"){
            bodyParameters = {
                id_projet: this.props.projetId,
                id_rapport: this.state.id_rapport,
                id_traitant: this.state.id_traitant, 
                quantite: this.state.quantite_traitant,
                taux: (traitant.prix)/hoursbyday,
            }
        }else if(unite === "Mois"){
            bodyParameters = {
                id_projet: this.props.projetId,
                id_rapport: this.state.id_rapport,
                id_traitant: this.state.id_traitant, 
                quantite: this.state.quantite_traitant,
                taux: (traitant.prix)/(30 * hoursbyday),
            }
        }  

        axios.post( 
            'https://api-ml.projectmanagementsolutions.net/api/rapport-traitant',
            bodyParameters,
            config
        ).then(response => {        
            console.log("return", response.data)    
            this.setState({ isDid: true, openTraitant:false });
            this.componentDidMount()            
        }).catch(error => {
            this.setState({ errorMateriel: true });
            console.log("error", error)
        });    
    }

    handleEditTraitantSubmit(event) {
        event.preventDefault()
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);        
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };

        let hoursbyday = this.state.projects.filter(c => c["id"] === this.props.projetId)[0]
        hoursbyday = hoursbyday.hoursbyday 

        const traitant = this.state.traitants.filter(c => c["id"] === this.state.id_traitant)[0]
        const unite = traitant.unite

        let bodyParameters = {}
        if(unite === "Heure"){
            bodyParameters = {
                id_projet: this.props.projetId,
                id_rapport: this.state.id_rapport,
                id_traitant: this.state.id_traitant, 
                quantite: this.state.quantite_traitant,
                taux: traitant.prix,
            }
        }else if(unite === "Jour"){
            bodyParameters = {
                id_projet: this.props.projetId,
                id_rapport: this.state.id_rapport,
                id_traitant: this.state.id_traitant, 
                quantite: this.state.quantite_traitant,
                taux: (traitant.prix)/hoursbyday,
            }
        }else if(unite === "Mois"){
            bodyParameters = {
                id_projet: this.props.projetId,
                id_rapport: this.state.id_rapport,
                id_traitant: this.state.id_traitant, 
                quantite: this.state.quantite_traitant,
                taux: (traitant.prix)/(30 * hoursbyday),
            }
        }   
        
        axios.put( 
            'https://api-ml.projectmanagementsolutions.net/api/rapport-traitant/' + this.state.id_rapport_traitant,
            bodyParameters,
            config
        ).then(response => {        
            console.log("return", response.data)    
            this.setState({ isDid: true, openEditTraitant:false });
            this.componentDidMount()            
        }).catch(error => {
            this.setState({ errorMateriel: true });
            console.log("error", error)
        });    
    }

    editsoustraitant(id){
        const traitant = this.state.rapport_traitants.filter(c => c["id"] === id)
        this.setState({
            id_rapport_traitant: id,
            id_projet: this.props.projetId,
            id_traitant: traitant[0].id_traitant,
            quantite_traitant: traitant[0].quantite,
            openEditTraitant: true
        })
    }

    deletetraitant(id){
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);        
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };
        axios.delete( 
            'https://api-ml.projectmanagementsolutions.net/api/rapport-traitant/' + id,
            config
        ).then(response => {                     
            this.setState({ isDid: true });
            this.componentDidMount()
            
        }).catch(error => {
            this.setState({ isNotDit: true });
            console.log("error", error)
        });
    } 
    
    editTable(props){
        console.log('Ahmed', props)
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);        
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };    
        let bodyParameters = {}
        if(props.field === "quantite"){
            bodyParameters = {
                id_projet: props.row.id_projet,
                id_rapport: props.row.id_rapport,
                id_traitant: props.row.id_traitant, 
                quantite: props.value,
                taux: props.row.taux,
            };
        }
        axios.put( 
            'https://api-ml.projectmanagementsolutions.net/api/rapport-traitant/' + props.id,
            bodyParameters,
            config
        ).then(response => {        
            this.setState({ isDid: true});
            this.componentDidMount()            
        }).catch(error => {
            this.setState({ errorMateriel: true });
            console.log("error", error)
        });    
    }

    idchange(id){ 
        this.setState({
            projetId: id
        })
    }

    dateChange(date){ 
        this.setState({
            fait: date
        })
        if(this.state.id_borderau !== null){
            this.handleSubmit(this.state.id_borderau, date)
        }
    }

    render() {

        const {projetId, openEditTraitant, quantite_traitant, id_traitant, traitants, rapport_traitants, observation, quantite, borderauselected, bordereaux, id_borderau, temps, fait, id_rapport, openTraitant, isDid, isNotDit} = this.state

        const id_project = projetId
        
        const bordereauxToSelect = bordereaux.filter(c => c["id_projet"] === id_project)

        let rapport_traitant = []
        if(id_rapport === null){
            rapport_traitant = []
        }else{
            rapport_traitant = rapport_traitants.filter(c => c["id_rapport"] === id_rapport)
        }

        let traitant = []
        if(id_project === null){
            traitant = []
        }else{
            traitant = traitants.filter(c => c["id_projet"] === id_project)
        }

        const columnsTraitant = [
            { 
                field: 'code', 
                headerName: 'Code',
                flex: 1/5,
                renderCell: (cellValues) => {return  displayCode(cellValues.row.id_traitant)}
            },       
            { 
                field: 'designation', 
                headerName: 'Désignation',
                flex: 4/2,
                renderCell: (cellValues) => {return  displayDesign(cellValues.row.id_traitant)}
            },         
            { 
                field: 'quantite', 
                headerName: 'Quantité',
                flex: 1/5,
                editable: true,
                renderCell: (cellValues) => {return  cellValues.row.quantite.toLocaleString()}
            }, 
            { 
                field: 'editer', 
                headerName: '',
                flex: 1/5,
                renderCell: (cellValues) => {
                    return (
                        <Button
                            onClick={() => this.editsoustraitant(cellValues.row.id)}
                            variant="contained"
                            sx={{background:'#eff0f2', color:'black', height:30, ':hover':{background:'#2f4cdd', color:'white'}}}
                        >
                            Modifier
                        </Button>
                    )
                    
                }
            },        
            { 
                field: 'supprimer', 
                headerName: '',
                flex: 1/5,
                renderCell: (cellValues) => {
                    return (
                        <Button
                            onClick={() => { if (window.confirm('Êtes-vous sûr de le supprimer ?')){ this.deletetraitant(cellValues.row.id) }}}
                            variant="contained"
                            sx={{background:'#eff0f2', color:'black', height:30, ':hover':{background:'#e6374c', color:'white'}}}
                        >
                            Supprimer
                        </Button>
                    )
                }
            }
        ]

        const displayCode = (id) => {
            let resultat = ""
            resultat = traitants.filter(c => c["id"] === id)[0].code           
            return resultat
        }

        const displayDesign = (id) => {
            let resultat = ""
            resultat = traitants.filter(c => c["id"] === id)[0].designation           
            return resultat
        }        

        return ( 
            <React.Fragment> 
                <Grid sx={{ flexGrow: 1, mb:10}} container spacing={3} >
                    <Grid item xs={12} sm={6} md={2} >
                        <Button 
                            variant="contained" 
                            sx={{bgcolor:'#2f4cdd', textTransform: 'none', fontSize:16, height:50}} 
                            fullWidth
                            startIcon={<Avatar  sx={{ bgcolor: '#4777f4', height:28, width:28 }}><AddIcon sx={{fontSize:26}} /></Avatar>}
                            onClick={() => this.setState({ openTraitant: true })}
                        >
                            Ajouter un s.traitant
                        </Button>
                    </Grid>

                    <Grid item xs={12} sm={6} md={8} >
                        <Box style={{display: 'flex', flexDirection:'row'}}>
                            <ReactToPrint
                                trigger={() => {
                                    return  (
                                        <Button 
                                            variant="contained" 
                                            sx={{bgcolor:'#5d95ed', height:50}}
                                        >
                                            <PrintIcon sx={{fontSize:40}} /> 
                                        </Button>
                                    );
                                }}
                                content={() => this.componentRef}
                            />  

                            <Tooltip 
                                title={<React.Fragment>
                                    <Typography color="inherit" sx={{fontSize:20}} >Rapports de chantier</Typography>
                                    <Typography color="inherit" sx={{fontSize:16}} >
                                    Les rapports d'activités de chaque échelon sont rédigés chaque jour sur un forulaire conformement au prix du bordereau. 
                                    Un échelon peut produire plusieurs rapports par jour selon que les opérations éffectuées concernant plusieurs prix du bordereau. 
                                    Le rapport de chantier comportent les informations de date, de prix, de quantite executées, des matériels utilisés, du personnel, 
                                    des matériaux etc .....
                                    </Typography>
                                </React.Fragment>
                                }
                            >                        
                                <Button 
                                    variant="contained" 
                                    sx={{bgcolor:'#5d95ed', height:50, ml:3}}
                                >
                                    <AnnouncementOutlinedIcon/>
                                </Button>
                            </Tooltip> 
                        </Box>
                    </Grid>

                    <Grid item xs={12} sm={6} md={2} >
                        <ProjetMenu projetId={id_project} onChange={this.idchange} />
                    </Grid>

                    <Grid item xs={12} sm={6} md={12} >
                        <div className="card" style={{height: 600, borderRadius:16, padding:32 }} ref={el => (this.componentRef = el)}>
                            <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                                <Grid item xs={12} sm={6} md={2.5} > 
                                    <Datee onChange={this.dateChange} />
                                </Grid>

                                <Grid item xs={12} sm={6} md={9.5} style={{textAlign: "right"}} > 
                                    <FormControl >
                                        <RadioGroup
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="row-radio-buttons-group"
                                            onChange={this.handleChange}
                                        >
                                            <FormControlLabel 
                                                value="Beau" 
                                                name="temps" 
                                                control={<Radio  sx={{color: "#2f4cdd", '&.Mui-checked': {color: "#2f4cdd"}}}/>} 
                                                label="Beau" 
                                                checked={temps === "Beau"}
                                            />
                                            <FormControlLabel 
                                                value="Pluvieux" 
                                                name="temps" 
                                                control={<Radio  sx={{color: "#2f4cdd", '&.Mui-checked': {color: "#2f4cdd"}}}/>} 
                                                label="Pluvieux" 
                                                checked={temps === "Pluvieux"}
                                            />
                                            <FormControlLabel 
                                                value="Nuageux" 
                                                name="temps" 
                                                control={<Radio  sx={{color: "#2f4cdd", '&.Mui-checked': {color: "#2f4cdd"},}}/>} 
                                                label="Nuageux" 
                                                checked={temps === "Nuageux"}
                                            />
                                        </RadioGroup>                                        
                                    </FormControl>  
                                </Grid>

                                <Grid item xs={12} md={2.5}> 
                                    <FormControl sx={{ m: 1 }} style={{ width: '100%', marginTop:'-1px', marginLeft:'-1px' }}>
                                        <InputLabel id="demo-simple-select-helper-label">N° prix</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-helper-label"
                                            id="demo-simple-select-helper"
                                            value={id_borderau}
                                            label="N° prix"
                                            name="id_borderau"
                                            style={{ width: '100%' }}
                                            onChange={this.handleChange}
                                            sx={{bgcolor:'#e9eff6'}}
                                        >        
                                            {bordereauxToSelect.map((answer) => { 
                                                return  <MenuItem value={answer.id}>{answer.n_prix}  {answer.designation}</MenuItem>
                                            })}       
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} md={8.5}> 
                                    <TextField
                                        id="outlined-required"
                                        label="Désignation"
                                        name="designation"
                                        variant="outlined"
                                        style={{ width: '100%' }}
                                        value={borderauselected.designation || ''}
                                        sx={{bgcolor:'#e9eff6'}}
                                    />
                                </Grid>

                                <Grid item xs={12} md={1}> 
                                    <TextField
                                        id="outlined-required"
                                        label="Unité"
                                        style={{ width: '100%' }}
                                        value={borderauselected.unite || ''}
                                        sx={{bgcolor:'#e9eff6'}}
                                    />
                                </Grid>

                                <Grid item xs={12} md={12}>
                                    <div style={{ height: 350, width: '100%'}} >
                                        <DataGrid
                                            rows={rapport_traitant}
                                            columns={columnsTraitant}
                                            onCellEditCommit={(props, event) => this.editTable(props)}
                                            sx={{
                                                '.MuiDataGrid-columnSeparator': {
                                                    display: 'none',
                                                    },
                                                    '&.MuiDataGrid-root': {
                                                    border: 'none',
                                                    },
                                                '.css-1jbbcbn-MuiDataGrid-columnHeaderTitle': {
                                                    color:'black',
                                                    fontWeight: 600,
                                                    fontSize:18
                                                }
                                            }}
                                        />
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>

                    <Grid item xs={12} sm={6} md={12}>
                        <div className="card" style={{height: 200, borderRadius:16}} >
                            <TextField
                                id="outlined-multiline-static"
                                label="Observation"
                                multiline
                                rows={4}
                                value={observation}
                                style={{ width: `calc(100% - 32 px`, margin:32  }}
                                name="observation"
                                onChange={this.handleChange}
                                sx={{bgcolor:'#e9eff6'}}
                            />

                            <Button  
                                onClick={() => this.sendObservation()}
                                fullWidth
                                variant="contained" 
                                sx={{
                                    color:"white", 
                                    textTransform: 'none', 
                                    fontSize:20, 
                                    height:50, 
                                    backgroundColor:'#888da0',  
                                    borderRadius:0, 
                                    ':hover':{backgroundColor:'#2f4cdd'} 
                                }}
                                endIcon={<ChevronRightIcon sx={{fontSize:20}} />}
                            >
                                Cliquer pour enregistrer l'observation
                            </Button>
                        </div>                        
                    </Grid>
                </Grid>

                <Dialog
                    fullWidth={true}
                    maxWidth={"sm"}
                    open={openTraitant}
                    onClose={() => this.setState({ openTraitant: false })}                    
                >
                    <Box style={{display: 'flex', flexDirection:'row', justifyContent:'space-between', padding:32}} >
                        <Typography 
                            component="h1" 
                            variant="h1" 
                            sx={{color:"#313339", fontSize:26}}
                        >
                            Rapport sous-traitant
                        </Typography>

                        <IconButton onClick={() =>  this.setState({ openTraitant: false })} >   
                            <CloseIcon /> 
                        </IconButton>
                    </Box>

                    <form role="form" className="text-start" onSubmit={this.handleSubmitTraitant} >
                        <DialogContent>
                            <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <FormControl style={{ width: '100%' }}>
                                        <InputLabel id="demo-simple-select-helper-label">Code</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-helper-label"
                                            id="demo-simple-select-helper"
                                            value={id_traitant || ""}
                                            label="Code"
                                            name="id_traitant"
                                            style={{ width: '100%' }}
                                            onChange={this.handleChange}
                                            sx={{bgcolor:'#e9eff6'}}
                                        >        
                                            {traitant.map((answer) => { 
                                                return  <MenuItem value={answer.id}>{answer.code}</MenuItem>
                                            })}       
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <FormControl style={{ width: '100%' }}>
                                        <InputLabel id="demo-simple-select-helper-label">Sous-traitant</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-helper-label"
                                            id="demo-simple-select-helper"
                                            value={id_traitant || ""}
                                            label="Code"
                                            name="id_traitant"
                                            style={{ width: '100%' }}
                                            onChange={this.handleChange}
                                            sx={{bgcolor:'#e9eff6'}}
                                        >        
                                            {traitant.map((answer) => { 
                                                return  <MenuItem value={answer.id}>{answer.designation}</MenuItem>
                                            })}       
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} md={12}> 
                                    <TextField
                                        id="outlined-required"
                                        label="Quantité"
                                        style={{ width: '100%' }}
                                        type="number"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        value={quantite_traitant}
                                        name="quantite_traitant"
                                        onChange={this.handleChange}
                                        sx={{bgcolor:'#e9eff6'}}
                                    />
                                </Grid>
                            </Grid>
                        </DialogContent>

                        <Button  
                            type="submit" 
                            fullWidth
                            variant="contained" 
                            sx={{
                                color:"white", 
                                textTransform: 'none', 
                                fontSize:20, 
                                height:50, 
                                backgroundColor:'#888da0',  
                                borderRadius:0, 
                                ':hover':{backgroundColor:'#2f4cdd'} 
                            }}
                            endIcon={<ChevronRightIcon sx={{fontSize:20}} />}
                        >
                            Cliquer pour enregistrer le rapport
                        </Button>
                    </form>
                </Dialog>

                <Dialog
                    fullWidth={true}
                    maxWidth={"sm"}
                    open={openEditTraitant}
                    onClose={() => this.setState({ openEditTraitant: false })}                    
                >
                    <Box style={{display: 'flex', flexDirection:'row', justifyContent:'space-between', padding:32}} >
                        <Typography 
                            component="h1" 
                            variant="h1" 
                            sx={{color:"#313339", fontSize:26}}
                        >
                            Modifier rapport sous-traitant
                        </Typography>

                        <IconButton onClick={() =>  this.setState({ openEditTraitant: false })} >   
                            <CloseIcon /> 
                        </IconButton>
                    </Box>

                    <form role="form" className="text-start" onSubmit={this.handleEditTraitantSubmit} >
                        <DialogContent>
                            <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <FormControl style={{ width: '100%' }}>
                                        <InputLabel id="demo-simple-select-helper-label">Code</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-helper-label"
                                            id="demo-simple-select-helper"
                                            value={id_traitant}
                                            label="Code"
                                            name="id_traitant"
                                            style={{ width: '100%' }}
                                            onChange={this.handleChange}
                                            sx={{bgcolor:'#e9eff6'}}
                                        >        
                                            {traitant.map((answer) => { 
                                                return  <MenuItem value={answer.id}>{answer.code}</MenuItem>
                                            })}       
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <FormControl style={{ width: '100%' }}>
                                        <InputLabel id="demo-simple-select-helper-label">Sous-traitant</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-helper-label"
                                            id="demo-simple-select-helper"
                                            value={id_traitant}
                                            label="Code"
                                            name="id_traitant"
                                            style={{ width: '100%' }}
                                            onChange={this.handleChange}
                                            sx={{bgcolor:'#e9eff6'}}
                                        >        
                                            {traitant.map((answer) => { 
                                                return  <MenuItem value={answer.id}>{answer.designation}</MenuItem>
                                            })}       
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} md={12}> 
                                    <TextField
                                        id="outlined-required"
                                        label="Quantité"
                                        style={{ width: '100%' }}
                                        type="number"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        value={quantite_traitant}
                                        name="quantite_traitant"
                                        onChange={this.handleChange}
                                        sx={{bgcolor:'#e9eff6'}}
                                    />
                                </Grid>
                            </Grid>
                        </DialogContent>

                        <Button  
                            type="submit" 
                            fullWidth
                            variant="contained" 
                            sx={{
                                color:"white", 
                                textTransform: 'none', 
                                fontSize:20, 
                                height:50, 
                                backgroundColor:'#888da0',  
                                borderRadius:0, 
                                ':hover':{backgroundColor:'#2f4cdd'} 
                            }}
                            endIcon={<ChevronRightIcon sx={{fontSize:20}} />}
                        >
                            Cliquer pour modifier le rapport
                        </Button> 
                    </form>
                </Dialog>

                <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={isDid} autoHideDuration={6000} onClose={() => this.setState({ isDid: false })} >
                    <Alert severity="success" sx={{ width: '100%' }} onClose={() => this.setState({ isDid: false })} >
                        Ajouté avec succès
                    </Alert>
                </Snackbar>

                <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={isNotDit} autoHideDuration={6000} onClose={() => this.setState({ isNotDit: false })} >
                    <Alert severity="error" sx={{ width: '100%' }} onClose={() => this.setState({ isNotDit: false })} >
                        Erreur d'enregistrement
                    </Alert>
                </Snackbar> 
            </React.Fragment>   
        )
    }
}