import React, { Component } from "react";
import axios from 'axios';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import AnnouncementOutlinedIcon from '@mui/icons-material/AnnouncementOutlined';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default class Profil extends Component {

    constructor(props) {
        super(props)
        this.state = {
            id: null,
            name: "",
            email: "",
            password: "",
            password_c: "",
            isNotDit: false,
            isDid: false,
            isNotMatch:false
        }
        this.handleChange = this.handleChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
    }

    componentDidMount() {
        const userJson = localStorage.getItem("USER");
        const user = JSON.parse(userJson);
        if (user === null) {
            window.location.href = '/';
        } else {
            this.setState({
                id: user.id,
                name: user.name,
                email: user.email
            });
        }
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleSubmit(event) {  
        event.preventDefault()
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);        
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };

        if(this.state.password !== this.state.password_c){
            this.setState({ isNotMatch: true })            
        }else{

            const bodyParameters = {
                name: this.state.name, 
                email: this.state.email,
                password: this.state.password,
                password_confirmation: this.state.password_c,
            };

            axios.put( 
                'https://api-ml.projectmanagementsolutions.net/api/registerupdate/' + this.state.id,
                bodyParameters,
                config
            ).then(response => {                     
                this.setState({ isDid: true});
                localStorage.setItem("USER", JSON.stringify(response.data.user));
                this.componentDidMount()          
            }).catch(error => {
                this.setState({ isNotDit: true });
                console.log("error", error)
            });
        }
    }

    render() {

        const { name, email, isNotMatch, isDid, isNotDit} = this.state
        return (
            <React.Fragment>
                <Grid sx={{ flexGrow: 1, mb:2}} container spacing={3} >
                    <Grid item xs={12} sm={6} md={2} >
                        <Tooltip 
                            title={<React.Fragment>
                                <Typography color="inherit" sx={{fontSize:20}} >Approvisionnement</Typography>
                                <Typography color="inherit" sx={{fontSize:16}} >
                                Le personnel est composé de l'ensemble des personnes employées par le chantier. Il est subdivisé en catégorie, d'où l'on distingue 1ere, 2ème, "ème, 4ème ...
                                HC ... etc. Il s'agira ici de lister les differentes catégories avec leurs caractéristiques.
                                </Typography>
                            </React.Fragment>
                            }
                        >                        
                            <Button 
                                variant="contained" 
                                sx={{bgcolor:'#5d95ed', height:50}}
                            >
                                <AnnouncementOutlinedIcon/>
                            </Button>
                        </Tooltip>  
                    </Grid>

                    <Grid item xs={12} sm={6} md={10} />

                    <Grid item xs={12} sm={6} md={2} />
                        
                    <Grid item xs={12} sm={6} md={8} >
                        <div className="card" style={{ borderRadius:16 }}>
                            <form role="form" className="text-start" onSubmit={this.handleSubmit}>
                                <Grid sx={{ flexGrow: 1, p:4, mt:4, mb:4 }} container spacing={2}>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            required
                                            id="outlined-required"
                                            label="Nom"
                                            name="name"
                                            style={{ width: '100%' }}
                                            value={name}
                                            onChange={this.handleChange}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            required
                                            id="outlined-required"
                                            label="E-mail"
                                            name="email"
                                            style={{ width: '100%' }}
                                            value={email}
                                            onChange={this.handleChange}
                                        />
                                    </Grid>   

                                    <Grid item xs={12} md={6} sx={{mt:4}}>
                                        <TextField
                                            required
                                            id="outlined-required"
                                            label="Password"
                                            name="password"
                                            placeholder="wsX9C#)5"
                                            style={{ width: '100%' }}
                                            onChange={this.handleChange}
                                            type="password"
                                            autoComplete="current-password"
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={6} sx={{mt:4}}>
                                        <TextField
                                            required
                                            id="outlined-required"
                                            label="Password confirmation"
                                            name="password_c"
                                            placeholder="wsX9C#)5"
                                            style={{ width: '100%' }}
                                            onChange={this.handleChange}
                                            type="password"
                                            autoComplete="current-password"
                                        />
                                    </Grid> 
                                </Grid>

                                <Button  
                                    type="submit" 
                                    fullWidth
                                    variant="contained" 
                                    sx={{
                                        color:"white", 
                                        textTransform: 'none', 
                                        fontSize:20, 
                                        height:50, 
                                        backgroundColor:'#888da0',  
                                        borderRadius:0, 
                                        ':hover':{backgroundColor:'#2f4cdd'} 
                                    }}
                                    endIcon={<ChevronRightIcon sx={{fontSize:20}} />}
                                >
                                        Cliquer pour enregistrer les modifications
                                </Button> 
                            </form>
                        </div>
                    </Grid>
                </Grid>                

                <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={isDid} autoHideDuration={6000} onClose={() => this.setState({ isDid: false })} >
                    <Alert severity="success" sx={{ width: '100%' }} onClose={() => this.setState({ isDid: false })} >
                        Modifier avec succès
                    </Alert>
                </Snackbar>

                <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={isNotDit} autoHideDuration={6000} onClose={() => this.setState({ isNotDit: false })} >
                    <Alert severity="error" sx={{ width: '100%' }} onClose={() => this.setState({ isNotDit: false })} >
                        Erreur de modification
                    </Alert>
                </Snackbar> 

                <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={isNotMatch} autoHideDuration={6000} onClose={() => this.setState({ isNotMatch: false })} >
                    <Alert severity="error" sx={{ width: '100%' }} onClose={() => this.setState({ isNotMatch: false })} >
                        Les mots de passe que vous avez entrés ne sont pas identiques.
                    </Alert>
                </Snackbar>
            </React.Fragment>
        )
    }
}