import React, { Component } from "react";
import axios from 'axios';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import { DataGrid } from '@mui/x-data-grid';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import ReactToPrint from 'react-to-print';
import Tooltip  from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import PrintIcon from '@mui/icons-material/Print';
import Dropzone from 'react-dropzone'
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { CSVLink } from "react-csv";
import Avatar from '@mui/material/Avatar';
import AnnouncementOutlinedIcon from '@mui/icons-material/AnnouncementOutlined';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import csbup from "../../Assets/Images/csbup.png"
import csbdown from '../../Assets/Images/csbdown.png'
import csvup from '../../Assets/Images/csvup.png'
import ProjetMenu  from "../../Layouts/ProjetMenu"; 

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default class Personnels extends Component {

    constructor(props) {
        super(props)
        this.state = {
            personnels:[],
            projects:[],

            open:false,
            isDid:false,
            isNotDit:false,
            importation: false,
            voir: false,
            openEdit: false,
            loading: true,

            projetId: this.props.projetId,

            id:null,
            name:"",
            categorie:"",
            description:"",
            taux: 0,
            unite: "Heure",

            selectedFile: null,
            path:"",
            message:'',
        }
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.idchange = this.idchange.bind(this)
    }

    componentDidMount() {   
        const token = JSON.parse(localStorage.getItem("TOKEN"))
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };
        axios.get('https://api-ml.projectmanagementsolutions.net/api/personnel', config)
        .then(response => {    
            this.setState({ 
                projects: response.data.projects,
                personnels: response.data.personnels,
                loading: false
            });
        }).catch(error => {
        });
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleSubmit(event) {
        event.preventDefault()
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);        
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };

        const bodyParameters = {
            id_projet: this.state.projetId, 
            name: this.state.name,
            categorie: this.state.categorie, 
            description: this.state.description, 
            taux: this.state.taux, 
            unite: this.state.unite
        };

        axios.post( 
            'https://api-ml.projectmanagementsolutions.net/api/personnel',
            bodyParameters,
            config
        ).then(response => {  
            this.setState({ message:"Personnel ajouté avec succès", isDid: true, open:false });
            this.componentDidMount()            
        }).catch(error => {
            this.setState({ message:"Erreur d'enregistrement", isNotDit: true });
        });
    }

    fileDrop(acceptedFiles){
        this.setState({selectedFile: acceptedFiles[0],  path: acceptedFiles[0].path})
    }

    onFileUpload(){ 
        // Create an object of formData 
        const formData = new FormData(); 
        // Update the formData object 
        formData.append("File", this.state.selectedFile)
        formData.append("id_project", this.state.projetId)
        axios.post('https://api-ml.projectmanagementsolutions.net/api/personnel-import', formData)
        .then(response => {           
            this.setState({message: "Importé avec succès", isDid: true, importation:false });
            this.componentDidMount()
            
        }).catch(error => {
            this.setState({ message:"Erreur d'importation", isNotDit: true });
        });     
    }

    delete(id){
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);        
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };
        axios.delete( 
            'https://api-ml.projectmanagementsolutions.net/api/personnel/' + id,
            config
        ).then(response => {                     
            this.setState({message:"Personnel supprimé avec succès", isDid: true });
            this.componentDidMount()
            
        }).catch(error => {
            this.setState({ message:"Erreur de suppression", isNotDit: true });
        });    

    }

    editTable(props){
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);        
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };    
        let bodyParameters = {}

        if(props.field === "name"){
            bodyParameters = {
                id_projet: props.row.id_projet, 
                categorie: props.row.categorie, 
                description: props.row.description, 
                taux: props.row.taux, 
                unite: props.row.unite,
                name: props.value
            }
        }else if(props.field === "categorie"){
            bodyParameters = {
                id_projet: props.row.id_projet, 
                categorie: props.value, 
                description: props.row.description, 
                taux: props.row.taux, 
                unite: props.row.unite,
                name: props.row.name
            }
        }else if(props.field === "description"){
            bodyParameters = {
                id_projet: props.row.id_projet, 
                categorie: props.row.categorie, 
                description: props.value, 
                taux: props.row.taux, 
                unite: props.row.unite,
                name: props.row.name
            }
        }else if(props.field === "taux"){
            bodyParameters = {
                id_projet: props.row.id_projet, 
                categorie: props.row.categorie, 
                description: props.row.description, 
                taux: props.value, 
                unite: props.row.unite,
                name: props.row.name
            }
        }else if(props.field === "unite"){
            bodyParameters = {
                id_projet: props.row.id_projet, 
                categorie: props.row.categorie, 
                description: props.row.description, 
                taux: props.row.taux, 
                unite: props.value,
                name: props.row.name
            }
        }

        axios.put( 
            'https://api-ml.projectmanagementsolutions.net/api/personnel/' + props.id,
            bodyParameters,
            config
        ).then(response => {            
            this.setState({ message:"Personnel modifié avec succès", isDid: true});  
            this.componentDidMount()          
        }).catch(error => {
            this.setState({ message:"Erreur dans le modification", isNotDit: true });
        })
    }

    idchange(id){ 
        this.setState({
            projetId: id
        })
    }

    render() {

        const {categorie, message, projetId, loading, name, description, taux, unite, personnels, open, isDid, isNotDit, importation, voir, openEdit, id, path} = this.state

        const id_project = projetId

        let personnel = []
        if(id_project === null){
            personnel = []
        }else{
            personnel = personnels.filter(c => c["id_projet"] === id_project)
        }

        const columns = [
            { 
                field: 'name', 
                headerName: 'Personnel',
                editable: true,
                flex: 4/12
            },
            { 
                field: 'categorie', 
                headerName: 'Catégorie',
                editable: true,
                flex: 1/12
            },
            { 
                field: 'description', 
                headerName: 'Description',
                editable: true,
                flex: 3/12
            },
            { 
                field: 'taux', 
                headerName: 'Taux',
                editable: true,
                flex: 1/12,
                type: 'number',
                renderCell: (cellValues) => {return cellValues.row.taux.toLocaleString()}
            },
            { 
                field: 'unite', 
                headerName: 'Unité',
                editable: true,
                flex: 1/12
            }, 
            { 
                field: 'supprimer', 
                headerName: '',
                flex: 1/12,
                renderCell: (cellValues) => {
                    return (
                        <Button
                            onClick={() => { if (window.confirm('Êtes-vous sûr de le supprimer ?')){ this.delete(cellValues.row.id) }}}
                            variant="contained"
                            sx={{background:'#eff0f2', color:'black', height:30, ':hover':{background:'#e6374c', color:'white'}}}
                        >
                            Supprimer
                        </Button>
                    )
                    
                }
            }
        ]

        const headers = [
            { label: "Catégorie", key: "categorie" },
            { label: "Description", key: "description" },
            { label: "Taux", key: "taux" },
            { label: "Unité", key: "unite" }
        ];

        return ( 
            <React.Fragment>
                <Grid sx={{ flexGrow: 1, mb:2}} container spacing={3} >
                    <Grid item xs={12} sm={6} md={2} >
                        <Button 
                            variant="contained" 
                            sx={{bgcolor:'#2f4cdd', textTransform: 'none', fontSize:16, height:50}} 
                            fullWidth
                            startIcon={<Avatar  sx={{ bgcolor: '#4777f4', height:28, width:28 }}><AddIcon sx={{fontSize:26}} /></Avatar>}
                            onClick={() => this.setState({ open: true })}
                        >
                            Ajouter un personnel
                        </Button>
                    </Grid>

                    <Grid item xs={12} sm={6} md={8} >
                        <Box style={{display: 'flex', flexDirection:'row'}}>
                            <Button 
                                variant="contained" 
                                sx={{bgcolor:'#5d95ed', height:50, ':hover':{background:'#5d95ed'}}}
                                onClick={() => this.setState({ importation: true })}
                            >
                                <img src={csbup} style={{width:40}}/>
                            </Button>

                            <CSVLink 
                                data={personnel} 
                                headers={headers} 
                                filename={"personnels.csv"} 
                                className="btn" 
                                style={{height:50, backgroundColor:"#5d95ed", marginLeft:24, borderRadius:4, width:70}}
                            >
                                <img src={csbdown} style={{width:40, marginTop:'-6px', marginLeft:'-6px'}}/>
                            </CSVLink> 

                            <ReactToPrint
                                trigger={() => {
                                    return  (
                                        <Button 
                                            variant="contained" 
                                            sx={{bgcolor:'#5d95ed', height:50, ml:3}}
                                        >
                                            <PrintIcon sx={{fontSize:40}} /> 
                                        </Button>
                                    );
                                }}
                                content={() => this.componentRef}
                            />  

                            <Tooltip 
                                title={<React.Fragment>
                                    <Typography color="inherit" sx={{fontSize:20}} >Personnel</Typography>
                                    <Typography color="inherit" sx={{fontSize:16}} >
                                    Le personnel est composé de l'ensemble des personnes employées par le chantier. Il est subdivisé en catégorie, d'où l'on distingue 1ere, 2ème, "ème, 4ème ...
                                    HC ... etc. Il s'agira ici de lister les differentes catégories avec leurs caractéristiques.
                                    </Typography>
                                </React.Fragment>
                                }
                            >                        
                                <Button 
                                    variant="contained" 
                                    sx={{bgcolor:'#5d95ed', height:50, ml:3}}
                                >
                                    <AnnouncementOutlinedIcon/>
                                </Button>
                            </Tooltip>  
                        </Box>                        
                    </Grid>

                    <Grid item xs={12} sm={6} md={2} >
                        <ProjetMenu projetId={id_project} onChange={this.idchange} />
                    </Grid> 

                    <Grid item xs={12} sm={6} md={12} >
                        <div className="card" style={{ height: 570, width: '100%',borderRadius:16 }}>
                            <DataGrid
                                rows={personnel}
                                columns={columns}
                                onCellEditCommit={(props, event) => this.editTable(props)}
                                loading={loading}
                                elevation={10}
                                sx={{
                                    boxShadow: 2,
                                    borderRadius:4,
                                    '.MuiDataGrid-columnSeparator': {
                                        display: 'none',
                                        },
                                        '&.MuiDataGrid-root': {
                                        border: 'none',
                                        },
                                    '.css-1jbbcbn-MuiDataGrid-columnHeaderTitle': {
                                        color:'black',
                                        fontWeight: 600,
                                        fontSize:18
                                    }
                                }}
                            />
                        </div>
                    </Grid>                    
                </Grid>  

                <div  style={{ display: "none" }}>
                    <table className="table table-striped table-hover"  ref={el => (this.componentRef = el)}>
                        <thead>
                            <tr>
                                <th scope="col">Catégorie</th>
                                <th scope="col">Description</th>
                                <th scope="col">Taux</th>
                                <th scope="col">Unité</th>
                            </tr>
                        </thead>
                        <tbody>
                            {personnel.map((answer) => {
                                return (
                                    <tr>
                                        <td>{answer.categorie}</td>
                                        <td>{answer.description}</td>
                                        <td>{answer.taux}</td>
                                        <td>{answer.unite}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>

                <Dialog
                    fullWidth={true}
                    maxWidth={"sm"}
                    open={open}
                    onClose={() => this.setState({ open: false })}                    
                >
                    <Box style={{display: 'flex', flexDirection:'row', justifyContent:'space-between', padding:32}} >
                        <Typography 
                            component="h1" 
                            variant="h1" 
                            sx={{color:"#313339", fontSize:26}}
                        >
                            Nouveau personnel
                        </Typography>

                        <IconButton onClick={() =>  this.setState({ open: false })} >   
                            <CloseIcon /> 
                        </IconButton>
                    </Box>

                    <form role="form" className="text-start" onSubmit={this.handleSubmit}>
                        <DialogContent >
                            <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                                <Grid item xs={12} md={12}>
                                    <div className="input-group input-group-outline mb-3">
                                        <TextField
                                            id="outlined-required"
                                            label="Prénom et nom du personnel"
                                            name="name"
                                            placeholder="Amdadou Coulibaly"
                                            style={{ width: '100%' }}
                                            value={name}
                                            onChange={this.handleChange}
                                            sx={{bgcolor:'#e9eff6'}}
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <div className="input-group input-group-outline mb-3">
                                        <TextField
                                            required
                                            id="outlined-required"
                                            label="Catégorie"
                                            name="categorie"
                                            placeholder="HL6536"
                                            style={{ width: '100%' }}
                                            value={categorie}
                                            onChange={this.handleChange}
                                            sx={{bgcolor:'#e9eff6'}}
                                        />
                                    </div>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <div className="input-group input-group-outline mb-3">
                                        <TextField
                                            required
                                            id="outlined-required"
                                            label="Description"
                                            name="description"
                                            placeholder="HL6536"
                                            style={{ width: '100%' }}
                                            value={description}
                                            onChange={this.handleChange}
                                            sx={{bgcolor:'#e9eff6'}}
                                        />
                                    </div>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <div className="input-group input-group-outline mb-4">
                                        <TextField
                                            required
                                            id="outlined-required"
                                            label="Taux"
                                            name="taux"
                                            type="number"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            style={{ width: '100%' }}
                                            value={taux}
                                            onChange={this.handleChange}
                                            sx={{bgcolor:'#e9eff6'}}
                                        />
                                    </div>
                                </Grid>     

                                <Grid item xs={12} md={6}>
                                    <FormControl  style={{ width: '100%' }}>
                                        <InputLabel id="demo-simple-select-helper-label">unité</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-helper-label"
                                            id="demo-simple-select-helper"
                                            value={unite}
                                            label="unite"
                                            name="unite"
                                            style={{ width: '100%' }}
                                            onChange={this.handleChange}
                                            sx={{bgcolor:'#e9eff6'}}
                                        >                                        
                                            <MenuItem value="Heure">Heure</MenuItem>
                                            <MenuItem value="Jour">Jour</MenuItem>
                                            <MenuItem value="Mois">Mois</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>                                                                         
                            </Grid>
                        </DialogContent>

                        <Button  
                            type="submit" 
                            fullWidth
                            variant="contained" 
                            sx={{
                                color:"white", 
                                textTransform: 'none', 
                                fontSize:20, 
                                height:50, 
                                backgroundColor:'#888da0',  
                                borderRadius:0, 
                                ':hover':{backgroundColor:'#2f4cdd'} 
                            }}
                            endIcon={<ChevronRightIcon sx={{fontSize:20}} />}
                        >
                            Cliquer pour enregistrer le nouveau personnel
                        </Button>
                    </form>                    
                </Dialog>

                <Dialog
                    fullWidth={true}
                    maxWidth={"sm"}
                    open={importation}
                    onClose={() => this.setState({ importation: false })}  
                    sx={{'.css-tlc64q-MuiPaper-root-MuiDialog-paper':{bgcolor:'#fafafa'}}}                    
                >
                    <Box style={{display: 'flex', flexDirection:'row', justifyContent:'space-between', padding:32}} >
                        <Typography 
                            component="h1" 
                            variant="h1" 
                            sx={{color:"#313339", fontSize:26, pl:16}}
                        >
                            Importer un fichier CSV 
                        </Typography>

                        <IconButton onClick={() =>  this.setState({ importation: false })} >   
                            <CloseIcon /> 
                        </IconButton>
                    </Box>

                    <DialogContent style={{textAlign: "center",  padding:'0px 170px' }}>
                        <Dropzone onDrop={acceptedFiles  => this.fileDrop(acceptedFiles)}>
                            {({getRootProps, getInputProps}) => (
                                <section>
                                <div {...getRootProps()}>
                                    <input {...getInputProps()} />

                                    <img src={csvup} style={{ width: 260, marginLeft:'-50px', marginTop:80, marginBottom:20}} />
                                    
                                    {path === "" ? <p style={{marginTop:64}}>Faites glisser et déposez le fichier ici, ou cliquez pour sélectionner le fichier</p> : <p>{path}</p>}
                                </div>
                                </section>
                            )}
                        </Dropzone>                                    
                    </DialogContent>

                    {path === "" ? null :
                        <Button  
                            onClick={() => this.onFileUpload()}
                            fullWidth
                            variant="contained" 
                            sx={{
                                color:"white", 
                                textTransform: 'none', 
                                fontSize:20, 
                                height:50, 
                                backgroundColor:'#888da0',  
                                borderRadius:0, 
                                ':hover':{backgroundColor:'#2f4cdd'} 
                            }}
                            endIcon={<ChevronRightIcon sx={{fontSize:20}} />}
                        >
                            Cliquer pour Importer le fichier CSV
                        </Button>
                    }                                    
                </Dialog>

                <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={isDid} autoHideDuration={6000} onClose={() => this.setState({ isDid: false })} >
                    <Alert severity="success" sx={{ width: '100%' }} onClose={() => this.setState({ isDid: false })} >{message}</Alert>
                </Snackbar>

                <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={isNotDit} autoHideDuration={6000} onClose={() => this.setState({ isNotDit: false })} >
                    <Alert severity="error" sx={{ width: '100%' }} onClose={() => this.setState({ isNotDit: false })} >{message}</Alert>
                </Snackbar>   
             
            </React.Fragment>
            
        )
    }
}