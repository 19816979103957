import React, { Component } from "react";
import { Link } from "react-router-dom";
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import { styled } from '@mui/material/styles';
import MuiAppBar from '@mui/material/AppBar';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import SupervisedUserCircleOutlinedIcon from '@mui/icons-material/SupervisedUserCircleOutlined';
import LocalGasStationOutlinedIcon from '@mui/icons-material/LocalGasStationOutlined';
import TaskOutlinedIcon from '@mui/icons-material/TaskOutlined';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import PaidIcon from '@mui/icons-material/Paid';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import Inventory2Icon from '@mui/icons-material/Inventory2';
import BarChartIcon from '@mui/icons-material/BarChart';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import Grid from '@mui/material/Grid';
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import ListItemButton from '@mui/material/ListItemButton';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import DonutSmallIcon from '@mui/icons-material/DonutSmall';
import StorageRoundedIcon from '@mui/icons-material/StorageRounded';
import SouthWestRoundedIcon from '@mui/icons-material/SouthWestRounded';
import NorthEastRoundedIcon from '@mui/icons-material/NorthEastRounded';
import SettingsSuggestRoundedIcon from '@mui/icons-material/SettingsSuggestRounded';
import QueryStatsRoundedIcon from '@mui/icons-material/QueryStatsRounded';
import PlumbingIcon from '@mui/icons-material/Plumbing';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import SettingsIcon from '@mui/icons-material/Settings';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import logo from "../../Assets/Images/logob.png"
import Navbg from "../../Assets/Images/fond.png"
import FolderZipIcon from '@mui/icons-material/FolderZip';

const drawerWidth = 320;

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
  })(({ theme, open }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      width: `calc(100% - ${drawerWidth}px + 80px)`,
      marginLeft: `${drawerWidth}px`,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  }));
  
  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
  }));

export default class Asside extends Component {

    constructor(props) {
        super(props);
        this.state = {
            title: "Dashboard",
            link: window.location.pathname,
            user: {},
            open: true,
            isLoading: true,
            anchorEl: null,
            role: null,

            dash:false,
            entantant: false,
            utilis: false,
            exploi: false,
            stati: false
        }
        this.handleClick = this.handleClick.bind(this)
    }

    componentDidMount() {
        const userJson = localStorage.getItem("USER");
        const user = JSON.parse(userJson);
        const role = JSON.parse(localStorage.getItem("ROLE"));
        if (user === null) {
            window.location.href = '/';
        } else {
            this.setState({ user: user, isLoading: false, role: role });
        }
    }

    setlink(item) {
        this.setState({ link: item })        

        if (item === "/dashboard") {
            this.setState({ title: "Dashboard" })
        } else if (item === "/projets") {
            this.setState({ title: "Projets" })
        } else if (item === "/files") {
            this.setState({ title: "Fichiers" })
        } else if (item === "/borderau") {
            this.setState({ title: "Borderau des prix" })
        } else if (item === "/materiels") {
            this.setState({ title: "Matériels" })
        } else if (item === "/personnels") {
            this.setState({ title: "Personnels" })
        } else if (item === "/fournitures") {
            this.setState({ title: "Fournitures" })
        } else if (item === "/sous-traitants") {
            this.setState({ title: "Sous-traitants" })
        } else if (item === "/caisse") {
            this.setState({ title: "Caisse" })
        }  else if (item === "/ravitaillement") {
            this.setState({ title: "Ravitaillements" })
        }else if (item === "/utilisateurs") {
            this.setState({ title: "Gestion des compte d'utilisateurs" })
        } else if (item === "/approvisionnement") {
            this.setState({ title: "Approvisionnement" })
        } else if(item === "/appro-pusure"){
            this.setState({ title: "Appro. pièces d'usure" })
        }else if (item === "/use-materiels") {
            this.setState({ title: "Utilisation des matériels" })
        }else if (item === "/use-personnels") {
            this.setState({ title: "Utilisation du personnels" })
        }else if (item === "/use-fournitures") {
            this.setState({ title: "Utilisation des fournitures" })
        }else if (item === "/use-sous-traitans") {
            this.setState({ title: "Utilisation sous-traitants" })
        }else if (item === "/pieces-usure"){
            this.setState({ title: "Pièces d'usure" })
        }else if (item === "/use-caisse") {
            this.setState({ title: "Utilisation caisse" })
        }else if (item === "/rapports") {
            this.setState({ title: "Rapports journaliers" })
        }else if (item === "/carburants") {
            this.setState({ title: "Sorties de carburant" })
        } else if (item === "/prdetailles") {
            this.setState({ title: "Prix de revient détaillées" })
        } else if (item === "/prmoyens") {
            this.setState({ title: "Prix de revient moyens" })
        } else if (item === "/rdepenses") {
            this.setState({ title: "Récapitulatif de toutes les dépenses" })
        } else if (item === "/rdprix") {
            this.setState({ title: "Récapitulatif des dépenses par prix" })
        } else if (item === "/utilisations") {
            this.setState({ title: "Utilisation du matériel" })
        } else if (item === "/efficiences") {
            this.setState({ title: "Efficience du matériel" })
        } else if (item === "/statistiques") {
            this.setState({ title: "Statistiques diverses" })
        }else if (item === "/profil") {
            this.setState({ title: "Gérez vos informations, ainsi que la confidentialité et la sécurité de vos données" })
        }else if (item === "/depenses") {
            this.setState({ title: "Dépenses mensuelles" })
        }else if(item === "/use-piece-usure"){
            this.setState({ title: "Sortie pièce d'usure" })
        }else if (item === "/rapportsjournaliers") {
            this.setState({ title: "Liste des rapports journaliers" })
        }        
    }

    handleClick(event) { 
        this.setState({ 
            anchorEl: event.currentTarget,
            dash:false,
            entantant: false,
            utilis: false,
            exploi: false,
            stati: false
        })}

    logout() {
        localStorage.clear();
        window.location.href = '/';
    }

    ChangeOpen(){
        this.setState(
            { open: ! this.state.open },
            () => this.props.onChange(this.state.open)            
        )        
    }

    render() {

        const { title, link, open, user, anchorEl, isLoading, role, dash, entantant, utilis, exploi, stati} = this.state

        const displayAvatar = () => {
            if (typeof (user.name) !== "undefined") {
                return user.name.charAt(0)
            } else {
                return ""
            }
        }

        const displayName = () => {
            if (typeof (user.name) !== "undefined") {
                return user.name
            } else {
                return ""
            }
        }

        const displayEmail = () => {
            if (typeof (user.email) !== "undefined") {
                return user.email
            } else {
                return ""
            }
        }

        const openMenu = Boolean(anchorEl);

        return (
            <React.Fragment>    
                <CssBaseline />            
                
                <AppBar  
                    open={open} 
                    elevation={0} 
                    sx={{ bgcolor: "#ffffff", height:100, paddingLeft:'50px', }}
                >
                    <Toolbar sx={{paddingTop:2}}>
                        <IconButton 
                            onClick={() => this.ChangeOpen()}
                            edge="start" 
                        >
                            <ClearAllIcon sx={{fontSize:48, color:'black'}} />
                        </IconButton>

                        <Typography variant="h4" noWrap component="h1" sx={{color:'black'}}>{title}</Typography>

                        <Box sx={{ flexGrow: 1 }} />

                        <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                            
                        </Box>
                    </Toolbar>
                </AppBar>
                
                <Drawer 
                    sx={{
                        width: drawerWidth,
                        flexShrink: 0,
                        '& .MuiDrawer-paper': {
                            width: drawerWidth,
                            boxSizing: 'border-box',
                            backgroundImage: `url(${Navbg})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: '2000px 1024px',
                            // backgroundSize: 'cover',
                            // borderTopRightRadius:'140px 700px',
                            // borderBottomRightRadius:'140px 700px',
                            // backgroundPosition:"left -650px center",
                            //zIndex:9999
                        },
                      }}
                      variant="persistent"
                      anchor="left"
                      open={open}                    
                >
                    <DrawerHeader >
                        <img src={logo} alt="main_logo" style={{ width: 200, marginTop:14}} />
                    </DrawerHeader>                    

                    <List component="nav" sx={{ width: '90%', marginTop:4}}>
                        {/* item */}
                        <Link
                            to="/dashboard"
                            onClick={() => { 
                                this.setlink("/dashboard")
                                this.setState({
                                    dash: false, 
                                    entantant:false,
                                    utilis: false,
                                    exploi: false,
                                    stati: false
                                })}
                            }
                        >
                            <ListItemButton 
                                style={
                                    link === "/dashboard" ? 
                                    {backgroundColor:'#ffffff40', borderRadius:32, height:56, marginLeft:24, marginTop:16} : 
                                    {height:56, marginLeft:24, marginTop:16}
                                } 
                            >     
                                {link === "/dashboard" ? 
                                    <Avatar sx={{ m: 1, ml:'-16px', bgcolor: 'white',  width: 56, height: 56 }}>
                                        <DonutSmallIcon sx={{color:"#306bff" }} />
                                    </Avatar>
                                    :
                                    <ListItemIcon sx={{marginLeft:'-8px', paddingLeft:0}}>
                                       <DonutSmallIcon sx={{color:"white", fontSize:34 }} /> 
                                    </ListItemIcon>                                    
                                } 
                                <ListItemText primary="Dashboard"  primaryTypographyProps={{color:"white", fontSize: 20, fontWeight: 600 }}/>
                            </ListItemButton>    
                        </Link>   

                        <ListItemButton onClick={() => this.setState({
                            dash: !dash,
                            entantant: false,
                            utilis: false,
                            exploi: false,
                            stati: false
                        })} 
                            style={
                                dash ? 
                                { backgroundColor:'#ffffff40', borderRadius:32, height:56, marginLeft:24, marginTop:16} :
                                { height:56, marginLeft:24, marginTop:16}
                            } 
                        >  
                            {dash ?   
                                <Avatar sx={{ m: 1, ml:'-16px', bgcolor: 'white',  width: 56, height: 56 }}>
                                    <StorageRoundedIcon sx={{color:"#306bff" }} />
                                </Avatar>
                                :
                                <ListItemIcon sx={{marginLeft:'-8px', paddingLeft:0}}>
                                    <StorageRoundedIcon sx={{color:"white", fontSize:34 }} /> 
                                </ListItemIcon>
                            } 
                            <ListItemText primary="Base de données"  primaryTypographyProps={{color:"white", fontSize: 20, fontWeight: 600 }}/>
                            {dash ? <ExpandLess sx={{color:"white" }} /> : <ExpandMore sx={{color:"white" }} />}
                        </ListItemButton>

                        <Collapse in={dash} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <Link
                                    to="/projets"
                                    onClick={() => { this.setlink("/projets") }}
                                >
                                    <ListItemButton sx={{ pl: 8 }}>
                                        <ListItemIcon>
                                            <FolderOutlinedIcon sx={{color:"white" }}/>
                                        </ListItemIcon>
                                        <ListItemText primary="Projets" primaryTypographyProps={{color:"white", fontSize: 16}}/>
                                    </ListItemButton>
                                </Link>
                            </List>

                            <List component="div" disablePadding>
                                <Link
                                    to="/files"
                                    onClick={() => { this.setlink("/files") }}
                                >
                                    <ListItemButton sx={{ pl: 8 }}>
                                        <ListItemIcon>
                                            <FolderZipIcon sx={{color:"white" }}/>
                                        </ListItemIcon>
                                        <ListItemText primary="Tous les fichiers" primaryTypographyProps={{color:"white", fontSize: 16}}/>
                                    </ListItemButton>
                                </Link>
                            </List>

                            <List component="div" disablePadding>
                                <Link
                                    to="/borderau"
                                    onClick={() => { this.setlink("/borderau") }}
                                >
                                    <ListItemButton sx={{ pl: 8 }}>
                                        <ListItemIcon>
                                            <AssignmentOutlinedIcon sx={{color:"white" }}/>
                                        </ListItemIcon>
                                        <ListItemText primary="Bordereau des prix" primaryTypographyProps={{color:"white", fontSize: 16}}/>
                                    </ListItemButton>
                                </Link>
                            </List>

                            <List component="div" disablePadding>
                                <Link
                                    to="/materiels"
                                    onClick={() => { this.setlink("/materiels") }}
                                >
                                    <ListItemButton sx={{ pl: 8 }}>
                                        <ListItemIcon>
                                            <LocalShippingOutlinedIcon sx={{color:"white" }}/>
                                        </ListItemIcon>
                                        <ListItemText primary="Matériels" primaryTypographyProps={{color:"white", fontSize: 16}}/>
                                    </ListItemButton>
                                </Link>
                            </List>

                            <List component="div" disablePadding>
                                <Link
                                    to="/personnels"
                                    onClick={() => { this.setlink("/personnels") }}
                                >
                                    <ListItemButton sx={{ pl: 8 }}>
                                        <ListItemIcon>
                                            <PeopleAltOutlinedIcon sx={{color:"white" }}/>
                                        </ListItemIcon>
                                        <ListItemText primary="Personnels" primaryTypographyProps={{color:"white", fontSize: 16}}/>
                                    </ListItemButton>
                                </Link>
                            </List>

                            <List component="div" disablePadding>
                                <Link
                                    to="/fournitures"
                                    onClick={() => { this.setlink("/fournitures") }}
                                >
                                    <ListItemButton sx={{ pl: 8 }}>
                                        <ListItemIcon>
                                            <Inventory2OutlinedIcon sx={{color:"white" }}/>
                                        </ListItemIcon>
                                        <ListItemText primary="fournitures" primaryTypographyProps={{color:"white", fontSize: 16}}/>
                                    </ListItemButton>
                                </Link>
                            </List>

                            <List component="div" disablePadding>
                                <Link
                                    to="/sous-traitants"
                                    onClick={() => { this.setlink("/sous-traitants") }}
                                >
                                    <ListItemButton sx={{ pl: 8 }}>
                                        <ListItemIcon>
                                            <SupervisedUserCircleOutlinedIcon sx={{color:"white" }}/>
                                        </ListItemIcon>
                                        <ListItemText primary="Sous-traitants" primaryTypographyProps={{color:"white", fontSize: 16}}/>
                                    </ListItemButton>
                                </Link>
                            </List>

                            <List component="div" disablePadding>
                                <Link
                                    to="/pieces-usure"
                                    onClick={() => { this.setlink("/pieces-usure") }}
                                >
                                    <ListItemButton sx={{ pl: 8 }}>
                                        <ListItemIcon>
                                            <PlumbingIcon sx={{color:"white" }}/>
                                        </ListItemIcon>
                                        <ListItemText primary="Pièces d'usure" primaryTypographyProps={{color:"white", fontSize: 16}}/>
                                    </ListItemButton>
                                </Link>
                            </List>
                        </Collapse>

                        {/* item */}
                        <ListItemButton 
                            onClick={() => this.setState({
                                entantant: ! entantant,
                                dash: false, 
                                utilis: false,
                                exploi: false,
                                stati: false
                            })} 
                            style={
                                entantant ? 
                                { backgroundColor:'#ffffff40', borderRadius:32, height:56, marginLeft:24, marginTop:16} :
                                { height:56, marginLeft:24, marginTop:16}
                            }  
                        >       
                            {entantant ?   
                                <Avatar sx={{ m: 1, ml:'-16px', bgcolor: 'white',  width: 56, height: 56 }}>
                                    <SouthWestRoundedIcon sx={{color:"#306bff" }} />
                                </Avatar>
                                :
                                <ListItemIcon sx={{marginLeft:'-8px', paddingLeft:0}}>
                                    <SouthWestRoundedIcon sx={{color:"white", fontSize:34 }} /> 
                                </ListItemIcon>
                            }
                            <ListItemText primary="Les entrées"  primaryTypographyProps={{color:"white", fontSize: 20, fontWeight: 600 }}/>
                            {entantant ? <ExpandLess sx={{color:"white" }} /> : <ExpandMore sx={{color:"white" }} />}
                        </ListItemButton>

                        <Collapse in={entantant} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <Link
                                    to="/caisse"
                                    onClick={() => { this.setlink("/caisse") }}
                                >
                                    <ListItemButton sx={{ pl: 8 }}>
                                        <ListItemIcon>
                                            <AttachMoneyOutlinedIcon sx={{color:"white" }}/>
                                        </ListItemIcon>
                                        <ListItemText primary="Caisse" primaryTypographyProps={{color:"white", fontSize: 16}}/>
                                    </ListItemButton>
                                </Link>
                            </List>

                            <List component="div" disablePadding>
                                <Link
                                    to="/ravitaillement"
                                    onClick={() => { this.setlink("/ravitaillement") }}
                                >
                                    <ListItemButton sx={{ pl: 8 }}>
                                        <ListItemIcon>
                                            <LocalGasStationOutlinedIcon sx={{color:"white" }}/>
                                        </ListItemIcon>
                                        <ListItemText primary="Carburant" primaryTypographyProps={{color:"white", fontSize: 16}}/>
                                    </ListItemButton>
                                </Link>
                            </List>

                            <List component="div" disablePadding>
                                <Link
                                    to="/approvisionnement"
                                    onClick={() => { this.setlink("/approvisionnement") }}
                                >
                                    <ListItemButton sx={{ pl: 8 }}>
                                        <ListItemIcon>
                                            <Inventory2Icon sx={{color:"white" }}/>
                                        </ListItemIcon>
                                        <ListItemText primary="Fourniture" primaryTypographyProps={{color:"white", fontSize: 16}}/>
                                    </ListItemButton>
                                </Link>
                            </List>

                            <List component="div" disablePadding>
                                <Link
                                    to="/appro-pusure" onClick={() => { this.setlink("/appro-pusure") }}
                                >
                                    <ListItemButton sx={{ pl: 8 }}>
                                        <ListItemIcon>
                                            <PlumbingIcon sx={{color:"white" }}/>
                                        </ListItemIcon>
                                        <ListItemText primary="Pièces d'usure" primaryTypographyProps={{color:"white", fontSize: 16}}/>
                                    </ListItemButton>
                                </Link>
                            </List>
                        </Collapse>

                        {/* item */}
                        <ListItemButton 
                            onClick={() => this.setState({
                                utilis: !utilis,
                                dash: false, 
                                entantant:false,
                                exploi: false,
                                stati: false
                            })} 
                            style={
                                utilis ? 
                                { backgroundColor:'#ffffff40', borderRadius:32, height:56, marginLeft:24, marginTop:16} :
                                { height:56, marginLeft:24, marginTop:16}
                            }  
                        >       
                            {utilis ?   
                                <Avatar sx={{ m: 1, ml:'-16px', bgcolor: 'white',  width: 56, height: 56 }}>
                                    <NorthEastRoundedIcon sx={{color:"#306bff" }} />
                                </Avatar>
                                :
                                <ListItemIcon sx={{marginLeft:'-8px', paddingLeft:0}}>
                                    <NorthEastRoundedIcon sx={{color:"white", fontSize:34 }} /> 
                                </ListItemIcon>
                            }
                            <ListItemText primary="Utilisations"  primaryTypographyProps={{color:"white", fontSize: 20, fontWeight: 600 }}/>
                            {utilis ? <ExpandLess sx={{color:"white" }} /> : <ExpandMore sx={{color:"white" }} />}
                        </ListItemButton>

                        <Collapse in={utilis} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <Link
                                    to="/use-materiels"
                                    onClick={() => { this.setlink("/use-materiels") }}
                                >
                                    <ListItemButton sx={{ pl: 8 }}>
                                        <ListItemIcon>
                                            <LocalShippingIcon sx={{color:"white" }}/>
                                        </ListItemIcon>
                                        <ListItemText primary="Matériels" primaryTypographyProps={{color:"white", fontSize: 16}}/>
                                    </ListItemButton>
                                </Link>
                            </List>

                            <List component="div" disablePadding>
                                <Link
                                    to="/use-personnels"
                                    onClick={() => { this.setlink("/use-personnels") }}
                                >
                                    <ListItemButton sx={{ pl: 8 }}>
                                        <ListItemIcon>
                                            <PeopleAltIcon sx={{color:"white" }}/>
                                        </ListItemIcon>
                                        <ListItemText primary="Personnels" primaryTypographyProps={{color:"white", fontSize: 16}}/>
                                    </ListItemButton>
                                </Link>
                            </List>

                            <List component="div" disablePadding>
                                <Link
                                    to="/use-fournitures"
                                    onClick={() => { this.setlink("/use-fournitures") }}
                                >
                                    <ListItemButton sx={{ pl: 8 }}>
                                        <ListItemIcon>
                                            <Inventory2Icon sx={{color:"white" }}/>
                                        </ListItemIcon>
                                        <ListItemText primary="Fournitures" primaryTypographyProps={{color:"white", fontSize: 16}}/>
                                    </ListItemButton>
                                </Link>
                            </List>

                            <List component="div" disablePadding>
                                <Link
                                    to="/use-sous-traitans"
                                    onClick={() => { this.setlink("/use-sous-traitans") }}
                                >
                                    <ListItemButton sx={{ pl: 8 }}>
                                        <ListItemIcon>
                                            <SupervisedUserCircleIcon sx={{color:"white" }}/>
                                        </ListItemIcon>
                                        <ListItemText primary="Sous-traitants" primaryTypographyProps={{color:"white", fontSize: 16}}/>
                                    </ListItemButton>
                                </Link>
                            </List>

                            <List component="div" disablePadding>
                                <Link
                                    to="/use-caisse"
                                    onClick={() => { this.setlink("/use-caisse") }}
                                >
                                    <ListItemButton sx={{ pl: 8 }}>
                                        <ListItemIcon>
                                            <PaidIcon sx={{color:"white" }}/>
                                        </ListItemIcon>
                                        <ListItemText primary="Caisse" primaryTypographyProps={{color:"white", fontSize: 16}}/>
                                    </ListItemButton>
                                </Link>
                            </List>

                            <List component="div" disablePadding>
                                <Link
                                    to="/depenses"
                                    onClick={() => { this.setlink("/depenses") }}
                                >
                                    <ListItemButton sx={{ pl: 8 }}>
                                        <ListItemIcon>
                                            <PaidIcon sx={{color:"white" }}/>
                                        </ListItemIcon>
                                        <ListItemText primary="Dépenses mensuelles" primaryTypographyProps={{color:"white", fontSize: 16}}/>
                                    </ListItemButton>
                                </Link>
                            </List>

                            <List component="div" disablePadding>
                                <Link
                                    to="/use-piece-usure"
                                    onClick={() => { this.setlink("/use-piece-usure") }}
                                >
                                    <ListItemButton sx={{ pl: 8 }}>
                                        <ListItemIcon>
                                            <PlumbingIcon sx={{color:"white" }}/>
                                        </ListItemIcon>
                                        <ListItemText primary="Pièces d'usure" primaryTypographyProps={{color:"white", fontSize: 16}}/>
                                    </ListItemButton>
                                </Link>
                            </List>
                        </Collapse>

                        {/* item */}
                        <Link
                            to="/rapports"
                            onClick={() => { 
                                this.setlink("/rapports")
                                this.setState({
                                    dash: false, 
                                    entantant:false,
                                    utilis: false,
                                    exploi: false,
                                    stati: false
                                })}
                            }
                        >
                            <ListItemButton 
                                style={
                                    link === "/rapports" ? 
                                    {backgroundColor:'#ffffff40', borderRadius:32, height:56, marginLeft:24, marginTop:16} : 
                                    {height:56, marginLeft:24, marginTop:16}
                                } 
                            >     
                                {link === "/rapports" ? 
                                    <Avatar sx={{ m: 1, ml:'-16px', bgcolor: 'white',  width: 56, height: 56 }}>
                                        <TaskOutlinedIcon sx={{color:"#306bff" }} />
                                    </Avatar>
                                    :
                                    <ListItemIcon sx={{marginLeft:'-8px', paddingLeft:0}}>
                                       <TaskOutlinedIcon sx={{color:"white", fontSize:34 }} /> 
                                    </ListItemIcon>                                    
                                } 
                                <ListItemText primary="Rapports journaliers"  primaryTypographyProps={{color:"white", fontSize: 20, fontWeight: 600 }}/>
                            </ListItemButton>    
                        </Link>

                        {/* item */}
                        <ListItemButton 
                            onClick={() => this.setState({
                                exploi: !exploi,
                                dash: false, 
                                entantant:false,
                                utilis: false,
                                stati: false
                            })} 
                            style={
                                exploi ? 
                                { backgroundColor:'#ffffff40', borderRadius:32, height:56, marginLeft:24, marginTop:16} :
                                { height:56, marginLeft:24, marginTop:16}
                            }  
                        >       
                            {exploi ?   
                                <Avatar sx={{ m: 1, ml:'-16px', bgcolor: 'white',  width: 56, height: 56 }}>
                                    <SettingsSuggestRoundedIcon sx={{color:"#306bff" }} />
                                </Avatar>
                                :
                                <ListItemIcon sx={{marginLeft:'-8px', paddingLeft:0}}>
                                    <SettingsSuggestRoundedIcon sx={{color:"white", fontSize:34 }} /> 
                                </ListItemIcon>
                            }
                            <ListItemText primary="Exploitation"  primaryTypographyProps={{color:"white", fontSize: 20, fontWeight: 600 }}/>
                            {exploi ? <ExpandLess sx={{color:"white" }} /> : <ExpandMore sx={{color:"white" }} />}
                        </ListItemButton>

                        <Collapse in={exploi} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <Link
                                    to="/prdetailles"
                                    onClick={() => { this.setlink("/prdetailles") }}
                                >
                                    <ListItemButton sx={{ pl: 8 }}>
                                        <ListItemIcon>
                                            <PaidIcon sx={{color:"white" }}/>
                                        </ListItemIcon>
                                        <ListItemText primary="Prix de revient détaillées" primaryTypographyProps={{color:"white", fontSize: 16}}/>
                                    </ListItemButton>
                                </Link>
                            </List>

                            <List component="div" disablePadding>
                                <Link
                                    to="/prmoyens"
                                    onClick={() => { this.setlink("/prmoyens") }}
                                >
                                    <ListItemButton sx={{ pl: 8 }}>
                                        <ListItemIcon>
                                            <PaidIcon sx={{color:"white" }}/>
                                        </ListItemIcon>
                                        <ListItemText primary="Prix de revient moyens" primaryTypographyProps={{color:"white", fontSize: 16}}/>
                                    </ListItemButton>
                                </Link>
                            </List>

                            <List component="div" disablePadding>
                                <Link
                                    to="/rdepenses"
                                    onClick={() => { this.setlink("/rdepenses") }}
                                >
                                    <ListItemButton sx={{ pl: 8 }}>
                                        <ListItemIcon>
                                            <PaidIcon sx={{color:"white" }}/>
                                        </ListItemIcon>
                                        <ListItemText primary="Récap. de toutes les dépenses" primaryTypographyProps={{color:"white", fontSize: 16}}/>
                                    </ListItemButton>
                                </Link>
                            </List>

                            <List component="div" disablePadding>
                                <Link
                                    to="/rdprix"
                                    onClick={() => { this.setlink("/rdprix") }}
                                >
                                    <ListItemButton sx={{ pl: 8 }}>
                                        <ListItemIcon>
                                            <PaidIcon sx={{color:"white" }}/>
                                        </ListItemIcon>
                                        <ListItemText primary="Récap. dépenses par prix" primaryTypographyProps={{color:"white", fontSize: 16}}/>
                                    </ListItemButton>
                                </Link>
                            </List>
                        </Collapse>

                        {/* item */}
                        <ListItemButton 
                            onClick={() => this.setState({
                                stati: !stati,
                                dash: false, 
                                entantant:false,
                                utilis: false,
                                exploi: false,
                            })} 
                            style={
                                stati ? 
                                { backgroundColor:'#ffffff40', borderRadius:32, height:56, marginLeft:24, marginTop:16} :
                                { height:56, marginLeft:24, marginTop:16}
                            }  
                        >       
                            {stati ?   
                                <Avatar sx={{ m: 1, ml:'-16px', bgcolor: 'white',  width: 56, height: 56 }}>
                                    <QueryStatsRoundedIcon sx={{color:"#306bff" }} />
                                </Avatar>
                                :
                                <ListItemIcon sx={{marginLeft:'-8px', paddingLeft:0}}>
                                    <QueryStatsRoundedIcon sx={{color:"white", fontSize:34 }} /> 
                                </ListItemIcon>
                            }
                            <ListItemText primary="Statistiques"  primaryTypographyProps={{color:"white", fontSize: 20, fontWeight: 600 }}/>
                            {stati ? <ExpandLess sx={{color:"white" }} /> : <ExpandMore sx={{color:"white" }} />}
                        </ListItemButton>

                        <Collapse in={stati} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <Link
                                    to="/efficiences"
                                    onClick={() => { this.setlink("/efficiences") }}
                                >
                                    <ListItemButton sx={{ pl: 8 }}>
                                        <ListItemIcon>
                                            <LocalShippingOutlinedIcon sx={{color:"white" }}/>
                                        </ListItemIcon>
                                        <ListItemText primary="Efficience du matériel" primaryTypographyProps={{color:"white", fontSize: 16}}/>
                                    </ListItemButton>
                                </Link>
                            </List>

                            <List component="div" disablePadding>
                                <Link
                                    to="/statistiques"
                                    onClick={() => { this.setlink("/statistiques") }}
                                >
                                    <ListItemButton sx={{ pl: 8 }}>
                                        <ListItemIcon>
                                            <BarChartIcon sx={{color:"white" }}/>
                                        </ListItemIcon>
                                        <ListItemText primary="Statistiques diverses" primaryTypographyProps={{color:"white", fontSize: 16}}/>
                                    </ListItemButton>
                                </Link>
                            </List>
                        </Collapse>

                        {/* item */}
                        <Link
                            to="/utilisateurs"
                            onClick={() => { 
                                this.setlink("/utilisateurs")
                                this.setState({
                                    dash: false, 
                                    entantant:false,
                                    utilis: false,
                                    exploi: false,
                                    stati: false
                                })}
                            }
                        >
                            <ListItemButton 
                                style={
                                    link === "/utilisateurs" ? 
                                    {backgroundColor:'#ffffff40', borderRadius:32, height:56, marginLeft:24, marginTop:16} : 
                                    {height:56, marginLeft:24, marginTop:16}
                                } 
                            >     
                                {link === "/utilisateurs" ? 
                                    <Avatar sx={{ m: 1, ml:'-16px', bgcolor: 'white',  width: 56, height: 56 }}>
                                        <AdminPanelSettingsIcon sx={{color:"#306bff" }} />
                                    </Avatar>
                                    :
                                    <ListItemIcon sx={{marginLeft:'-8px', paddingLeft:0}}>
                                       <AdminPanelSettingsIcon sx={{color:"white", fontSize:34 }} /> 
                                    </ListItemIcon>                                    
                                } 
                                <ListItemText primary="Comptes"  primaryTypographyProps={{color:"white", fontSize: 20, fontWeight: 600 }}/>
                            </ListItemButton>    
                        </Link>
                    
                        <Card sx={{maxWidth: 250, ml: 2, mt:10, bgcolor:'transparent'  }} elevation={0}>
                            <CardHeader sx={{color:'white', fontSize:24}}
                                avatar={<Avatar sx={{ width: 32, height: 32, backgroundColor:'white', color:'#d32f2f' }}>{displayAvatar()}</Avatar>}
                                title={<Typography variant="h6" noWrap component="h1" sx={{color:'white', fontSize:16}}>{displayName()}</Typography>}
                                subheader={<Typography variant="h6" noWrap component="h1" sx={{color:'white', fontSize:14, marginTop:'-5px'}}>{role}</Typography>}
                                action={
                                    <IconButton aria-label="settings" 
                                        onClick={this.handleClick}
                                    >
                                        <SettingsIcon sx={{color:'white'}} />
                                    </IconButton> 
                                }                            
                            />
                            <Menu
                                id="basic-menu"
                                anchorEl={anchorEl}
                                open={openMenu}
                                onClose={() => this.setState({ anchorEl: null })}
                                onClick={() => this.setState({ anchorEl: null })}

                                MenuListProps={{ 'aria-labelledby': 'basic-button' }}
                                PaperProps={{
                                    elevation: 0,
                                    sx: {
                                        width: 320,
                                        //height: 320,
                                        overflow: 'visible',
                                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                        mt: 1.5,
                                        ml: 30,
                                        '& .MuiAvatar-root': {
                                            width: 32,
                                            height: 32,
                                            ml: -0.5,
                                            mr: 1,
                                        },
                                        '&:before': {
                                            content: '""',
                                            display: 'block',
                                            position: 'absolute',
                                            top: 100,
                                            left: '-4px',
                                            width: 10,
                                            height: 10,
                                            bgcolor: 'background.paper',
                                            transform: 'translateY(-50%) rotate(45deg)',
                                            zIndex: 0,
                                        },
                                    },
                                }}
                                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                            >
                                <MenuItem style={{ textAlign:'center', width:'center' }}>
                                    <Grid item xs={12} md={12} align="center">
                                        <Avatar align="center" sx={{marginBottom:'16px !important',   width: '64px !important', height: '64px !important', backgroundColor:'#d32f2f', color:'white' }}>{displayAvatar()}</Avatar>
                                        <span style={{fontSize:16, marginTop: '32px !important'}}>{displayName()}</span> <br/> 
                                        <span style={{fontSize:14, marginTop: '16px !important'}}>{displayEmail()}</span> 
                                    </Grid>
                                </MenuItem>

                                <MenuItem style={{ textAlign:'center', width:'center' }}>
                                    <Grid item xs={12} md={12} align="center">
                                        <Link
                                        to="/profil"
                                        onClick={() => { this.setlink("/profil") }}
                                    >
                                    
                                        <Chip
                                            style={{margin:8}}
                                            variant="outlined"
                                            label="Gérer votre compte"
                                        /> </Link>
                                    </Grid>
                                </MenuItem>

                                <Divider />                            

                                <MenuItem onClick={() => this.logout()}>
                                    <Grid item xs={12} md={12} align="center">
                                        <Button variant="outlined" color="error">Se déconnecter</Button>
                                    </Grid>
                                </MenuItem>
                            </Menu>
                        </Card>
                    </List>
                </Drawer>
            </React.Fragment>
        )
    }
}

