import React, { Component } from "react"; 
import Button from '@mui/material/Button';
import { DataGrid } from '@mui/x-data-grid';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import axios from "axios";
import { Chart } from "react-google-charts";
import ProjetMenu  from "../../Layouts/ProjetMenu";
import ProjetPeriode from "../../Layouts/ProjetPeriode"
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

export default class Statistiques extends Component {

    constructor(props) {
        super(props)
        this.state = {
            rapports: [],
            projects: [],
            bordereaux: [],
            rapport_caisses: [],
            rapport_fournitures: [],
            rapport_materiels: [],
            rapport_personnels: [],
            rapport_traitants: [],
            personnels:[],
            fournitures: [],
            traitants: [],
            carburants: [],
            materiels: [], 
            depenses: [],
            approvisionnements:[],
            allapprovisionnements:[],
            caisses:[],
            pieces: [],        
            rapport_pieces: [],
            appropieces: [],

            projetId: this.props.projetId,

            start: this.props.startDate,
            end: this.props.endDate,

            id_materiel: null,
            id_fourniture: null,
            id_pieceusure: null,

            optionFilter: "Prix et rendements moyens",
            materiauxFilter: "Toutes les fournitures",
            materielsFilter: "Tous les engins",
            piecesFilter: "Toutes pièces d'usure",
            anchorEl: null,
            anchorEl2: null,
            anchorEl3: null,
        }
        this.handleChange = this.handleChange.bind(this)
        this.idchange = this.idchange.bind(this)
        this.Datechange = this.Datechange.bind(this)
        this.handleClick = this.handleClick.bind(this)
        this.handleClick2 = this.handleClick2.bind(this)
        this.handleClick3 = this.handleClick3.bind(this)
    }

    componentDidMount() {
        const token = JSON.parse(localStorage.getItem("TOKEN"))
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };
        axios.get('https://api-ml.projectmanagementsolutions.net/api/rapport', config)
        .then(response => {
            this.setState({ 
                rapports: response.data.rapports,
                projects: response.data.projects,
                bordereaux: response.data.bordereaux,
                rapport_materiels: response.data.rapport_materiels,
                rapport_fournitures: response.data.rapport_fournitures,
                rapport_personnels: response.data.rapport_personnels,
                rapport_traitants: response.data.rapport_traitants,
                rapport_caisses: response.data.rapport_caisses,
                personnels: response.data.personnels,
                fournitures: response.data.fournitures,
                traitants: response.data.traitants,
                materiels: response.data.materiels,
                carburants: response.data.carburants,
                depenses: response.data.depenses,
                caisses: response.data.caisses,
                approvisionnements: response.data.approvisionnements,
                allapprovisionnements: response.data.allapprovisionnements,
                pieces: response.data.pieces,
                rapport_pieces: response.data.rapport_pieces,
                appropieces: response.data.appropieces,
            });
        }).catch(error => {
            console.log("error", error)
        });
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    idchange(id){  
        let projet = this.state.projects.filter(c => c["id"] === id)[0]

        this.setState(
            { 
                projetId: id,
                start: new Date(projet.start *1000),
                end: new Date(projet.close *1000),
            },
            () => this.props.onChange(id)
        )
    }

    Datechange(startDate, endDate){
        this.setState({start: startDate, end: endDate})
    }

    handleClick(event){
        this.setState({anchorEl: event.currentTarget});
    }

    handleClick2(event){
        this.setState({anchorEl2: event.currentTarget});
    }

    handleClick3(event){
        this.setState({anchorEl3: event.currentTarget});
    }

    render() {

        const {
            depenses, 
            anchorEl3, 
            anchorEl2, 
            anchorEl, 
            materielsFilter, 
            materiauxFilter, 
            optionFilter,  
            piecesFilter,
            projetId, 
            id_fourniture, 
            id_materiel, 
            id_pieceusure,
            caisses, 
            allapprovisionnements, 
            pieces,
            rapport_pieces,
            carburants, 
            materiels, 
            fournitures, 
            traitants, 
            personnels, 
            appropieces,
            rapport_fournitures, 
            rapport_traitants, 
            rapport_materiels, 
            rapport_personnels, 
            rapports, 
            rapport_caisses, 
            bordereaux, 
            projects, 
            start, 
            end
        } = this.state

        const id_project = projetId
        
        let name = ""
        let pays= ""
        let curency = ""
        let localite= ""
        if(projects.filter(c => c["id"] === id_project)[0] !== undefined){
            name = projects.filter(c => c["id"] === id_project)[0].name
            pays= projects.filter(c => c["id"] === id_project)[0].country
            curency = projects.filter(c => c["id"] === id_project)[0].currency
            localite = projects.filter(c => c["id"] === id_project)[0].location
        }

        const bordereau = bordereaux.filter(c => c["id_projet"] === id_project)
        
        const aa = rapports.filter(c => c["id_projet"] === id_project)
        const  bb = aa.filter(c => c["fait"] >= Math.floor(new Date(start).getTime() / 1000))
        const cc = bb.filter(c => c["fait"] <= Math.floor(new Date(end).getTime() / 1000) + 86400)
        let rapport = cc

        const carburant = carburants.filter(c => c["id_projet"] === id_project)

        let materiel = []
        if(id_project === null){
            materiel = []
        }else{
            materiel = materiels.filter(c => c["id_projet"] === id_project)
        }

        let caisse  = []
        if(id_project === null){
            caisse = []
        }else{
            caisse = caisses.filter(c => c["id_projet"] === id_project)
        }

        let caissetrie = []
        if(id_project === null){
            caissetrie = []
        }else{
            const a = caisses.filter(c => c["id_projet"] === id_project)
            const b = a.filter(c => c["start"] >= Math.floor(new Date(start).getTime() / 1000))
            caissetrie = b.filter(c => c["start"] <= Math.floor(new Date(end).getTime() / 1000) + 86400)
        }

        let gm = ""
        let type = ""
        let chassis = ""
        let taux = ""
        let owner = ""
        let ctheor = ""
        if(materiels.filter(c => c["id"] === id_materiel)[0] !== undefined){
            gm = materiels.filter(c => c["id"] === id_materiel)[0].gm
            type= materiels.filter(c => c["id"] === id_materiel)[0].type
            chassis = materiels.filter(c => c["id"] === id_materiel)[0].chassis
            taux = materiels.filter(c => c["id"] === id_materiel)[0].taux
            owner = materiels.filter(c => c["id"] === id_materiel)[0].owner
            ctheor = materiels.filter(c => c["id"] === id_materiel)[0].ctheorique
        }

        const displayCreation = (timestamp) => {
            var date = new Date(timestamp * 1000);
            return date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear()
        }

        const rapport_personnelItems = () => {
            let item = []
            rapport.map((answer) => {
                const c = rapport_personnels.filter(c => c["id_rapport"] === answer.id)
                c.map((sss) => {
                    item = item.concat(sss)
                })       
            })
            let pp = item.filter( (ele, ind) => ind === item.findIndex(elem => elem.id_personnel === ele.id_personnel))
            return pp
        }

        const rapport_materiauxItems = () => {
            let item = []
            rapport.map((answer) => {
                const c = rapport_fournitures.filter(c => c["id_rapport"] === answer.id)
                c.map((sss) => {
                    item = item.concat(sss)
                })       
            })
            let pp = item.filter( (ele, ind) => ind === item.findIndex(elem => elem.id_fourniture === ele.id_fourniture))
            return pp
        }

        const rapport_pieceItems = () => {
            let item = []
            rapport.map((answer) => {
                const c = rapport_pieces.filter(c => c["id_rapport"] === answer.id)
                c.map((sss) => {
                    item = item.concat(sss)
                })       
            })
            let pp = item.filter( (ele, ind) => ind === item.findIndex(elem => elem.id_pieceusure === ele.id_pieceusure))
            return pp
        }

        const rapport_materiauxItem = () => {
            let resultat = []
            let item = []
            let resultatb = rapport_fournitures.filter(c => c["id_fourniture"] === id_fourniture)
            resultat = resultatb.filter(c => c["id_projet"] === id_project)
            resultat.map((answer) => {
                const c = rapport.filter(c => c["id"] === answer.id_rapport)
                if(rapport.filter(c => c["id"] === answer.id_rapport)[0] !== undefined){
                    item = item.concat(answer)
                }               
            })
            return item
        }

        const rapport_pieceItem = () => {
            let resultat = []
            let item = []
            let resultatb = rapport_pieces.filter(c => c["id_pieceusure"] === id_pieceusure)
            resultat = resultatb.filter(c => c["id_projet"] === id_project)
            resultat.map((answer) => {
                const c = rapport.filter(c => c["id"] === answer.id_rapport)
                if(rapport.filter(c => c["id"] === answer.id_rapport)[0] !== undefined){
                    item = item.concat(answer)
                }               
            })
            return item
        }

        const columnsmateriauxItem = [
            { 
                field: 'code', 
                headerName: "Code",
                flex: 1/10,
                renderCell: (cellValues) => {return  displayCode(cellValues.row.id_fourniture) }
            },
            { 
                field: 'designation', 
                headerName: "Désignation",
                flex: 1/10,
                renderCell: (cellValues) => {return   displayDesignationmateriaux(cellValues.row.id_fourniture) }
            },
            { 
                field: 'unite', 
                headerName: 'Unité',
                flex: 1/10,
                renderCell: (cellValues) => {return  displayUnitemateriaux(cellValues.row.id_fourniture)}
            },
            { 
                field: 'fait', 
                headerName: 'Date de sortie',
                flex: 1/10,
                renderCell: (cellValues) => {return  rrr(cellValues.row.id_rapport)}
            },
            { 
                field: 'quantite', 
                headerName: "Quantité utilisée",
                flex: 2/10,
                renderCell: (cellValues) => {return  (cellValues.row.quantite).toLocaleString()}
            },
            { 
                field: 'taux', 
                headerName: "Prix unitaire",
                flex: 1/10,
                renderCell: (cellValues) => {return  (cellValues.row.taux).toLocaleString()}
            },
            { 
                field: 'montant', 
                headerName: "Montant",
                flex: 1/10,
                renderCell: (cellValues) => {return  (cellValues.row.quantite  * cellValues.row.taux ).toLocaleString()}
            }
        ]

        const columnspiecesItem = [
            { 
                field: 'designation', 
                headerName: "Désignation",
                flex: 1/10,
                renderCell: (cellValues) => {return displayDesignationPieces(cellValues.row.id_pieceusure) }
            },
            { 
                field: 'fait', 
                headerName: 'Date de sortie',
                flex: 1/10,
                renderCell: (cellValues) => {return  rrr(cellValues.row.id_rapport)}
            },
            { 
                field: 'gm', 
                headerName: 'Machine',
                flex: 1/10,
                renderCell: (cellValues) => {return  displayGM(cellValues.row.id_materiel)}
            },
            { 
                field: 'type', 
                headerName: 'Modèle',
                flex: 1/10,
                renderCell: (cellValues) => {return  displayType(cellValues.row.id_materiel) }
            }, 
            { 
                field: 'chassis', 
                headerName: 'N° Chassis',
                flex: 1/10,
                renderCell: (cellValues) => {return   displaychassis(cellValues.row.id_materiel) }
            },

            { 
                field: 'quantite', 
                headerName: "Quantité utilisée",
                flex: 2/10,
                renderCell: (cellValues) => {return  (cellValues.row.quantite).toLocaleString()}
            },
            { 
                field: 'taux', 
                headerName: "Prix unitaire",
                flex: 1/10,
                renderCell: (cellValues) => {return  (cellValues.row.taux).toLocaleString()}
            },
            { 
                field: 'montant', 
                headerName: "Montant",
                flex: 1/10,
                renderCell: (cellValues) => {return  (cellValues.row.quantite  * cellValues.row.taux ).toLocaleString()}
            }
        ]

        const rapport_traitantItems = () => {
            let item = []
            rapport.map((answer) => {
                const c = rapport_traitants.filter(c => c["id_rapport"] === answer.id)
                c.map((sss) => {
                    item = item.concat(sss)
                })       
            })
            let pp = item.filter( (ele, ind) => ind === item.findIndex(elem => elem.id_traitant === ele.id_traitant))
            return pp
        }        

        const rapport_caisseItems  = () => {
            let item = []
            rapport.map((answer) => {
                const c = rapport_caisses.filter(c => c["id_rapport"] === answer.id)
                c.map((sss) => {
                    item = item.concat(sss)
                })
            })
            return item
        }

        const caisseEntrantSort = () => {
            let first = caissetrie
            let second = rapport_caisseItems()
            first = first.map(el => (  {...el, date:el.start, n_prix:'', entrant:el.montant, sortie:0} ))
            let second2= []
            let prixxxx = ""
            let second1 = second.map(el => {
                let a = rapports.filter(c => c["id"] === el.id_rapport)
                if(a[0] !== undefined){
                    a = a[0].fait
                }else{
                    a=  0
                }
                let b = rapport.filter(c => c["id"] === el.id_rapport)
                if(b[0] !== undefined){
                    const id_bordereau = b[0].id_bordereau
                    prixxxx = bordereaux.filter(c => c["id"] === id_bordereau)[0].n_prix + " " + bordereaux.filter(c => c["id"] === id_bordereau)[0].designation	
                }    
                second2 = second2.concat( {...el, date:a, n_prix:prixxxx, entrant:0, sortie: el.somme } )
            })
            second2.map((sss) => { 
                first = first.concat(sss)
            })     
            return first
        }

        const rapport_depenseItems = () => {
            let item = depenses.filter(c => c["id_projet"] === id_project)
            let ite = item.filter(c => c["start"] >= Math.floor(new Date(start).getTime() / 1000))
            let it = ite.filter(c => c["start"] <= Math.floor(new Date(end).getTime() / 1000) + 86400)
            return it
        }

        const rapport_salaireItems = () => {
            let item = []
            let c =  personnels.filter(c => c["id_projet"] === id_project)
            let b = c.filter(c => c["unite"] === "Mois")
            item = b 
            return item
        }

        const rapport_materielsItems = () => {
            let item = []
            rapport.map((answer) => {
                const c = rapport_materiels.filter(c => c["id_rapport"] === answer.id)
                c.map((sss) => {
                    item = item.concat(sss)
                })       
            })
            let pp = item.filter( (ele, ind) => ind === item.findIndex(elem => elem.id_materiel === ele.id_materiel))
            return pp
        }

        const columnsrapport = [
            { 
                field: 'n_prix', 
                headerName: 'N° prix',
                flex: 1/10,
                renderCell: (cellValues) => { return displayBorderaux(cellValues.row.id_bordereau) }
            },
            { 
                field: 'designation', 
                headerName: 'Désignation',
                flex: 4/5,
                renderCell: (cellValues) => { return displayDesignation(cellValues.row.id_bordereau) }
            },
            { 
                field: 'unite', 
                headerName: 'Unité',
                flex: 1/15,
                renderCell: (cellValues) => { return displayUnite(cellValues.row.id_bordereau) }
            },
            { 
                field: 'rendement', 
                headerName: 'Rendement',
                flex: 1/10,
                type: 'number',
                renderCell: (cellValues) => { return (cellValues.row.quantite).toLocaleString() }
            },
            { 
                field: 'prix', 
                headerName: 'Prix de revient',
                flex: 1/5,
                type: 'number',
                renderCell: (cellValues) => { return (displayPrixrevient(cellValues.row.id, cellValues.row.quantite)).toLocaleString()}
            },
            
        ]

        const displayBorderaux  = (id_bordereau) => { return bordereau.filter(c => c["id"] === id_bordereau)[0].n_prix }

        const displayDesignation  = (id_bordereau) => { return bordereau.filter(c => c["id"] === id_bordereau)[0].designation}

        const displayUnite = (id_bordereau) => { return bordereau.filter(c => c["id"] === id_bordereau)[0].unite}

        const displayPrixrevient =  (id_rapport, quantite) => {
            return (displayMcar(id_rapport) + displayMmater(id_rapport) + displayMper(id_rapport) + displayMfour(id_rapport) + displayMtraitant(id_rapport) + displayMcaiss(id_rapport))/quantite
        }
        
        const displayMtraitant = (id_rapport) => {
            let resultat = 0
            let item = []
            let items = []
            rapport_traitant(id_rapport).map((answer) => { 
                item = rapport_traitants.filter(c => c["id_traitant"] === answer.id_traitant )
                items =  item.filter(c => c["id_rapport"] === id_rapport )
                items.map((answer) => {                 
                    resultat = resultat + (answer.quantite * answer.taux)
                })            
            }) 
            return resultat
        }

        const rapport_traitant = (id_rapport) => {
            let resultat = []
            resultat = rapport_traitants.filter(c => c["id_rapport"] === id_rapport)
            let pp = resultat.filter( (ele, ind) => ind === resultat.findIndex( elem => elem.id_traitant === ele.id_traitant))
            return pp
        }

        const displayMcaiss = (id_rapport) => {
            let resultat = 0
            rapport_caisse(id_rapport).map((answer) => {
                resultat = resultat + answer.somme
            })
            return resultat
        }

        const rapport_caisse = (id_rapport) => { return rapport_caisses.filter(c => c["id_rapport"] === id_rapport)} 

        const displayMfour = (id_rapport) => {
            let resultat = 0
            let item = []
            let items = []
            rapport_fourniture(id_rapport).map((answer) => { 
                item = rapport_fournitures.filter(c => c["id_fourniture"] === answer.id_fourniture )
                items =  item.filter(c => c["id_rapport"] === id_rapport )
                items.map((answer) => {                 
                    resultat = resultat + (answer.quantite * answer.taux)
                })            
            }) 
            return resultat
        }

        const rapport_fourniture = (id_rapport) => {
            let resultat = []
            resultat = rapport_fournitures.filter(c => c["id_rapport"] === id_rapport) 
            let pp = resultat.filter( (ele, ind) => ind === resultat.findIndex( elem => elem.id_fourniture	 === ele.id_fourniture	))
            return pp
        }

        const displayMper = (id_rapport) => {
            let resultat = 0
            let item = []
            let items = []
            rapport_personnel(id_rapport).map((answer) => { 
                item = rapport_personnels.filter(c => c["id_personnel"] === answer.id_personnel )
                items =  item.filter(c => c["id_rapport"] === id_rapport )
                items.map((answer) => {                 
                    resultat = resultat + (answer.heures * answer.taux)
                })            
            }) 
            return resultat
        }

        const rapport_personnel = (id_rapport) => {
            let resultat = []
            resultat = rapport_personnels.filter(c => c["id_rapport"] === id_rapport)
            let pp = resultat.filter( (ele, ind) => ind === resultat.findIndex( elem => elem.id_personnel === ele.id_personnel))
            return pp
        }
        
        const displayMmater = (id_rapport) => {
            let resultat = 0
            let items = []
            let item = []
            rapport_materiel(id_rapport).map((answer) => { 
                item = rapport_materiels.filter(c => c["id_materiel"] === answer.id_materiel )
                items =  item.filter(c => c["id_rapport"] === id_rapport )
                items.map((answer) => {                 
                    resultat = resultat + (answer.hj * answer.taux)
                })            
            }) 
            return resultat
        }

        const displayMcar = (id_rapport) => {
            let resultat = 0
            let items = []
            let item = []
            rapport_materiel(id_rapport).map((answer) => { 
                item = rapport_materiels.filter(c => c["id_materiel"] === answer.id_materiel )
                items =  item.filter(c => c["id_rapport"] === id_rapport )
                items.map((answer) => {                 
                    resultat = resultat + (answer.carburant * answer.prix)
                })            
            })            
            return resultat
        }

        const rapport_materiel = (id_rapport) => {
            let resultat = []
            resultat = rapport_materiels.filter(c => c["id_rapport"] === id_rapport)
            let pp = resultat.filter( (ele, ind) => ind === resultat.findIndex( elem => elem.id_materiel === ele.id_materiel))
            return pp
        }  
        
        const list_un_materiel = () => {
            let resultat = []
            let item = []
            resultat = rapport_materiels.filter(c => c["id_materiel"] === id_materiel)
            resultat.map((answer) => {
                const c = rapport.filter(c => c["id"] === answer.id_rapport)
                if(rapport.filter(c => c["id"] === answer.id_rapport)[0] !== undefined){
                    item = item.concat(answer)
                }               
            })
            return item
        }

        const displayLocation =  () => {
            let resultat = 0
            list_un_materiel().map((answer) => {                
                resultat = resultat + (answer.hj * taux)
            })
            return resultat
        }

        const displayPUs =  () => {
            let resultat = 0
            list_un_materiel().map((answer) => {                
                resultat = resultat + answer.pusure
            })
            return resultat
        }

        const displayCarburant =  () => {
            let resultat = 0
            list_un_materiel().map((answer) => {                
                resultat = resultat + (answer.carburant * answer.prix)
            })
            return resultat
        }

        const columnspersonnelItems = [
            { 
                field: 'name', 
                headerName: 'Personnel',
                flex: 4/7,
                renderCell: (cellValues) => { return displayName(cellValues.row.id_personnel) }
            },
            { 
                field: 'code', 
                headerName: 'Catégorie',
                flex: 1/7,
                renderCell: (cellValues) => { return displayCategorie(cellValues.row.id_personnel) }
            },
            { 
                field: 'designation', 
                headerName: 'Désignation',
                flex: 1/7,
                renderCell: (cellValues) => { return displayDesignationPersonnel(cellValues.row.id_personnel) }
            },
            { 
                field: 'unite', 
                headerName: 'Unité',
                flex: 1/7,
                renderCell: (cellValues) => { return displayUnitePersonnel(cellValues.row.id_personnel) }
            },
            { 
                field: 'taux', 
                headerName: 'Taux',
                flex: 1/7,
                type: 'number',
                renderCell: (cellValues) => { return (cellValues.row.taux).toLocaleString() }
            },
            { 
                field: 'hj', 
                headerName: 'Total Heure',
                type: 'number',
                flex: 1/7,
                renderCell: (cellValues) => { return displayTotalH(cellValues.row.id_personnel) }
            },
            { 
                field: 'montant', 
                headerName: 'Montant',
                type: 'number',
                flex: 1/7,
                renderCell: (cellValues) => { return (displayTotalH(cellValues.row.id_personnel) * (cellValues.row.taux)).toLocaleString() }
            }
        ]

        const displayCategorie  = (id_personnel) => { return personnels.filter(c => c["id"] === id_personnel)[0].categorie }

        const displayName  = (id_personnel) => { return personnels.filter(c => c["id"] === id_personnel)[0].name }

        const displayDesignationPersonnel  = (id_personnel) => { return personnels.filter(c => c["id"] === id_personnel)[0].description }

        const displayUnitePersonnel  = (id_personnel) => { return personnels.filter(c => c["id"] === id_personnel)[0].unite }

        const displayTotalH  = (id_personnel) => { 
            let item = 0
            rapport.map((answer) => {
                const c = rapport_personnels.filter(c => c["id_rapport"] === answer.id)
                const b = c.filter(c => c["id_personnel"] === id_personnel)
                b.map((sss) => {
                    item = item + sss.heures
                })       
            })

            return item
        }

        const columnsmateriauxItems = [
            { 
                field: 'code', 
                headerName: 'Code',
                flex: 1/12,
                renderCell: (cellValues) => { return displayCode(cellValues.row.id_fourniture) }
            },
            { 
                field: 'designation', 
                headerName: 'Désignation',
                flex: 4/6,
                renderCell: (cellValues) => { return displayDesignationmateriaux(cellValues.row.id_fourniture) }
            },
            { 
                field: 'unite', 
                headerName: 'Unité',
                flex: 1/12,
                renderCell: (cellValues) => { return displayUnitemateriaux(cellValues.row.id_fourniture) }
            },
            { 
                field: 'taux', 
                headerName: 'Prix unitaire',
                flex: 1/12,
                type: 'number',
                renderCell: (cellValues) => { return (cellValues.row.taux).toLocaleString() }
            },
            { 
                field: 'quantite', 
                headerName: 'Quantité utilisée',
                flex: 1/12,
                type: 'number',
                renderCell: (cellValues) => { return displayTotalquantite(cellValues.row.id_fourniture).toLocaleString() }
            },
            { 
                field: 'montant', 
                headerName: 'Montant',
                flex: 1/9,
                type: 'number',
                renderCell: (cellValues) => { return (cellValues.row.taux * displayTotalquantite(cellValues.row.id_fourniture)).toLocaleString() }
            },
        ]

        const columnspiecesItems = [
            { 
                field: 'designation', 
                headerName: 'Désignation',
                flex: 2/4,
                renderCell: (cellValues) => { return displayDesignationPieces(cellValues.row.id_pieceusure) }
            },
            { 
                field: 'taux', 
                headerName: 'Prix unitaire',
                flex: 1/4,
                type: 'number',
                renderCell: (cellValues) => { return (cellValues.row.taux).toLocaleString() }
            },
            { 
                field: 'quantite', 
                headerName: 'Quantité utilisée',
                flex: 1/4,
                type: 'number',
                renderCell: (cellValues) => { return displayTotalquantitePiece(cellValues.row.id_pieceusure).toLocaleString() }
            },
            { 
                field: 'montant', 
                headerName: 'Montant',
                flex: 1/4,
                type: 'number',
                renderCell: (cellValues) => { return (cellValues.row.taux * displayTotalquantitePiece(cellValues.row.id_pieceusure)).toLocaleString() }
            },
        ]

        const displayCode = (id_fourniture) => { return fournitures.filter(c => c["id"] === id_fourniture)[0].code }

        const displayDesignationmateriaux = (id_fourniture) => { return fournitures.filter(c => c["id"] === id_fourniture)[0].designation }

        const displayDesignationPieces = (id_pieceusure) => { return pieces.filter(c => c["id"] === id_pieceusure)[0].designation }

        const displayUnitemateriaux = (id_fourniture) => { return fournitures.filter(c => c["id"] === id_fourniture)[0].unite }

        const displayTotalquantite = (id_fourniture) => { 
            let item = 0
            rapport.map((answer) => {
                const c = rapport_fournitures.filter(c => c["id_rapport"] === answer.id)
                const b = c.filter(c => c["id_fourniture"] === id_fourniture)
                b.map((sss) => {
                    item = item + sss.quantite
                })       
            })
            return item
        }

        const displayTotalquantitePiece = (id_pieceusure) => { 
            let item = 0
            rapport.map((answer) => {
                const c = rapport_pieces.filter(c => c["id_rapport"] === answer.id)
                const b = c.filter(c => c["id_pieceusure"] === id_pieceusure)
                b.map((sss) => {
                    item = item + sss.quantite
                })       
            })
            return item
        }

        const columnsTraitantItems  = [
            { 
                field: 'code', 
                headerName: 'code',
                flex: 1/12,
                renderCell: (cellValues) => { return displayCodetraitant(cellValues.row.id_traitant) }
            },
            { 
                field: 'designation', 
                headerName: 'Désignation',
                flex: 4/6,
                renderCell: (cellValues) => { return displayDesignationtraitant(cellValues.row.id_traitant) }
            },
            { 
                field: 'unite', 
                headerName: 'Unité',
                flex: 1/12,
                renderCell: (cellValues) => { return displayUnitetraitant(cellValues.row.id_traitant) }
            },
            { 
                field: 'taux', 
                headerName: 'Prix unitaire',
                flex: 1/6,
                type: 'number',
                renderCell: (cellValues) => { return (cellValues.row.taux).toLocaleString() }
            },
            { 
                field: 'quantite', 
                headerName: 'Quantité',
                flex: 1/12,
                type: 'number',
                renderCell: (cellValues) => { return displayTotalquantitetraitant(cellValues.row.id_traitant).toLocaleString() }
            },
            { 
                field: 'montant', 
                headerName: 'Montant',
                flex: 1/6,
                type: 'number',
                renderCell: (cellValues) => { return (cellValues.row.taux * displayTotalquantitetraitant(cellValues.row.id_traitant)).toLocaleString() }
            },
        ]

        const displayCodetraitant = (id_traitant) => { return traitants.filter(c => c["id"] === id_traitant)[0].code }
        const displayDesignationtraitant  = (id_traitant) => { return traitants.filter(c => c["id"] === id_traitant)[0].designation }
        const displayUnitetraitant = (id_traitant) => { return traitants.filter(c => c["id"] === id_traitant)[0].unite }

        const displayTotalquantitetraitant = (id_traitant) => { 
            let item = 0
            rapport.map((answer) => {
                const c = rapport_traitants.filter(c => c["id_rapport"] === answer.id)
                const b = c.filter(c => c["id_traitant"] === id_traitant)
                b.map((sss) => {
                    item = item + sss.quantite
                })       
            })
            return item
        }

        const displayn_prix  = (id_rapport) => { 
            let item = 0
            let c = rapport.filter(c => c["id"] ===id_rapport)
            if(c[0] !== undefined){
                const id_bordereau = c[0].id_bordereau
                item = bordereaux.filter(c => c["id"] === id_bordereau)[0].	n_prix + " " + bordereaux.filter(c => c["id"] === id_bordereau)[0].designation	
            }  
            return item
        }

        const conlumCaisse = [
            { 
                field: 'date', 
                headerName: "Date",
                flex: 1/12,
                renderCell: (cellValues) => {return  displayCreation(cellValues.row.date);}
            },
            { 
                field: 'designation', 
                headerName: 'Désignation',
                flex: 2/6
            },
            { 
                field: 'n_prix', 
                headerName: 'N° prix',
                flex: 2/6,
            },
            { 
                field: 'entrantt', 
                headerName: 'Entée',
                flex: 1/6,
                type: 'number',
                renderCell: (cellValues) => {
                    if(cellValues.row.entrant == 0 ) {
                        return null
                    }else{
                        return (cellValues.row.entrant).toLocaleString()
                    }                                       
                }
            },
            { 
                field: 'sortiee', 
                headerName: 'Sortie',
                flex: 1/6,
                type: 'number',
                renderCell: (cellValues) => {
                    if(cellValues.row.sortie == 0 ) {
                        return null
                    }else{
                        return (cellValues.row.sortie).toLocaleString()
                    }                                       
                }
            },
            { 
                field: 'solde', 
                headerName: 'Solde',
                flex: 1/6,
                type: 'number',
                renderCell: (cellValues) => { return  (cellValues.row.entrant - cellValues.row.sortie).toLocaleString() }
            }

        ]

        const columnsDepenseItems = [
            { 
                field: 'designation', 
                headerName: 'Désignation',
                flex: 2/2,
            },
            { 
                field: 'montant', 
                headerName: 'Montant',
                flex: 1/4,
                type: 'number',
                renderCell: (cellValues) => {return  (cellValues.row.montant).toLocaleString() }
            }
        ]

        const columnsMaterielItems = [
            { 
                field: 'gm', 
                headerName: 'Machine',
                flex: 1/5,
                renderCell: (cellValues) => {return  displayGM(cellValues.row.id_materiel)}
            },
            { 
                field: 'type', 
                headerName: 'Modèle',
                flex: 1/5,
                renderCell: (cellValues) => {return  displayType(cellValues.row.id_materiel)} 
            }, 
            { 
                field: 'chassis', 
                headerName: 'N° Chassis',
                flex: 1/5,
                renderCell: (cellValues) => {return   displaychassis(cellValues.row.id_materiel) }
            },
            { 
                field: 'consommation total', 
                headerName: 'Conso. totale réelle (L)',
                flex: 1/6,
                type: 'number',
                renderCell: (cellValues) => {return  displayConsommation(cellValues.row.id_materiel).toLocaleString() }
            },
            { 
                field: 'consommation total theorique', 
                headerName: 'Conso. totale théo.(L)',
                flex: 1/6,
                type: 'number',
                renderCell: (cellValues) => {return  displayConsommationtheori(cellValues.row.id_materiel).toLocaleString() }
            },
        ]

        const columnsSalaireItems = [ 
            { 
                field: 'name', 
                headerName: 'Personnel',
                flex: 4/7
            },
            { 
                field: 'categorie', 
                headerName: 'Catégorie',
                flex: 1/7
            },
            { 
                field: 'description', 
                headerName: 'Désignation',
                flex: 1/7
            },
            { 
                field: 'unite', 
                headerName: 'Unité',
                flex: 1/7
            },
            { 
                field: 'taux', 
                headerName: 'Taux',
                type: 'number',
                flex: 1/7,
                renderCell: (cellValues) => { return (cellValues.row.taux).toLocaleString() }
            },
            { 
                field: 'temps', 
                headerName: 'Total mois',
                type: 'number',
                flex: 1/7,
                renderCell: (cellValues) => { return displayTotalmois().toFixed(2) }
            },
            { 
                field: 'montant', 
                headerName: 'Montant',
                type: 'number',
                flex: 1/7,
                renderCell: (cellValues) => { return (displayTotalmois().toFixed(2) * (cellValues.row.taux)).toLocaleString() }
            }
        ]

        const displayTotalmois  = () => { 
            const a = (Math.floor(new Date(end).getTime() / 1000) -  Math.floor(new Date(start).getTime() / 1000))/(30*24*60*60)
            return a
        }

        const displayGM = (id) => { return materiels.filter(c => c["id"] === id)[0].gm}

        const displayGMr = (id) => { return  materiels.filter(c => c["id"] === id)[0].gm + " ("+ materiels.filter(c => c["id"] === id)[0].type + " " + materiels.filter(c => c["id"] === id)[0].chassis  + ")"}

        const displayType  = (id) => { return materiels.filter(c => c["id"] === id)[0].type}

        const displaychassis = (id) => { return materiels.filter(c => c["id"] === id)[0].chassis}

        const displayConsommation = (id_materiel) => { 
            let item = 0
            rapport.map((answer) => {
                const c = rapport_materiels.filter(c => c["id_rapport"] === answer.id)
                const b = c.filter(c => c["id_materiel"] === id_materiel)
                b.map((sss) => {
                    item = item + sss.carburant
                })       
            })
            return item
        }

        const displayConsommationtheori = (id_materiel) => { 
            let item = 0
            let  consotheor = materiels.filter(c => c["id"] === id_materiel)[0].ctheorique
            rapport.map((answer) => {
                const c = rapport_materiels.filter(c => c["id_rapport"] === answer.id)
                const b = c.filter(c => c["id_materiel"] === id_materiel)
                b.map((sss) => {
                    item = item + sss.hj
                })       
            })

            
            return item * consotheor
        }

        const columnsMaterielItemsmoyenn = [
            { 
                field: 'gm', 
                headerName: 'Machine',
                flex: 1/5,
                renderCell: (cellValues) => {return  displayGM(cellValues.row.id_materiel)}
            },
            { 
                field: 'type', 
                headerName: 'Modèle',
                flex: 1/5,
                renderCell: (cellValues) => {return  displayType(cellValues.row.id_materiel) }
            }, 
            { 
                field: 'chassis', 
                headerName: 'N° Chassis',
                flex: 1/5,
                renderCell: (cellValues) => {return   displaychassis(cellValues.row.id_materiel) }
            },
            { 
                field: 'consommation moyenne', 
                headerName: 'Conso. moyenne réelle (L)',
                flex: 1/6,
                type: 'number',
                renderCell: (cellValues) => {return  displayConsommationMoyenn(cellValues.row.id_materiel).toLocaleString() }
            },
            { 
                field: 'consommation moyenne theorique', 
                headerName: 'Conso. moyenne théo.(L)',
                flex: 1/6,
                type: 'number',
                renderCell: (cellValues) => {return  displayConsommationMoyenntheori(cellValues.row.id_materiel).toLocaleString() }
            },
        ]

        const displayConsommationMoyenn = (id_materiel) => { 
            let item = 0
            let n = 0
            rapport.map((answer) => {
                const c = rapport_materiels.filter(c => c["id_rapport"] === answer.id)
                const b = c.filter(c => c["id_materiel"] === id_materiel)                
                b.map((sss) => {
                    item = item + sss.carburant
                    n = n + 1
                })       
            })
            return item/n
        }

        const displayConsommationMoyenntheori = (id_materiel) => { 
            let item = 0
            let n = 0
            let  consotheor = materiels.filter(c => c["id"] === id_materiel)[0].ctheorique
            rapport.map((answer) => {
                const c = rapport_materiels.filter(c => c["id_rapport"] === answer.id)
                const b = c.filter(c => c["id_materiel"] === id_materiel)                
                b.map((sss) => {
                    item = item + sss.hj
                    n = n + 1
                })       
            })
            return (item/n) * consotheor
        }

        const columnsMaterielItemsmoyennccc = [
            { 
                field: 'type', 
                headerName: 'Carburant',
                flex: 1/2
            },
            { 
                field: 'Total', 
                headerName: 'Consommation',
                flex: 1/4,
                type: 'number',
                renderCell: (cellValues) => {return  displayTypeConsommation(cellValues.row.type)}
            }, 
        ]

        const displayTypeConsommation = (type) => {  
            let item = 0
            // let a = carburant.filter(c => c["type"] === type)[0].prix
            let a = []
            if(carburant.filter(c => c["type"] === type).length > 0){
                a = carburant.filter(c => c["type"] === type)[0].prix
            }else{
                a = []
            }

            rapport.map((answer) => {
                const c = rapport_materiels.filter(c => c["id_rapport"] === answer.id)
                const b = c.filter(c => c["prix"] === a)
                b.map((sss) => {
                    item = item + sss.carburant
                })       
            })
            return item
        }

        const columnsConsomPoint = [
            { 
                field: 'type', 
                headerName: 'Carburant',
                flex: 1/2
            },
            { 
                field: 'Total', 
                headerName: 'Consommation',
                flex: 1/4,
                type: 'number',
                renderCell: (cellValues) => {return  displayTypeConsommationPoint(cellValues.row.type)}
            }, 
        ]

        const displayTypeConsommationPoint = (type) => { 
            let item = []
            let a = carburant.filter(c => c["type"] === type)[0].prix
            rapport.map((answer) => {
                const c = rapport_materiels.filter(c => c["id_rapport"] === answer.id)
                const b = c.filter(c => c["prix"] === a)
                b.map((sss) => {
                    item = item.concat(sss)
                })       
            })
            return Math.max.apply(Math, item.map(function(o) { return o.carburant; }))

        }

        const getMateiel = () => { 
            let item = 0
            rapport.map((answer) => {
                const c = rapport_materiels.filter(c => c["id_rapport"] === answer.id)
                c.map((answerr) => {
                    item = item + (answerr.taux * answerr.hj)
                })       
            })
            return item
        }

        const getPusure = () => { 
            let item = 0
            rapport.map((answer) => {
                const c = rapport_pieces.filter(c => c["id_rapport"] === answer.id)
                c.map((answerr) => {
                    item = item + (answerr.taux * answerr.quantite)
                })       
            })
            return item
        }

        const getCraburant = () => { 
            let item = 0
            rapport.map((answer) => {
                const c = rapport_materiels.filter(c => c["id_rapport"] === answer.id)
                c.map((answerr) => {
                    item = item + (answerr.carburant * answerr.prix)
                })       
            })
            return item
        }

        const getPersonnel = () => { 
            let item = 0
            rapport.map((answer) => {
                const c = rapport_personnels.filter(c => c["id_rapport"] === answer.id)
                c.map((answerr) => {
                    item = item + (answerr.taux * answerr.heures)
                })       
            })
            return item
        }

        const getFourniture = () => { 
            let item = 0
            rapport.map((answer) => {
                const c = rapport_fournitures.filter(c => c["id_rapport"] === answer.id)
                c.map((answerr) => {
                    item = item + (answerr.taux * answerr.quantite)
                })       
            })
            return item
        }

        const getTraitant = () => { 
            let item = 0
            rapport.map((answer) => {
                const c = rapport_traitants.filter(c => c["id_rapport"] === answer.id)
                c.map((answerr) => {
                    item = item + (answerr.taux * answerr.quantite)
                }) 
            })
            return item
        }

        const getCaisse = () => { 
            let item = 0
            rapport.map((answer) => {
                const c = rapport_caisses.filter(c => c["id_rapport"] === answer.id)
                c.map((answerr) => {
                    item = item + answerr.somme
                })       
            })
            return item
        }

        const getDepense = () => { 
            let items = 0
            let item = depenses.filter(c => c["id_projet"] === id_project)
            let ite = item.filter(c => c["start"] >= Math.floor(new Date(start).getTime() / 1000))
            let it = ite.filter(c => c["start"] <= Math.floor(new Date(end).getTime() / 1000) + 86400)
            it.map((answerr) => {
                items = items + answerr.montant
            })
            return items
        }

        const getSalaire = () => { 
            let items = 0
            let c =  personnels.filter(c => c["id_projet"] === id_project)
            let b = c.filter(c => c["unite"] === "Mois")
            b.map((answerr) => {
                items = items + (displayTotalmois().toFixed(2) * (answerr.taux))
            })
            return items
        }
        
        const data = [
            ["Task", "Hours per Day"],
            ["Matériels", getMateiel()],
            ["Pièces d'usure", getPusure()],
            ["Carburant", getCraburant()],
            //["Personnel", (getPersonnel() + getSalaire())],
            ["Personnel", getPersonnel()],
            ["Matériaux", getFourniture()],
            ["Sous traitant", getTraitant()],
            ["caisse", getCaisse()],
            ["Dép. mensuelles", getDepense()]
        ];

        const optionsDepense = {
            //title: "My Daily Activities",
            is3D: true,
        };

        const datar = ()  => {
            let  arrayListe = [["Engin", "Conso. réelle", "Conso. théorique"]]
            rapport_materielsItems().map(element => {
                arrayListe.push([displayGMr(element.id_materiel), displayConsommation(element.id_materiel), displayConsommationtheori(element.id_materiel)])
            })
            return arrayListe        
        }

        const options1 = {
            title: "Consomation totale du carburant par type d'engin"
        }       

        const datamoyen = ()  => {
            let  arrayListe = [["Engin", "Conso. réelle", "Conso. théorique"]]
            rapport_materielsItems().map(element => {
                arrayListe.push([displayGMr(element.id_materiel), displayConsommationMoyenn(element.id_materiel), displayConsommationMoyenntheori(element.id_materiel)])
            })
            return arrayListe 
        }       

        const options2 = { 
            title: "Consommation moyenne du carburant par type d'engin" 
        }        

        const datatotal = () => {            
            let constGas = displayTypeConsommation("Gas-oil")
            let constEss = displayTypeConsommation("Essence")

            let r = [
                ["Element", "", { role: "style" }],
                ["Gas-oil", constGas, "#4584f6"], // RGB value
                ["Essence", constEss, "#4584f6"]
            ] 
            
            return r
    
        } 

        const options3 = { title: "Consommation total carburant (litre)" }

        const rrr = (id_rapport) => {
            const a = rapports.filter(c => c["id"] === id_rapport)
            if(a[0] !== undefined){
                return   displayCreation(a[0].fait)
            }else{
                return 0
            }
        }        

        const columnEngin = [
            { 
                field: 'fait', 
                headerName: 'Date',
                flex: 1/12,
                renderCell: (cellValues) => {return  rrr(cellValues.row.id_rapport)}
            },
            { 
                field: 'heures', 
                headerName: "H. utili.",
                flex: 1/12,
                type: 'number',
                renderCell: (cellValues) => {return  (cellValues.row.hj).toLocaleString()}
            },
            { 
                field: 'arret', 
                headerName: "H. de panne",
                flex: 1/15,
                type: 'number',
                renderCell: (cellValues) => {return  cellValues.row.arret.toLocaleString()}
            }, 
            { 
                field: 'compteurdepart', 
                headerName: "Compt. au départ(KM)",
                flex: 1/9,
                type: 'number',
                renderCell: (cellValues) => {return  cellValues.row.compteurdepart.toLocaleString()}
            }, 
            { 
                field: 'compteurarrive', 
                headerName: "Compt. a l'arrivé(KM)",
                flex: 1/9,
                type: 'number',
                renderCell: (cellValues) => {return  cellValues.row.compteurarrive.toLocaleString()}
            }, 
            { 
                field: 'nbvoyage', 
                headerName: "Nb Voyages",
                flex: 1/9,
                type: 'number',
                renderCell: (cellValues) => {return  cellValues.row.nbvoyage.toLocaleString()}
            }, 
            { 
                field: 'carburantl', 
                headerName: "Car. (l)",
                flex: 1/15,
                type: 'number',
                renderCell: (cellValues) => {return  (cellValues.row.carburant).toLocaleString()}
            },
            { 
                field: 'consommatio', 
                headerName: "Conso. réelle(h)",
                flex: 1/9,
                type: 'number',
                renderCell: (cellValues) => {return  (cellValues.row.carburant/cellValues.row.hj).toLocaleString()}
            },
            { 
                field: 'consommation', 
                headerName: "Conso. théo. (h)",
                flex: 1/9,
                type: 'number',
                renderCell: (cellValues) => {return  (ctheor).toLocaleString()}
            },
            { 
                field: 'Coût de location', 
                headerName: "Location",
                flex: 1/12,
                type: 'number',
                renderCell: (cellValues) => {return  (cellValues.row.hj * taux).toLocaleString()}
            },
            { 
                field: 'Coût du carburant', 
                headerName: "Car.",
                flex: 1/12,
                type: 'number',
                renderCell: (cellValues) => {return  (cellValues.row.carburant * cellValues.row.prix).toLocaleString()}
            }            
        ]

        const columnMateriel = [
            { 
                field: 'gm', 
                headerName: 'Machine',
                flex: 1/11,
                renderCell: (cellValues) => {return  cellValues.row.gm }
            },
            { 
                field: 'type', 
                headerName: 'Modèle',
                flex: 1/11,
                renderCell: (cellValues) => {return  cellValues.row.type }
            },
            { 
                field: 'chassis', 
                headerName: 'N° Chassis',
                flex: 1/11,
                renderCell: (cellValues) => {return   cellValues.row.chassis  }
            },
            { 
                field: 'owner', 
                headerName: 'Propriétaire',
                flex: 1/11,
                renderCell: (cellValues) => {return  cellValues.row.owner }
            },
            { 
                field: 'taux', 
                headerName: 'Taux',
                type: 'number',
                flex: 1/16,
                renderCell: (cellValues) => {return  (cellValues.row.taux).toLocaleString() }
            },
            { 
                field: 'hj', 
                headerName: "H. utilis.",
                type: 'number',
                flex: 1/16,
                renderCell: (cellValues) => {return  displayH(cellValues.row.id).toLocaleString() }
            },
            { 
                field: 'jour', 
                headerName: "Jour",
                type: 'number',
                flex: 1/16,
                renderCell: (cellValues) => {return  displayJour(cellValues.row.id).toLocaleString() }
            },
            { 
                field: 'car', 
                headerName: "Car.(l)",
                type: 'number',
                flex: 1/16,
                renderCell: (cellValues) => {return  displayCarbH(cellValues.row.id).toLocaleString() }
            },
            { 
                field: 'carb', 
                headerName: "Conso. réelle (h)",
                type: 'number',
                flex: 1/11,
                renderCell: (cellValues) => {return  (displayCarbH(cellValues.row.id)/displayH(cellValues.row.id)).toLocaleString() }
            },
            { 
                field: 'ctheorique', 
                headerName: "Conso. théo. (h)",
                type: 'number',
                flex: 1/11,
                renderCell: (cellValues) => {return  (cellValues.row.ctheorique).toLocaleString() }
            },
            { 
                field: 'LocationGM', 
                headerName: "Location",
                type: 'number',
                flex: 1/11,
                renderCell: (cellValues) => {return  (cellValues.row.taux * displayH(cellValues.row.id)).toLocaleString() }
            },
            { 
                field: 'carburan', 
                headerName: "Mt. Car.",
                type: 'number',
                flex: 1/11,
                renderCell: (cellValues) => {return (displayPrix(cellValues.row.id) * displayCarbH(cellValues.row.id)).toLocaleString() }
            },
        ]  

        const displayH =  (id_materiel) => {
            let resultat = []
            let item = []
            let items = 0   
            resultat = rapport_materiels.filter(c => c["id_materiel"] === id_materiel)
            resultat.map((answer) => {
                const c = rapport.filter(c => c["id"] === answer.id_rapport)
                if(rapport.filter(c => c["id"] === answer.id_rapport)[0] !== undefined){
                    item = item.concat(answer)
                }               
            })

            item.map((answer) => {                
                items = items + answer.hj 
            })
            return items
        }

        const displayJour =  (id_materiel) => {
            let resultat = []
            let item = []
            let items = 0      
            resultat = rapport_materiels.filter(c => c["id_materiel"] === id_materiel)

            resultat.map((answer) => {
                const c = rapport.filter(c => c["id"] === answer.id_rapport)
                c.map((sss) => {
                    item = item.concat(sss)
                })       
            })
            let pp = item.filter( (ele, ind) => ind === item.findIndex(elem => elem.id === ele.id))
            pp = pp.filter( (ele, ind) => ind === pp.findIndex(elem => elem.fait === ele.fait))

            console.log("rrr", pp)
            return pp.length 
        }

        const displayCarbH =  (id_materiel) => {
            let resultat = []
            let item = []
            let items = 0            
            resultat = rapport_materiels.filter(c => c["id_materiel"] === id_materiel)
            resultat.map((answer) => {
                const c = rapport.filter(c => c["id"] === answer.id_rapport)
                if(c.length > 0){
                    item = item.concat(answer)
                }
            })
            item.map((answer) => {                
                items = items + answer.carburant
            })
            return items
        }

        const TotalMPUsure =  (id_materiel) => {
            let resultat = []
            let item = []
            let items = 0            
            resultat = rapport_materiels.filter(c => c["id_materiel"] === id_materiel)
            resultat = resultat.filter(c => c["fait"] >= Math.floor(new Date(start).getTime() / 1000))
            resultat = resultat.filter(c => c["fait"] <= Math.floor(new Date(end).getTime() / 1000) + 86400)
            resultat.map((answer) => {
                const c = rapport.filter(c => c["id"] === answer.id_rapport)
                if(c.length > 0){
                    item = item.concat(answer)
                }
            })
            item.map((answer) => {                
                items = items + answer.pusure
            })
            return items
        }

        const displayPrix =  (id_materiel) => {
            let items = 0            
            let resultat = rapport_materiels.filter(c => c["id_materiel"] === id_materiel)
            if(resultat.length > 0){
                items = resultat[0].prix
            }
            return items
        }

        const displayTLocation  =  () => {
            let resultat = 0
            materiels.map((answer) => {
                resultat = resultat + (answer.taux * displayH(answer.id))
            })
            return resultat
        }

        const displayTpu =  () => {
            let resultat = 0
            materiels.map((answer) => {
                resultat = resultat + TotalMPUsure(answer.id)
            })
            return resultat
        }

        const displayTCarburant =  () => {
            let resultat = 0
            materiels.map((answer) => {
                resultat = resultat + (displayPrix(answer.id) * displayCarbH(answer.id))
            })
            return resultat
        }     
        
        const displayTotalpersonnel = () => {
            let resultat
            let a = 0
            let b = 0
            rapport_salaireItems().map((answer) => {
                a = a + (displayTotalmois().toFixed(2) * answer.taux)
            })

            rapport_personnelItems().map((answer) => {
                b = b + (displayTotalH(answer.id_personnel) * (answer.taux))
            })

            resultat = a + b
            return resultat
        }     

        const displayTotalcaisse =  () => {
            let resultat = 0
            rapport_caisseItems().map((answer) => {
                resultat = resultat + answer.somme
            })
            return resultat
        }  

        const displayTotalcaisseentre =  () => {
            let resultat = 0
            caissetrie.map((answer) => {
                resultat = resultat + answer.montant
            })
            return resultat
        }  

        const displayMontantTotalFournitures =  () => {
            let resultat = 0
            rapport_materiauxItems().map((answer) => {
                resultat = resultat + (answer.taux * displayTotalquantite(answer.id_fourniture))
            })
            return resultat
        } 

        const displayMontantTotalPieces =  () => {
            let resultat = 0
            rapport_pieceItems().map((answer) => {
                resultat = resultat + (answer.taux * displayTotalquantitePiece(answer.id_pieceusure))
            })
            return resultat
        } 

        const displayMontantTotalFourniture =  () => {
            let resultat = 0
            rapport_materiauxItem().map((answer) => {
                resultat = resultat + (answer.taux * answer.quantite)
            })
            return resultat
        }   

        const displayMontantTotalPiece =  () => {
            let resultat = 0
            rapport_pieceItem().map((answer) => {
                resultat = resultat + (answer.taux * answer.quantite)
            })
            return resultat
        }   
        
        const displayMontantTotalsoustraitant =  () => {
            let resultat = 0
            rapport_traitantItems().map((answer) => {
                resultat = resultat + (answer.taux * displayTotalquantitetraitant(answer.id_traitant))
            })
            return resultat
        }

        const displayTotaldepenses =  () => {
            let resultat = 0
            rapport_depenseItems().map((answer) => {
                resultat = resultat + answer.montant 
            })
            return resultat
        }

        const fournitureRows = () => {
            let items = allapprovisionnements.filter(c => c["id_projet"] === id_project)
            let item = items.filter(c => c["id_fourniture"] === id_fourniture)
            let ite = item.filter(c => c["start"] >= Math.floor(new Date(start).getTime() / 1000))
            let it = ite.filter(c => c["start"] <= Math.floor(new Date(end).getTime() / 1000) + 86400)
            return it
        }

        const pieceRows = () => {
            let items = appropieces.filter(c => c["id_projet"] === id_project)
            let item = items.filter(c => c["id_pusure"] === id_pieceusure)
            let ite = item.filter(c => c["start"] >= Math.floor(new Date(start).getTime() / 1000))
            let it = ite.filter(c => c["start"] <= Math.floor(new Date(end).getTime() / 1000) + 86400)
            return it
        }

        const fournitureColumns = [
            { 
                field: 'code', 
                headerName: "Code",
                flex: 1/10,
                renderCell: (cellValues) => {return  displayCode(cellValues.row.id_fourniture) }
            },
            { 
                field: 'designation', 
                headerName: "Désignation",
                flex: 1/10,
                renderCell: (cellValues) => {return   displayDesignationmateriaux(cellValues.row.id_fourniture) }
            },
            { 
                field: 'unite', 
                headerName: 'Unité',
                flex: 1/10,
                renderCell: (cellValues) => {return  displayUnitemateriaux(cellValues.row.id_fourniture)}
            },
            { 
                field: 'approvisionnement', 
                headerName: "Date d'approvisionnement",
                flex: 1/10,
                renderCell: (cellValues) => {return  displayCreation(cellValues.row.start) }
            },
            { 
                field: 'quantite', 
                headerName: 'Quantité initiale',
                flex: 1/10,
                renderCell: (cellValues) => {return  cellValues.row.quantite.toLocaleString() }
            },
            { 
                field: 'restant',
                headerName: 'Quantité restante',
                flex: 1/10,
                renderCell: (cellValues) => {return  cellValues.row.restant .toLocaleString() }
            },
            { 
                field: 'prix', 
                headerName: 'Prix unitaire',
                flex: 1/10,
                renderCell: (cellValues) => {return  cellValues.row.prix.toLocaleString() }
            },
            { 
                field: 'montant', 
                headerName: 'Montant',
                flex: 1/10,
                renderCell: (cellValues) => {return  (cellValues.row.prix * cellValues.row.quantite).toLocaleString() }
            }
        ]

        const pieceColumns = [           
            { 
                field: 'designation', 
                headerName: "Désignation",
                flex: 1/10,
                renderCell: (cellValues) => {return   displayDesignationPieces(cellValues.row.id_pusure) }
            },
            { 
                field: 'approvisionnement', 
                headerName: "Date d'approvisionnement",
                flex: 1/10,
                renderCell: (cellValues) => {return  displayCreation(cellValues.row.start) }
            },
            { 
                field: 'quantite', 
                headerName: 'Quantité initiale',
                flex: 1/10,
                renderCell: (cellValues) => {return  cellValues.row.quantite.toLocaleString() }
            },
            { 
                field: 'restant',
                headerName: 'Quantité restante',
                flex: 1/10,
                renderCell: (cellValues) => {return  cellValues.row.restant .toLocaleString() }
            },
            { 
                field: 'prix', 
                headerName: 'Prix unitaire',
                flex: 1/10,
                renderCell: (cellValues) => {return  cellValues.row.prix.toLocaleString() }
            },
            { 
                field: 'montant', 
                headerName: 'Montant',
                flex: 1/10,
                renderCell: (cellValues) => {return  (cellValues.row.prix * cellValues.row.quantite).toLocaleString() }
            }
        ]

        return (
            <React.Fragment>
                <Grid sx={{ flexGrow: 1, mb:2 }} container spacing={6} >
                    <Grid item xs={12} sm={6}  md={5}>
                        <Typography sx={{color:'#46505b', fontSize:28 }}>{pays}, {localite}</Typography>

                        <Typography 
                            component="h1" 
                            variant="h1" 
                            sx={{color:"black", fontWeight: 400, fontSize:24}}
                        >
                            {name.substring(0, 45)}
                        </Typography>                                              
                    </Grid>  

                    <Grid item xs={12} sm={6} md={2}>
                        <ProjetMenu projetId={id_project} onChange={this.idchange} />
                    </Grid>

                    <Grid item xs={12} sm={6} md={3}>
                        <ProjetPeriode startDate={start}  endDate={end} onChange={this.Datechange}/>
                    </Grid>

                    <Grid item xs={12} sm={6} md={2}>
                        <Card elevation={1}  sx={{height:70, p:2, backgroundColor:'#ffffff', borderRadius:3, display: 'flex', alignItems: 'center',}}>
                            <Box sx={{display: 'flex', flexDirection:'column', alignItems: 'left', }} >
                                <Typography sx={{color:'#46505b', pl:1}}>{optionFilter}</Typography>
                            </Box>

                            <Box sx={{ flexGrow: 1 }} />

                            <IconButton onClick={this.handleClick} >
                                <ExpandMoreIcon />
                            </IconButton>

                            <Menu
                                elevation={12}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={() => this.setState({anchorEl: null}) }
                                PaperProps={{
                                    sx: {
                                        //width: 300,
                                        //backgroundColor:'gray',                                    
                                    }
                                }}
                            >
                                <MenuItem 
                                    disableRipple 
                                    onClick={() => this.setState({optionFilter: "Prix et rendements moyens", anchorEl: null })} 
                                    sx={optionFilter === "Prix et rendements moyens" ? {color:'#2f4cdd'} : null}
                                >
                                    Prix et rendements moyens
                                </MenuItem>

                                <MenuItem 
                                    disableRipple 
                                    onClick={() => this.setState({optionFilter: "Personnel d'exécution", anchorEl: null })} 
                                    sx={optionFilter === "Personnel d'exécution" ? {color:'#2f4cdd'} : null}
                                >
                                    Personnel d'exécution
                                </MenuItem>

                                <MenuItem 
                                    disableRipple 
                                    onClick={() => this.setState({optionFilter: "Matériaux", anchorEl: null })} 
                                    sx={optionFilter === "Matériaux" ? {color:'#2f4cdd'} : null}
                                >
                                    Matériaux
                                </MenuItem>                                                               

                                <MenuItem 
                                    disableRipple 
                                    onClick={() => this.setState({optionFilter: "Sous-traitants", anchorEl: null })} 
                                    sx={optionFilter === "Sous-traitants" ? {color:'#2f4cdd'} : null}
                                >
                                    Sous-traitants
                                </MenuItem>

                                <MenuItem 
                                    disableRipple 
                                    onClick={() => this.setState({optionFilter: "Caisse", anchorEl: null })} 
                                    sx={optionFilter === "Caisse" ? {color:'#2f4cdd'} : null}
                                >
                                    Caisse
                                </MenuItem>

                                <MenuItem 
                                    disableRipple 
                                    onClick={() => this.setState({optionFilter: "Matériels", anchorEl: null })} 
                                    sx={optionFilter === "Matériels" ? {color:'#2f4cdd'} : null}
                                >
                                    Matériels
                                </MenuItem>

                                <MenuItem 
                                    disableRipple 
                                    onClick={() => this.setState({optionFilter: "Pièce d'usure", anchorEl: null })} 
                                    sx={optionFilter === "Pièce d'usure" ? {color:'#2f4cdd'} : null}
                                >
                                    Pièce d'usure
                                </MenuItem> 

                                <MenuItem 
                                    disableRipple 
                                    onClick={() => this.setState({optionFilter: "Dépenses mensuelles", anchorEl: null })} 
                                    sx={optionFilter === "Dépenses mensuelles" ? {color:'#2f4cdd'} : null}
                                >
                                    Dépenses mensuelles
                                </MenuItem>

                                <MenuItem 
                                    disableRipple 
                                    onClick={() => this.setState({optionFilter: "Conso. Totale/type d'engin", anchorEl: null })} 
                                    sx={optionFilter === "Conso. Totale/type d'engin" ? {color:'#2f4cdd'} : null}
                                >
                                    Conso. Totale/type d'engin
                                </MenuItem>

                                <MenuItem 
                                    disableRipple 
                                    onClick={() => this.setState({optionFilter: "Conso. moyenne/type d'engin", anchorEl: null })} 
                                    sx={optionFilter === "Conso. moyenne/type d'engin" ? {color:'#2f4cdd'} : null}
                                >
                                    Conso. moyenne/type d'engin
                                </MenuItem>

                                <MenuItem 
                                    disableRipple 
                                    onClick={() => this.setState({optionFilter: "Conso. totale carburant", anchorEl: null })} 
                                    sx={optionFilter === "Conso. totale carburant" ? {color:'#2f4cdd'} : null}
                                >
                                    Conso. totale carburant
                                </MenuItem>

                                <MenuItem 
                                    disableRipple 
                                    onClick={() => this.setState({optionFilter: "Conso. pointe carburant", anchorEl: null })} 
                                    sx={optionFilter === "Conso. pointe carburant" ? {color:'#2f4cdd'} : null}
                                >
                                    Conso. pointe carburant
                                </MenuItem>

                                <MenuItem 
                                    disableRipple 
                                    onClick={() => this.setState({optionFilter: "Répartition des dépenses", anchorEl: null })} 
                                    sx={optionFilter === "Répartition des dépenses" ? {color:'#2f4cdd'} : null}
                                >
                                    Répartition des dépenses
                                </MenuItem>
                            </Menu>
                        </Card>                        
                    </Grid>

                    {optionFilter === "Prix et rendements moyens" &&
                        <Grid item xs={12} sm={12} md={12} sx={{mt:'-20px'}}>
                            <div className="card" style={{ height: 470, width: '100%',borderRadius:16 }}>
                                <DataGrid
                                    rows={rapport}
                                    columns={columnsrapport}
                                    elevation={10}
                                    sx={{
                                        boxShadow: 2,
                                        borderRadius:4,
                                        '.MuiDataGrid-columnSeparator': {
                                            display: 'none',
                                            },
                                            '&.MuiDataGrid-root': {
                                            border: 'none',
                                            },
                                        '.css-1jbbcbn-MuiDataGrid-columnHeaderTitle': {
                                            color:'black',
                                            fontWeight: 600,
                                            fontSize:18
                                        }
                                    }}                                    
                                />
                            </div>
                        </Grid>
                    }

                    {optionFilter === "Personnel d'exécution" &&
                        <React.Fragment>
                            <Grid item xs={12} sm={12} md={12}>
                                <Typography color="inherit" sx={{color:"black", fontSize:20}}>personnels permanents</Typography>
                            </Grid>

                            <Grid item xs={12} sm={12} md={12} sx={{mt:'-20px'}}>
                                <div className="card" style={{ height: 470, width: '100%',borderRadius:16 }}>
                                    <DataGrid
                                        rows={rapport_salaireItems()}
                                        columns={columnsSalaireItems}
                                        elevation={10}
                                        sx={{
                                            boxShadow: 2,
                                            borderRadius:4,
                                            '.MuiDataGrid-columnSeparator': {
                                                display: 'none',
                                                },
                                                '&.MuiDataGrid-root': {
                                                border: 'none',
                                                },
                                            '.css-1jbbcbn-MuiDataGrid-columnHeaderTitle': {
                                                color:'black',
                                                fontWeight: 600,
                                                fontSize:18
                                            }
                                        }}                                    
                                    />
                                </div>
                            </Grid>

                            <Grid item xs={12} sm={12} md={12}>
                                <Typography color="inherit" sx={{color:"black", fontSize:20}}>personnels journaliers</Typography>
                            </Grid>

                            <Grid item xs={12} sm={12} md={12} sx={{mt:'-20px'}}>
                                <div className="card" style={{ height: 470, width: '100%',borderRadius:16 }}>
                                    <DataGrid
                                        rows={rapport_personnelItems()}
                                        columns={columnspersonnelItems}
                                        elevation={10}
                                        sx={{
                                            boxShadow: 2,
                                            borderRadius:4,
                                            '.MuiDataGrid-columnSeparator': {
                                                display: 'none',
                                                },
                                                '&.MuiDataGrid-root': {
                                                border: 'none',
                                                },
                                            '.css-1jbbcbn-MuiDataGrid-columnHeaderTitle': {
                                                color:'black',
                                                fontWeight: 600,
                                                fontSize:18
                                            }
                                        }}                                    
                                    />
                                </div>
                            </Grid> 

                            <Grid item xs={12} sm={12} md={12*6/7} />           

                            <Grid item xs={12} sm={12} md={12/7} > 
                                <TextField
                                    id="outlined-required"
                                    label="Montant Total"
                                    variant="outlined"
                                    style={{ width: '100%' }}
                                    value={displayTotalpersonnel().toLocaleString()}
                                    sx={{bgcolor:'#ffffff'}}
                                />
                            </Grid>                   
                        </React.Fragment>
                    }

                    {optionFilter === "Matériaux" &&
                        <React.Fragment>
                            <Grid item xs={12} sm={6} md={5} sx={{mt:'-20px'}} />

                            {materiauxFilter === "Toutes les fournitures" && 
                                <Grid item xs={12} sm={6} md={5} sx={{mt:'-20px'}} />
                            }  

                            {materiauxFilter === "Une fourniture" && 
                                <React.Fragment>
                                    <Grid item xs={12} sm={6} md={2} sx={{mt:'-20px'}} >
                                        <FormControl  style={{ width: '100%'}}>
                                            <div className="card" style={{ width: '100%'}} >
                                                <InputLabel id="demo-simple-select-helper-label">Code</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-helper-label"
                                                    id="demo-simple-select-helper"
                                                    value={id_fourniture || ''}
                                                    style={{ width: '100%' }}
                                                    onChange={this.handleChange}
                                                    name="id_fourniture"
                                                    sx={{bgcolor:'#ffffff'}}
                                                >        
                                                    {fournitures.map((answer) => { 
                                                        return  <MenuItem value={answer.id}>{answer.code}</MenuItem>
                                                    })} 
                                                </Select>
                                            </div>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={6} md={3} sx={{mt:'-20px'}} >
                                        <FormControl  style={{ width: '100%'}}>
                                            <div className="card" style={{ width: '100%'}} >
                                                <InputLabel id="demo-simple-select-helper-label">Désignation</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-helper-label"
                                                    id="demo-simple-select-helper"
                                                    value={id_fourniture || ''}
                                                    style={{ width: '100%' }}
                                                    onChange={this.handleChange}
                                                    name="id_fourniture"
                                                    sx={{bgcolor:'#ffffff'}}
                                                >        
                                                    {fournitures.map((answer) => { 
                                                        return  <MenuItem value={answer.id}>{answer.designation}</MenuItem>
                                                    })} 
                                                </Select>
                                            </div>
                                        </FormControl>
                                    </Grid>
                                </React.Fragment>
                            }                          

                            <Grid item xs={12} sm={6} md={2} sx={{mt:'-20px'}}>
                                <Button
                                    variant="contained"
                                    sx={{bgcolor:'#2f4cdd', height:50, textTransform: 'none', borderRadius:2 }} 
                                    fullWidth
                                    onClick={this.handleClick2}
                                    endIcon={<KeyboardArrowDownIcon />}
                                >
                                    {materiauxFilter}
                                </Button>

                                <Menu
                                    elevation={12}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'right',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    anchorEl={anchorEl2}
                                    open={Boolean(anchorEl2)}
                                    onClose={() => this.setState({anchorEl2: null}) }
                                    PaperProps={{
                                        sx: {
                                            //width: 300,
                                            //backgroundColor:'gray',                                    
                                        }
                                    }}
                                >
                                    <MenuItem 
                                        disableRipple 
                                        onClick={() => this.setState({materiauxFilter: "Toutes les fournitures", anchorEl2: null })} 
                                        sx={materiauxFilter === "Toutes les fournitures" ? {color:'#2f4cdd'} : null}
                                    >
                                        Toutes les fournitures
                                    </MenuItem>

                                    <MenuItem 
                                        disableRipple 
                                        onClick={() => this.setState({materiauxFilter: "Une fourniture", anchorEl2: null })} 
                                        sx={materiauxFilter === "Une fourniture" ? {color:'#2f4cdd'} : null}
                                    >
                                        Une fourniture
                                    </MenuItem>
                                    
                                </Menu>
                    
                            </Grid>

                            {materiauxFilter === "Toutes les fournitures" && 
                                <React.Fragment>
                                    <Grid item xs={12} sm={12} md={12} sx={{mt:'-20px'}}>
                                        <div className="card" style={{ height: 470, width: '100%',borderRadius:16 }}>
                                            <DataGrid
                                                rows={rapport_materiauxItems()}
                                                columns={columnsmateriauxItems}
                                                elevation={10}
                                                sx={{
                                                    boxShadow: 2,
                                                    borderRadius:4,
                                                    '.MuiDataGrid-columnSeparator': {
                                                        display: 'none',
                                                        },
                                                        '&.MuiDataGrid-root': {
                                                        border: 'none',
                                                        },
                                                    '.css-1jbbcbn-MuiDataGrid-columnHeaderTitle': {
                                                        color:'black',
                                                        fontWeight: 600,
                                                        fontSize:18
                                                    }
                                                }}                                    
                                            />
                                        </div>
                                    </Grid> 

                                    <Grid item xs={12} sm={12} md={12*5/6} />           

                                    <Grid item xs={12} sm={12} md={12/6} > 
                                        <TextField
                                            id="outlined-required"
                                            label="Montant Total"
                                            variant="outlined"
                                            style={{ width: '100%' }}
                                            value={displayMontantTotalFournitures().toLocaleString()}
                                            sx={{bgcolor:'#ffffff'}}
                                        />
                                    </Grid>            
                                </React.Fragment>
                            }

                            {materiauxFilter === "Une fourniture" && 
                                <React.Fragment>
                                    <Grid item xs={12} sm={12} md={12}>
                                        <Typography color="inherit" sx={{color:"black", fontSize:20}}>Approvisionnements</Typography>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12} sx={{mt:'-20px'}}>
                                        <div className="card" style={{ height: 470, width: '100%',borderRadius:16 }}>
                                            <DataGrid
                                                rows={fournitureRows()}
                                                columns={fournitureColumns}
                                                elevation={10}
                                                sx={{
                                                    boxShadow: 2,
                                                    borderRadius:4,
                                                    '.MuiDataGrid-columnSeparator': {
                                                        display: 'none',
                                                        },
                                                        '&.MuiDataGrid-root': {
                                                        border: 'none',
                                                        },
                                                    '.css-1jbbcbn-MuiDataGrid-columnHeaderTitle': {
                                                        color:'black',
                                                        fontWeight: 600,
                                                        fontSize:18
                                                    }
                                                }}                                    
                                            />
                                        </div>
                                    </Grid> 

                                    <Grid item xs={12} sm={12} md={12}>
                                        <Typography color="inherit" sx={{color:"black", fontSize:20}}>Utilisations</Typography>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12} sx={{mt:'-20px'}}>
                                        <div className="card" style={{ height: 470, width: '100%',borderRadius:16 }}>
                                            <DataGrid
                                                rows={rapport_materiauxItem()}
                                                columns={columnsmateriauxItem}
                                                elevation={10}
                                                sx={{
                                                    boxShadow: 2,
                                                    borderRadius:4,
                                                    '.MuiDataGrid-columnSeparator': {
                                                        display: 'none',
                                                        },
                                                        '&.MuiDataGrid-root': {
                                                        border: 'none',
                                                        },
                                                    '.css-1jbbcbn-MuiDataGrid-columnHeaderTitle': {
                                                        color:'black',
                                                        fontWeight: 600,
                                                        fontSize:18
                                                    }
                                                }}                                    
                                            />
                                        </div>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12*5/6} />           

                                    <Grid item xs={12} sm={12} md={12/6} > 
                                        <TextField
                                            id="outlined-required"
                                            label="Montant Total"
                                            variant="outlined"
                                            style={{ width: '100%' }}
                                            value={displayMontantTotalFourniture().toLocaleString()}
                                            sx={{bgcolor:'#ffffff'}}
                                        />
                                    </Grid> 
                                </React.Fragment>
                            }
                        </React.Fragment>                    
                    }                    

                    {optionFilter === "Sous-traitants" &&
                        <React.Fragment>
                            <Grid item xs={12} sm={12} md={12} sx={{mt:'-20px'}}>
                                <div className="card" style={{ height: 470, width: '100%',borderRadius:16 }}>
                                    <DataGrid
                                        rows={rapport_traitantItems()}
                                        columns={columnsTraitantItems}
                                        elevation={10}
                                        sx={{
                                            boxShadow: 2,
                                            borderRadius:4,
                                            '.MuiDataGrid-columnSeparator': {
                                                display: 'none',
                                                },
                                                '&.MuiDataGrid-root': {
                                                border: 'none',
                                                },
                                            '.css-1jbbcbn-MuiDataGrid-columnHeaderTitle': {
                                                color:'black',
                                                fontWeight: 600,
                                                fontSize:18
                                            }
                                        }}                                    
                                    />
                                </div>
                            </Grid>

                            <Grid item xs={12} sm={12} md={12*5/6} />           

                            <Grid item xs={12} sm={12} md={12/6} > 
                                <TextField
                                    id="outlined-required"
                                    label="Montant Total"
                                    variant="outlined"
                                    style={{ width: '100%' }}
                                    value={displayMontantTotalsoustraitant().toLocaleString()}
                                    sx={{bgcolor:'#ffffff'}}
                                />
                            </Grid> 

                        </React.Fragment>
                    }

                    {optionFilter === "Caisse" &&
                        <React.Fragment>
                            <Grid item xs={12} sm={12} md={12} sx={{mt:'-20px'}}>
                                <div className="card" style={{ height: 470, width: '100%',borderRadius:16 }}>
                                    <DataGrid
                                        rows={caisseEntrantSort()}
                                        columns={conlumCaisse}
                                        elevation={10}
                                        sx={{
                                            boxShadow: 2,
                                            borderRadius:4,
                                            '.MuiDataGrid-columnSeparator': {
                                                display: 'none',
                                                },
                                                '&.MuiDataGrid-root': {
                                                border: 'none',
                                                },
                                            '.css-1jbbcbn-MuiDataGrid-columnHeaderTitle': {
                                                color:'black',
                                                fontWeight: 600,
                                                fontSize:18
                                            }
                                        }}                                    
                                    />
                                </div>
                            </Grid>

                            <Grid item xs={12} sm={12} md={12*4/7} />           

                            <Grid item xs={12} sm={12} md={12/7} > 
                                <div className="card" style={{width: '100%',borderRadius:16 }}>
                                    <TextField
                                        id="outlined-required"
                                        label="Totale des entrées"
                                        variant="outlined"
                                        style={{ width: '100%' }}
                                        value={displayTotalcaisseentre().toLocaleString()}
                                        sx={{bgcolor:'#ffffff'}}
                                    />
                                </div>
                            </Grid> 

                            <Grid item xs={12} sm={12} md={12/7} > 
                                <TextField
                                    id="outlined-required"
                                    label="Les dépenses totales"
                                    variant="outlined"
                                    style={{ width: '100%' }}
                                    value={displayTotalcaisse().toLocaleString()}
                                    sx={{bgcolor:'#ffffff'}}
                                />
                            </Grid> 

                            <Grid item xs={12} sm={12} md={12/7} > 
                                <TextField
                                    id="outlined-required"
                                    label="Solde"
                                    variant="outlined"
                                    style={{ width: '100%' }}
                                    value={( displayTotalcaisseentre() - displayTotalcaisse()).toLocaleString()}
                                    sx={{bgcolor:'#ffffff'}}
                                />
                            </Grid>
                        </React.Fragment>
                    }

                    {optionFilter === "Matériels" && 
                        <React.Fragment>
                            <Grid item xs={12} sm={6} md={5} sx={{mt:'-20px'}} />

                            {materielsFilter === "Tous les engins" && 
                                <Grid item xs={12} sm={6} md={5} sx={{mt:'-20px'}} />
                            } 

                            {materielsFilter === "Un engin" && 
                                <React.Fragment>
                                    <Grid item xs={12} sm={6} md={5/3} sx={{mt:'-20px'}} >
                                        <FormControl  style={{ width: '100%'}}>
                                            <div className="card" style={{ width: '100%'}} >
                                                <InputLabel id="demo-simple-select-helper-label">Machine</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-helper-label"
                                                    id="demo-simple-select-helper"
                                                    value={id_materiel || ''}
                                                    style={{ width: '100%' }}
                                                    onChange={this.handleChange}
                                                    name="id_materiel"
                                                    sx={{bgcolor:'#ffffff'}}
                                                >        
                                                    {materiel.map((answer) => { 
                                                        return  <MenuItem value={answer.id}>{answer.gm}</MenuItem>
                                                    })}     
                                                </Select>
                                            </div>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={6} md={5/3} sx={{mt:'-20px'}} >
                                        <FormControl  style={{ width: '100%'}}>
                                            <div className="card" style={{ width: '100%'}} >
                                                <InputLabel id="demo-simple-select-helper-label">Modèle</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-helper-label"
                                                    id="demo-simple-select-helper"
                                                    value={id_materiel || ''}
                                                    style={{ width: '100%' }}
                                                    onChange={this.handleChange}
                                                    name="id_materiel"
                                                    sx={{bgcolor:'#ffffff'}}
                                                >        
                                                    {materiel.map((answer) => { 
                                                        return  <MenuItem value={answer.id}>{answer.type}</MenuItem>
                                                    })}     
                                                </Select>
                                            </div>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={6} md={5/3} sx={{mt:'-20px'}} >
                                        <FormControl  style={{ width: '100%'}}>
                                            <div className="card" style={{ width: '100%'}} >
                                                <InputLabel id="demo-simple-select-helper-label">N° Chassis</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-helper-label"
                                                    id="demo-simple-select-helper"
                                                    value={id_materiel || ''}
                                                    style={{ width: '100%' }}
                                                    onChange={this.handleChange}
                                                    name="id_materiel"
                                                    sx={{bgcolor:'#ffffff'}}
                                                >        
                                                    {materiel.map((answer) => { 
                                                        return  <MenuItem value={answer.id}>{answer.chassis}</MenuItem>
                                                    })}     
                                                </Select>
                                            </div>
                                        </FormControl>
                                    </Grid>
                                </React.Fragment>
                            }                            

                            <Grid item xs={12} sm={6} md={2} sx={{mt:'-20px'}}>
                                <Button
                                    variant="contained"
                                    sx={{bgcolor:'#2f4cdd', height:50, textTransform: 'none', borderRadius:2 }} 
                                    fullWidth
                                    onClick={this.handleClick3}
                                    endIcon={<KeyboardArrowDownIcon />}
                                >
                                    {materielsFilter}
                                </Button>

                                <Menu
                                    elevation={12}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'right',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    anchorEl={anchorEl3}
                                    open={Boolean(anchorEl3)}
                                    onClose={() => this.setState({anchorEl3: null}) }
                                    PaperProps={{
                                        sx: {
                                            //width: 300,
                                            //backgroundColor:'gray',                                    
                                        }
                                    }}
                                >
                                    <MenuItem 
                                        disableRipple 
                                        onClick={() => this.setState({materielsFilter: "Tous les engins", anchorEl3: null })} 
                                        sx={materielsFilter === "Tous les engins" ? {color:'#2f4cdd'} : null}
                                    >
                                        Tous les engins
                                    </MenuItem>

                                    <MenuItem 
                                        disableRipple 
                                        onClick={() => this.setState({materielsFilter: "Un engin", anchorEl3: null })} 
                                        sx={materielsFilter === "Un engin" ? {color:'#2f4cdd'} : null}
                                    >
                                        Un engin
                                    </MenuItem>
                                    
                                </Menu>                    
                            </Grid>
                            
                            {materielsFilter === "Tous les engins" && 
                                <React.Fragment>
                                    <Grid item xs={12} sm={12} md={12} sx={{mt:'-20px'}}>
                                        <div className="card" style={{ height: 470, width: '100%',borderRadius:16 }}>
                                            <DataGrid
                                                rows={materiels}
                                                columns={columnMateriel}
                                                elevation={10}
                                                sx={{
                                                    boxShadow: 2,
                                                    borderRadius:4,
                                                    '.MuiDataGrid-columnSeparator': {
                                                        display: 'none',
                                                        },
                                                        '&.MuiDataGrid-root': {
                                                        border: 'none',
                                                        },
                                                    '.css-1jbbcbn-MuiDataGrid-columnHeaderTitle': {
                                                        color:'black',
                                                        fontWeight: 600,
                                                        fontSize:18
                                                    }
                                                }}                                    
                                            />
                                        </div>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12*5/7} />

                                    <Grid item xs={12} sm={12} md={12/7} > 
                                        <div className="card" style={{width: '100%',borderRadius:16 }}>
                                            <TextField
                                                id="outlined-required"
                                                label="Coût de Location"
                                                variant="outlined"
                                                style={{ width: '100%' }}
                                                value={displayTLocation().toLocaleString()}
                                                sx={{bgcolor:'#ffffff'}}
                                            />
                                        </div>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12/7} > 
                                        <div className="card" style={{width: '100%',borderRadius:16 }}>
                                            <TextField
                                                id="outlined-required"
                                                label="Coût du carburant"
                                                variant="outlined"
                                                style={{ width: '100%' }}
                                                value={displayTCarburant().toLocaleString()}
                                                sx={{bgcolor:'#ffffff'}}
                                            />
                                        </div>
                                    </Grid>
                                </React.Fragment>
                            } 

                            {materielsFilter === "Un engin" && 
                                <React.Fragment>
                                    <Grid item xs={12} sm={12} md={12} sx={{mt:'-20px'}}>
                                        <div className="card" style={{ height: 470, width: '100%',borderRadius:16 }}>
                                            <DataGrid
                                                rows={list_un_materiel()}
                                                columns={columnEngin}
                                                elevation={10}
                                                sx={{
                                                    boxShadow: 2,
                                                    borderRadius:4,
                                                    '.MuiDataGrid-columnSeparator': {
                                                        display: 'none',
                                                        },
                                                        '&.MuiDataGrid-root': {
                                                        border: 'none',
                                                        },
                                                    '.css-1jbbcbn-MuiDataGrid-columnHeaderTitle': {
                                                        color:'black',
                                                        fontWeight: 600,
                                                        fontSize:18
                                                    }
                                                }}                                    
                                            />
                                        </div>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12*5/7} />

                                    <Grid item xs={12} sm={12} md={12/7} > 
                                        <div className="card" style={{width: '100%',borderRadius:16 }}>
                                            <TextField
                                                id="outlined-required"
                                                label="Coût de Location"
                                                variant="outlined"
                                                style={{ width: '100%' }}
                                                value={displayLocation().toLocaleString()}
                                                sx={{bgcolor:'#ffffff'}}
                                            />
                                        </div>
                                    </Grid> 

                                    <Grid item xs={12} sm={12} md={12/7} > 
                                        <div className="card" style={{width: '100%',borderRadius:16 }}>
                                            <TextField
                                                id="outlined-required"
                                                label="Coût du carburant"
                                                variant="outlined"
                                                style={{ width: '100%' }}
                                                value={displayCarburant().toLocaleString()}
                                                sx={{bgcolor:'#ffffff'}}
                                            />
                                        </div>
                                    </Grid>                                    
                                </React.Fragment>
                            } 

                        </React.Fragment>
                    }

                    {optionFilter === "Pièce d'usure" &&
                        <React.Fragment>
                            <Grid item xs={12} sm={6} md={5} sx={{mt:'-20px'}} />

                            {piecesFilter === "Toutes pièces d'usure" && 
                                <Grid item xs={12} sm={6} md={5} sx={{mt:'-20px'}} />
                            }  

                            {piecesFilter === "Une pièce d'usure" && 
                                <React.Fragment>
                                    <Grid item xs={12} sm={6} md={5} sx={{mt:'-20px'}} >
                                        <FormControl  style={{ width: '100%'}}>
                                            <div className="card" style={{ width: '100%'}} >
                                                <InputLabel id="demo-simple-select-helper-label">Désignation</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-helper-label"
                                                    id="demo-simple-select-helper"
                                                    value={id_pieceusure || ''}
                                                    style={{ width: '100%' }}
                                                    onChange={this.handleChange}
                                                    name="id_pieceusure"
                                                    sx={{bgcolor:'#ffffff'}}
                                                >        
                                                    {pieces.map((answer) => { 
                                                        return  <MenuItem value={answer.id}>{answer.designation}</MenuItem>
                                                    })} 
                                                </Select>
                                            </div>
                                        </FormControl>

                                    </Grid>
                                </React.Fragment>
                            }                          

                            <Grid item xs={12} sm={6} md={2} sx={{mt:'-20px'}}>
                                <Button
                                    variant="contained"
                                    sx={{bgcolor:'#2f4cdd', height:50, textTransform: 'none', borderRadius:2 }} 
                                    fullWidth
                                    onClick={this.handleClick2}
                                    endIcon={<KeyboardArrowDownIcon />}
                                >
                                    {piecesFilter}
                                </Button>

                                <Menu
                                    elevation={12}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'right',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    anchorEl={anchorEl2}
                                    open={Boolean(anchorEl2)}
                                    onClose={() => this.setState({anchorEl2: null}) }
                                    PaperProps={{
                                        sx: {
                                            //width: 300,
                                            //backgroundColor:'gray',                                    
                                        }
                                    }}
                                >
                                    <MenuItem 
                                        disableRipple 
                                        onClick={() => this.setState({piecesFilter: "Toutes pièces d'usure", anchorEl2: null })} 
                                        sx={piecesFilter === "Toutes pièces d'usure" ? {color:'#2f4cdd'} : null}
                                    >
                                        Toutes pièces d'usure
                                    </MenuItem>

                                    <MenuItem 
                                        disableRipple 
                                        onClick={() => this.setState({piecesFilter: "Une pièce d'usure", anchorEl2: null })} 
                                        sx={piecesFilter === "Une pièce d'usure" ? {color:'#2f4cdd'} : null}
                                    >
                                        Une pièce d'usure
                                    </MenuItem>                                    
                                </Menu>                    
                            </Grid>

                            {piecesFilter === "Toutes pièces d'usure" && 
                                <React.Fragment>
                                    <Grid item xs={12} sm={12} md={12} sx={{mt:'-20px'}}>
                                        <div className="card" style={{ height: 470, width: '100%',borderRadius:16 }}>
                                            <DataGrid
                                                rows={rapport_pieceItems()}
                                                columns={columnspiecesItems}
                                                elevation={10}
                                                sx={{
                                                    boxShadow: 2,
                                                    borderRadius:4,
                                                    '.MuiDataGrid-columnSeparator': {
                                                        display: 'none',
                                                        },
                                                        '&.MuiDataGrid-root': {
                                                        border: 'none',
                                                        },
                                                    '.css-1jbbcbn-MuiDataGrid-columnHeaderTitle': {
                                                        color:'black',
                                                        fontWeight: 600,
                                                        fontSize:18
                                                    }
                                                }}                                    
                                            />
                                        </div>
                                    </Grid> 

                                    <Grid item xs={12} sm={12} md={12*5/6} />           

                                    <Grid item xs={12} sm={12} md={12/6} > 
                                        <TextField
                                            id="outlined-required"
                                            label="Montant Total"
                                            variant="outlined"
                                            style={{ width: '100%' }}
                                            value={displayMontantTotalPieces().toLocaleString()}
                                            sx={{bgcolor:'#ffffff'}}
                                        />
                                    </Grid>            
                                </React.Fragment>
                            }

                            {piecesFilter === "Une pièce d'usure" && 
                                <React.Fragment>
                                    <Grid item xs={12} sm={12} md={12}>
                                        <Typography color="inherit" sx={{color:"black", fontSize:20}}>Approvisionnements</Typography>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12} sx={{mt:'-20px'}}>
                                        <div className="card" style={{ height: 470, width: '100%',borderRadius:16 }}>
                                            <DataGrid
                                                rows={pieceRows()}
                                                columns={pieceColumns}
                                                elevation={10}
                                                sx={{
                                                    boxShadow: 2,
                                                    borderRadius:4,
                                                    '.MuiDataGrid-columnSeparator': {
                                                        display: 'none',
                                                        },
                                                        '&.MuiDataGrid-root': {
                                                        border: 'none',
                                                        },
                                                    '.css-1jbbcbn-MuiDataGrid-columnHeaderTitle': {
                                                        color:'black',
                                                        fontWeight: 600,
                                                        fontSize:18
                                                    }
                                                }}                                    
                                            />
                                        </div>
                                    </Grid> 

                                    <Grid item xs={12} sm={12} md={12}>
                                        <Typography color="inherit" sx={{color:"black", fontSize:20}}>Utilisations</Typography>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12} sx={{mt:'-20px'}}>
                                        <div className="card" style={{ height: 470, width: '100%',borderRadius:16 }}>
                                            <DataGrid
                                                rows={rapport_pieceItem()}
                                                columns={columnspiecesItem}
                                                elevation={10}
                                                sx={{
                                                    boxShadow: 2,
                                                    borderRadius:4,
                                                    '.MuiDataGrid-columnSeparator': {
                                                        display: 'none',
                                                        },
                                                        '&.MuiDataGrid-root': {
                                                        border: 'none',
                                                        },
                                                    '.css-1jbbcbn-MuiDataGrid-columnHeaderTitle': {
                                                        color:'black',
                                                        fontWeight: 600,
                                                        fontSize:18
                                                    }
                                                }}                                    
                                            />
                                        </div>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12*5/6} />           

                                    <Grid item xs={12} sm={12} md={12/6} > 
                                        <TextField
                                            id="outlined-required"
                                            label="Montant Total"
                                            variant="outlined"
                                            style={{ width: '100%' }}
                                            value={displayMontantTotalPiece().toLocaleString()}
                                            sx={{bgcolor:'#ffffff'}}
                                        />
                                    </Grid>
                                </React.Fragment>
                            }
                        </React.Fragment>                      
                    }

                    {optionFilter === "Dépenses mensuelles" && 
                        <React.Fragment>
                            <Grid item xs={12} sm={12} md={12} sx={{mt:'-20px'}}>
                                <div className="card" style={{ height: 470, width: '100%',borderRadius:16 }}>
                                    <DataGrid
                                        rows={rapport_depenseItems()}
                                        columns={columnsDepenseItems}
                                        elevation={10}
                                        sx={{
                                            boxShadow: 2,
                                            borderRadius:4,
                                            '.MuiDataGrid-columnSeparator': {
                                                display: 'none',
                                                },
                                                '&.MuiDataGrid-root': {
                                                border: 'none',
                                                },
                                            '.css-1jbbcbn-MuiDataGrid-columnHeaderTitle': {
                                                color:'black',
                                                fontWeight: 600,
                                                fontSize:18
                                            }
                                        }}                                    
                                    />
                                </div>
                            </Grid>

                            <Grid item xs={12} sm={12} md={12*6/7} />           

                            <Grid item xs={12} sm={12} md={12/7} > 
                                <div className="card" style={{width: '100%',borderRadius:16 }}>
                                    <TextField
                                        id="outlined-required"
                                        label="Montant total"
                                        variant="outlined"
                                        style={{ width: '100%' }}
                                        value={displayTotaldepenses().toLocaleString()}
                                        sx={{bgcolor:'#ffffff'}}
                                    />
                                </div>
                            </Grid> 

                        </React.Fragment>                    
                    }

                    {optionFilter === "Conso. Totale/type d'engin" && 
                        <React.Fragment>
                            <Grid item xs={12} sm={12} md={12} sx={{mt:'-20px'}}>
                                <Card elevation={1}  sx={{p:4, backgroundColor:'#ffffff', borderRadius:3,}}>
                                    <Chart 
                                        chartType="ColumnChart" 
                                        width="100%" 
                                        height="400px"  
                                        data={datar()} 
                                        options={options1} 
                                    />
                                </Card>
                            </Grid>

                            <Grid item xs={12} sm={12} md={12} sx={{mt:'-20px'}}>
                                <div className="card" style={{ height: 470, width: '100%',borderRadius:16 }}>
                                    <DataGrid
                                        rows={rapport_materielsItems()}
                                        columns={columnsMaterielItems}
                                        elevation={10}
                                        sx={{
                                            boxShadow: 2,
                                            borderRadius:4,
                                            '.MuiDataGrid-columnSeparator': {
                                                display: 'none',
                                                },
                                                '&.MuiDataGrid-root': {
                                                border: 'none',
                                                },
                                            '.css-1jbbcbn-MuiDataGrid-columnHeaderTitle': {
                                                color:'black',
                                                fontWeight: 600,
                                                fontSize:18
                                            }
                                        }}                                    
                                    />
                                </div>
                            </Grid>
                        </React.Fragment>
                    }

                    {optionFilter === "Conso. moyenne/type d'engin" && 
                        <React.Fragment>
                            <Grid item xs={12} sm={12} md={12} sx={{mt:'-20px'}}>
                                <Card elevation={1}  sx={{p:4, backgroundColor:'#ffffff', borderRadius:3,}}>
                                    <Chart 
                                        chartType="ColumnChart" 
                                        width="100%" 
                                        height="400px" 
                                        data={datamoyen()} 
                                        options={options2} 
                                    />
                                </Card>
                            </Grid>

                            <Grid item xs={12} sm={12} md={12} sx={{mt:'-20px'}}>
                                <div className="card" style={{ height: 470, width: '100%',borderRadius:16 }}>
                                    <DataGrid
                                        rows={rapport_materielsItems()}
                                        columns={columnsMaterielItemsmoyenn}
                                        elevation={10}
                                        sx={{
                                            boxShadow: 2,
                                            borderRadius:4,
                                            '.MuiDataGrid-columnSeparator': {
                                                display: 'none',
                                                },
                                                '&.MuiDataGrid-root': {
                                                border: 'none',
                                                },
                                            '.css-1jbbcbn-MuiDataGrid-columnHeaderTitle': {
                                                color:'black',
                                                fontWeight: 600,
                                                fontSize:18
                                            }
                                        }}                                    
                                    />
                                </div>
                            </Grid>
                        </React.Fragment>
                    }

                    {optionFilter === "Conso. totale carburant" && 
                        <React.Fragment>
                            <Grid item xs={12} sm={12} md={12} sx={{mt:'-20px'}}>
                                <Card elevation={1}  sx={{p:4, backgroundColor:'#ffffff', borderRadius:3,}}>
                                    <Chart 
                                        chartType="ColumnChart" 
                                        width="100%" 
                                        height="400px"  
                                        data={datatotal()} 
                                        options={options3} 
                                    />
                                </Card>
                            </Grid>

                            <Grid item xs={12} sm={12} md={12} sx={{mt:'-20px'}}>
                                <div className="card" style={{ height: 470, width: '100%',borderRadius:16 }}>
                                    <DataGrid
                                        rows={[{id:1, type:"Gas-oil"}, {id:2, type:"Essence"}]}
                                        columns={columnsMaterielItemsmoyennccc}
                                        elevation={10}
                                        sx={{
                                            boxShadow: 2,
                                            borderRadius:4,
                                            '.MuiDataGrid-columnSeparator': {
                                                display: 'none',
                                                },
                                                '&.MuiDataGrid-root': {
                                                border: 'none',
                                                },
                                            '.css-1jbbcbn-MuiDataGrid-columnHeaderTitle': {
                                                color:'black',
                                                fontWeight: 600,
                                                fontSize:18
                                            }
                                        }}                                    
                                    />
                                </div>
                            </Grid>
                        </React.Fragment>
                    }

                    {optionFilter === "Conso. pointe carburant" && 
                        <Grid item xs={12} sm={12} md={12} sx={{mt:'-20px'}}>
                            <div className="card" style={{ height: 470, width: '100%',borderRadius:16 }}>
                                <DataGrid
                                    rows={[{id:1, type:"Gas-oil"}, {id:2, type:"Essence"}]}
                                    columns={columnsConsomPoint}
                                    elevation={10}
                                    sx={{
                                        boxShadow: 2,
                                        borderRadius:4,
                                        '.MuiDataGrid-columnSeparator': {
                                            display: 'none',
                                            },
                                            '&.MuiDataGrid-root': {
                                            border: 'none',
                                            },
                                        '.css-1jbbcbn-MuiDataGrid-columnHeaderTitle': {
                                            color:'black',
                                            fontWeight: 600,
                                            fontSize:18
                                        }
                                    }}                                    
                                />
                            </div>
                        </Grid>
                    }

                    {optionFilter === "Répartition des dépenses" && 
                        <Grid item xs={12} sm={12} md={12} sx={{mt:'-20px'}}>
                            <Card elevation={1}  sx={{p:4, backgroundColor:'#ffffff', borderRadius:3,}}>
                                <Typography color="inherit" sx={{color:"black", fontSize:20}}>Répartition des dépenses</Typography>
                                <Chart
                                    chartType="PieChart"
                                    data={data}
                                    options={optionsDepense}
                                    width={"100%"}
                                    height={"400px"}
                                />
                            </Card>
                        </Grid>
                    }
                </Grid>
            </React.Fragment>
        ) 
    }
}