import React, { Component } from "react";
import Login from "../../Pages/Login"
import Layout from "../../Layouts";

export default class App extends Component {
    render() {
        if (window.location.pathname !== "/") {
            return <Layout/>
        } else {
            return <Login />
        }
    }
}