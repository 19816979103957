import React, { Component } from "react"; 
import axios from 'axios';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { DataGrid } from '@mui/x-data-grid';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import DatePicker from 'react-date-picker';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import ReactToPrint from 'react-to-print';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import AddIcon from '@mui/icons-material/Add';
import PrintIcon from '@mui/icons-material/Print';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import Avatar from '@mui/material/Avatar';
import AnnouncementOutlinedIcon from '@mui/icons-material/AnnouncementOutlined';
import Box from '@mui/material/Box';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ProjetMenu  from "../../Layouts/ProjetMenu"; 
import CloseIcon from '@mui/icons-material/Close';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'white',
    color: 'black',
    maxWidth: 400,
    fontSize: 14,
    border: '1px solid #dadde9',
    },
}));

export default class Depense extends Component {

    constructor(props) {
        super(props)
        this.state = {
            projects: [],
            depenses: [],

            open:false,
            isDid: false,
            isNotDit:false,
            openEdit:false,

            projetId: this.props.projetId,
            id:null,
            start: new Date(),
            designation: "",
            montant:0
        }
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.handleEditerSubmit = this.handleEditerSubmit.bind(this)
        this.idchange = this.idchange.bind(this)        
    }

    componentDidMount() {
        const token = JSON.parse(localStorage.getItem("TOKEN"))
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };

        axios.get('https://api-ml.projectmanagementsolutions.net/api/depense', config)
        .then(response => {
            this.setState({ 
                projects: response.data.projects,
                depenses: response.data.depenses,
            });
        }).catch(error => {
            console.log("error", error)
        });
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleChangeDateStart(date){ this.setState({start: date})}

    handleSubmit(event) {
        event.preventDefault()
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);        
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };

        const datestart = Math.floor(new Date(this.state.start).getTime() / 1000)

        const bodyParameters = {
            id_projet: this.props.projetId,
            start: datestart, 
            designation: this.state.designation,
            montant: this.state.montant
        };

        axios.post( 
            'https://api-ml.projectmanagementsolutions.net/api/depense',
            bodyParameters,
            config
        ).then(response => {       
            console.log("return", response.data)     
            this.setState({ isDid: true, open:false });
            this.componentDidMount()            
        }).catch(error => {
            this.setState({ isNotDit: true });
            console.log("error", error)
        });
    }

    handleEditerSubmit(event) {
        event.preventDefault()
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);        
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };

        const datestart = Math.floor(new Date(this.state.start).getTime() / 1000)

        const bodyParameters = {
            id_projet: this.props.projetId,
            start: datestart, 
            designation: this.state.designation,
            montant: this.state.montant
        };

        axios.put( 
            'https://api-ml.projectmanagementsolutions.net/api/depense/' + this.state.id,
            bodyParameters,
            config
        ).then(response => {       
            console.log("return", response.data)     
            this.setState({ isDid: true, openEdit:false });
            this.componentDidMount()            
        }).catch(error => {
            this.setState({ isNotDit: true });
            console.log("error", error)
        });
    }

    edit(id){ 
        const caisse = this.state.depenses.filter(c => c["id"] === id)
        this.setState({
            id: id, 
            start: new Date(caisse[0].start *1000),
            designation: caisse[0].designation, 
            montant: caisse[0].montant,
            openEdit: true
        })
    }

    delete(id){
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);        
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };
        axios.delete( 
            'https://api-ml.projectmanagementsolutions.net/api/depense/' + id,
            config
        ).then(response => {                     
            this.setState({ isDid: true });
            this.componentDidMount()
            
        }).catch(error => {
            this.setState({ isNotDit: true });
            console.log("error", error)
        });
    }

    editTable(props){
        console.log('Ahmed', props)
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);        
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };    
        let bodyParameters = {}
        if(props.field === "fait"){
            const datestart = Math.floor(new Date(props.value).getTime() / 1000)
            bodyParameters = {
                id_projet: props.row.id_projet,
                start: datestart, 
                designation: props.row.designation,
                montant: props.row.montant
            }
        }else if(props.field === "designation"){ 
            bodyParameters = {
                id_projet: props.row.id_projet,
                start: props.row.start, 
                designation: props.value,
                montant: props.row.montant
            }
        }else if(props.field === "montant"){
            bodyParameters = {
                id_projet: props.row.id_projet,
                start: props.row.start, 
                designation: props.row.designation,
                montant: props.value
            }
        }
        axios.put( 
            'https://api-ml.projectmanagementsolutions.net/api/depense/' + props.id,
            bodyParameters,
            config
        ).then(response => {       
            console.log("return", response.data)     
            this.setState({ isDid: true });
            this.componentDidMount()            
        }).catch(error => {
            this.setState({ isNotDit: true });
            console.log("error", error)
        });
    }

    idchange(id){ 
        this.setState(
            {projetId: id}, () => this.props.onChange(id)
        )
    }

    render() {

        const {projetId, depenses, isDid, isNotDit, open, start, designation, montant, openEdit} = this.state 

        const id_project = projetId

        let depense = []
        if(id_project === null){
            depense = []
        }else{
            depense = depenses.filter(c => c["id_projet"] === id_project)
        }
        
        const displayCreation = (timestamp) => {
            var date = new Date(timestamp * 1000);
            return date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear()
        }

        const columns = [
            { 
                field: 'fait', 
                headerName: 'Fait',
                flex: 1/5,
                editable: true,
                type: 'date',
                renderCell: (cellValues) => {return  displayCreation(cellValues.row.start);}
            },
            { 
                field: 'designation', 
                headerName: 'Désignation',
                editable: true,
                flex: 3/5
            },
            { 
                field: 'montant', 
                headerName: 'Montant',
                editable: true,
                type: 'number',
                flex: 1/5,
                renderCell: (cellValues) => {return cellValues.row.montant.toLocaleString()}
            }, 
            { 
                field: 'editer', 
                headerName: '',
                flex: 1/10,
                renderCell: (cellValues) => {
                    return (
                        <Button
                            onClick={() => this.edit(cellValues.row.id)}
                            variant="contained"
                            sx={{background:'#eff0f2', color:'black', height:30, ':hover':{background:'#2f4cdd', color:'white'}}}
                        >
                            Modifier
                        </Button>
                    )
                }
            },
            { 
                field: 'supprimer', 
                headerName: '',
                flex: 1/10,
                renderCell: (cellValues) => {
                    return (
                        <Button
                            onClick={() => { if (window.confirm('Êtes-vous sûr de le supprimer ?')){ this.delete(cellValues.row.id) }}}
                            variant="contained"
                            sx={{background:'#eff0f2', color:'black', height:30, ':hover':{background:'#e6374c', color:'white'}}}
                        >
                            Supprimer
                        </Button>
                    )
                }
            }
        ]

        return (
            <React.Fragment> 
                <Grid sx={{ flexGrow: 1, mb:10}} container spacing={3} >
                    <Grid item xs={12} sm={6} md={2} >
                        <Button 
                            variant="contained" 
                            sx={{bgcolor:'#2f4cdd', textTransform: 'none', fontSize:16, height:50}} 
                            fullWidth
                            startIcon={<Avatar  sx={{ bgcolor: '#4777f4', height:28, width:28 }}><AddIcon sx={{fontSize:26}} /></Avatar>}
                            onClick={() => this.setState({ open: true })}
                        >
                            Ajouter une dépense
                        </Button>
                    </Grid>

                    <Grid item xs={12} sm={6} md={8} >
                        <Box style={{display: 'flex', flexDirection:'row'}}>
                            <ReactToPrint
                                trigger={() => {
                                    return  (
                                        <Button 
                                            variant="contained" 
                                            sx={{bgcolor:'#5d95ed', height:50}}
                                        >
                                            <PrintIcon sx={{fontSize:40}} /> 
                                        </Button>
                                    );
                                }}
                                content={() => this.componentRef}
                            />  

                            <Tooltip 
                                title={<React.Fragment>
                                    <Typography color="inherit" sx={{fontSize:20}} >Rapports de chantier</Typography>
                                    <Typography color="inherit" sx={{fontSize:16}} >
                                    Les rapports d'activités de chaque échelon sont rédigés chaque jour sur un forulaire conformement au prix du bordereau. 
                                    Un échelon peut produire plusieurs rapports par jour selon que les opérations éffectuées concernant plusieurs prix du bordereau. 
                                    Le rapport de chantier comportent les informations de date, de prix, de quantite executées, des matériels utilisés, du personnel, 
                                    des matériaux etc .....
                                    </Typography>
                                </React.Fragment>
                                }
                            >                        
                                <Button 
                                    variant="contained" 
                                    sx={{bgcolor:'#5d95ed', height:50, ml:3}}
                                >
                                    <AnnouncementOutlinedIcon/>
                                </Button>
                            </Tooltip> 
                        </Box>
                    </Grid>

                    <Grid item xs={12} sm={6} md={2} >
                        <ProjetMenu projetId={id_project} onChange={this.idchange} />
                    </Grid>

                    <Grid item xs={12} sm={6} md={12} >
                        <div className="card" style={{ height: 570, width: '100%',borderRadius:16 }}>
                            <DataGrid
                                rows={depense}
                                columns={columns}
                                onCellEditCommit={(props, event) => this.editTable(props)}
                                elevation={10}
                                sx={{
                                    boxShadow: 2,
                                    borderRadius:4,
                                    '.MuiDataGrid-columnSeparator': {
                                        display: 'none',
                                        },
                                        '&.MuiDataGrid-root': {
                                        border: 'none',
                                        },
                                    '.css-1jbbcbn-MuiDataGrid-columnHeaderTitle': {
                                        color:'black',
                                        fontWeight: 600,
                                        fontSize:18
                                    }
                                }}
                            />
                        </div>
                    </Grid>
                </Grid>

                <Dialog
                    fullWidth={true}
                    maxWidth={"sm"}
                    open={open}
                    onClose={() => this.setState({ open: false })}                    
                >   
                    <Box style={{display: 'flex', flexDirection:'row', justifyContent:'space-between', padding:32}} >
                        <Typography 
                            component="h1" 
                            variant="h1" 
                            sx={{color:"#313339", fontSize:26}}
                        >
                            Nouvelle dépense
                        </Typography>

                        <IconButton onClick={() =>  this.setState({ open: false })} >   
                            <CloseIcon /> 
                        </IconButton>
                    </Box>

                    <form role="form" className="text-start" onSubmit={this.handleSubmit}>
                        <DialogContent >
                            <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                                <Grid item xs={12} md={12}>
                                    <div className="input-group input-group-outline mb-4">  
                                        <InputLabel id="demo-simple-select-label" style={{marginTop:"-20px"}}>Date d'entrée</InputLabel>
                                        <DatePicker 
                                            selected={start}
                                            onChange={(date) => this.handleChangeDateStart(date)}
                                            value={start}
                                            id="demo-simple-select"
                                            style={{backgroundColor:'#e9eff6'}}
                                        />
                                    </div>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <div className="input-group input-group-outline mb-4">
                                        <TextField
                                            required
                                            id="outlined-required"
                                            label="Désignation"
                                            name="designation"                                            
                                            style={{ width: '100%' }}
                                            value={designation}
                                            onChange={this.handleChange}
                                            sx={{bgcolor:'#e9eff6'}}
                                        />
                                    </div>
                                </Grid>    

                                <Grid item xs={12} md={6}>
                                    <div className="input-group input-group-outline mb-4">
                                        <TextField
                                            required
                                            id="outlined-required"
                                            label="Montant"
                                            name="montant"
                                            type="number"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            style={{ width: '100%' }}
                                            value={montant}
                                            onChange={this.handleChange}
                                            sx={{bgcolor:'#e9eff6'}}
                                        />
                                    </div>
                                </Grid> 
                            </Grid>
                        </DialogContent >

                        <Button  
                            type="submit" 
                            fullWidth
                            variant="contained" 
                            sx={{
                                color:"white", 
                                textTransform: 'none', 
                                fontSize:20, 
                                height:50, 
                                backgroundColor:'#888da0',  
                                borderRadius:0, 
                                ':hover':{backgroundColor:'#2f4cdd'} 
                            }}
                            endIcon={<ChevronRightIcon sx={{fontSize:20}} />}
                        >
                            Cliquer pour enregistrer la nouvelle dépense
                        </Button>
                    </form>
                </Dialog>

                <Dialog
                    fullWidth={true}
                    maxWidth={"sm"}
                    open={openEdit}
                    onClose={() => this.setState({ openEdit: false })}                    
                >   
                   <Box style={{display: 'flex', flexDirection:'row', justifyContent:'space-between', padding:32}} >
                        <Typography 
                            component="h1" 
                            variant="h1" 
                            sx={{color:"#313339", fontSize:26}}
                        >
                            Modifier une dépense
                        </Typography>

                        <IconButton onClick={() =>  this.setState({ openEdit: false })} >   
                            <CloseIcon /> 
                        </IconButton>
                    </Box>

                    <form role="form" className="text-start" onSubmit={this.handleEditerSubmit}>
                        <DialogContent >
                            <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                                <Grid item xs={12} md={12}>
                                    <div className="input-group input-group-outline mb-4">  
                                        <InputLabel id="demo-simple-select-label" style={{marginTop:"-20px"}}>Date d'entrée</InputLabel>
                                        <DatePicker 
                                            selected={start}
                                            onChange={(date) => this.handleChangeDateStart(date)}
                                            value={start}
                                            id="demo-simple-select"
                                            sx={{bgcolor:'#e9eff6'}}
                                        />
                                    </div>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <div className="input-group input-group-outline mb-4">
                                        <TextField
                                            required
                                            id="outlined-required"
                                            label="Désignation"
                                            name="designation"                                            
                                            style={{ width: '100%' }}
                                            value={designation}
                                            onChange={this.handleChange}
                                            sx={{bgcolor:'#e9eff6'}}
                                        />
                                    </div>
                                </Grid>    

                                <Grid item xs={12} md={6}>
                                    <div className="input-group input-group-outline mb-4">
                                        <TextField
                                            required
                                            id="outlined-required"
                                            label="Montant"
                                            name="montant"
                                            type="number"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            style={{ width: '100%' }}
                                            value={montant}
                                            onChange={this.handleChange}
                                            sx={{bgcolor:'#e9eff6'}}
                                        />
                                    </div>
                                </Grid> 
                            </Grid>
                        </DialogContent >

                        <Button  
                            type="submit" 
                            fullWidth
                            variant="contained" 
                            sx={{
                                color:"white", 
                                textTransform: 'none', 
                                fontSize:20, 
                                height:50, 
                                backgroundColor:'#888da0',  
                                borderRadius:0, 
                                ':hover':{backgroundColor:'#2f4cdd'} 
                            }}
                            endIcon={<ChevronRightIcon sx={{fontSize:20}} />}
                        >
                            Cliquer pour modifier une dépense
                        </Button>
                    </form>
                </Dialog>

                <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={isDid} autoHideDuration={6000} onClose={() => this.setState({ isDid: false })} >
                    <Alert severity="success" sx={{ width: '100%' }} onClose={() => this.setState({ isDid: false })} >
                        Ajouté avec succès
                    </Alert>
                </Snackbar>

                <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={isNotDit} autoHideDuration={6000} onClose={() => this.setState({ isNotDit: false })} >
                    <Alert severity="error" sx={{ width: '100%' }} onClose={() => this.setState({ isNotDit: false })} >
                        Erreur d'enregistrement
                    </Alert>
                </Snackbar>
            </React.Fragment> 
        )
    }

}