import React, { Component } from "react";
import { DataGrid } from '@mui/x-data-grid';
import Grid from '@mui/material/Grid';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import axios from "axios";
import CircularProgress, { circularProgressClasses} from '@mui/material/CircularProgress';
import ProjetMenu  from "../../Layouts/ProjetMenu";
import ProjetPeriode from "../../Layouts/ProjetPeriode"
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';

export default class Efficiences extends Component {

    constructor(props) {
        super(props)
        this.state = {
            materiels: [],
            projects: [],
            rapports: [],
            rapport_materiels: [],

            projetId: this.props.projetId,
            start: this.props.startDate,
            end: this.props.endDate,

            id_materiel: null,

            optionEngin: "Tous les engins",
            anchorEl: null,
        }
        this.handleChange = this.handleChange.bind(this)
        this.idchange = this.idchange.bind(this)
        this.Datechange = this.Datechange.bind(this)
        this.handleClick= this.handleClick.bind(this)
    }    

    componentDidMount() {
        const token = JSON.parse(localStorage.getItem("TOKEN"))
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };
        axios.get('https://api-ml.projectmanagementsolutions.net/api/rapport', config)
        .then(response => {
            this.setState({ 
                materiels: response.data.materiels,
                projects: response.data.projects,
                rapports: response.data.rapports,
                rapport_materiels: response.data.rapport_materiels
            });
        }).catch(error => {
            console.log("error", error)
        });
    }

    idchange(id){  
        let projet = this.state.projects.filter(c => c["id"] === id)[0]

        this.setState(
            { 
                projetId: id,
                start: new Date(projet.start *1000),
                end: new Date(projet.close *1000),
            },
            () => this.props.onChange(id)
        )
    }

    Datechange(startDate, endDate){
        this.setState({start: startDate, end: endDate})
    }

    handleClick(event){
        this.setState({anchorEl: event.currentTarget});
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    render() { 

        const {start, end, anchorEl, optionEngin, id_materiel, projects, rapports, rapport_materiels} = this.state

        const id_project = this.props.projetId

        const materiels = this.state.materiels.filter(c => c["id_projet"] === id_project)

        let materiel = []
        if(id_project === null){
            materiel = []
        }else{
            materiel = materiels.filter(c => c["id_projet"] === id_project)
        }

        let name = ""
        let pays= ""
        let curency = ""
        let hoursbyday = 0
        let localite = ""
        if(projects.filter(c => c["id"] === id_project)[0] !== undefined){
            name = projects.filter(c => c["id"] === id_project)[0].name
            pays= projects.filter(c => c["id"] === id_project)[0].country
            curency = projects.filter(c => c["id"] === id_project)[0].currency
            hoursbyday = projects.filter(c => c["id"] === id_project)[0].hoursbyday
            localite = projects.filter(c => c["id"] === id_project)[0].location
        }

        let gm = ""
        let type = ""
        let chassis= ""
        let owner = ""
        if(materiels.filter(c => c["id"] === id_materiel)[0] !== undefined){
            gm = materiels.filter(c => c["id"] === id_materiel)[0].gm
            type= materiels.filter(c => c["id"] === id_materiel)[0].type
            chassis = materiels.filter(c => c["id"] === id_materiel)[0].chassis
            owner = materiels.filter(c => c["id"] === id_materiel)[0].owner
        }

        const b = rapports.filter(c => c["fait"] >= Math.floor(new Date(start).getTime() / 1000))
        const c = b.filter(c => c["fait"] <= Math.floor(new Date(end).getTime() / 1000) + 86400)
        let rapport = c

        const rapport_materiel = () => {
            let resultat = []
            let item = []
            resultat = rapport_materiels.filter(c => c["id_materiel"] === id_materiel)
            resultat.map((answer) => {
                const c = rapport.filter(c => c["id"] === answer.id_rapport)
                if(rapport.filter(c => c["id"] === answer.id_rapport)[0] !== undefined){
                    item = item.concat(answer)
                }               
            })
            return item
        }

        const rrr = (id_rapport) => {
            const a = rapports.filter(c => c["id"] === id_rapport)
            if(a[0] !== undefined){
                return   displayCreation(a[0].fait)
            }else{
                return 0
            }
        }

        const displayCreation = (timestamp) => {
            var date = new Date(timestamp * 1000);
            return date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear()
        }

        const columnEngin = [
            { 
                field: 'fait', 
                headerName: 'Date',
                flex: 1/6,
                renderCell: (cellValues) => {return  rrr(cellValues.row.id_rapport)}
            },
            { 
                field: 'heures', 
                headerName: "Heures de marche",
                flex: 1/6,
                type: 'number',
                renderCell: (cellValues) => {return  (cellValues.row.hj).toLocaleString()}
            },
            { 
                field: 'heuress', 
                headerName: "Heures de panne",
                flex: 1/6,
                type: 'number',
                renderCell: (cellValues) => {return  (cellValues.row.arret).toLocaleString()}
            },
            { 
                field: 'mis', 
                headerName: "Mise à disposition",
                flex: 1/6,
                type: 'number',
                renderCell: (cellValues) => {return  displayMis(cellValues.row.id).toLocaleString() + "%"}
            },
            { 
                field: 'util', 
                headerName: "Utilisation",
                type: 'number',
                flex: 1/6,
                renderCell: (cellValues) => {return  ((cellValues.row.hj * 100)/hoursbyday).toLocaleString() + "%"}
            },
            { 
                field: 'taux', 
                headerName: 'Prix',
                type: 'number',
                flex: 1/8,
                renderCell: (cellValues) => {return  (cellValues.row.taux).toLocaleString()}
            },
            { 
                field: 'montant', 
                headerName: 'Montant',
                type: 'number',
                flex: 1/8,
                renderCell: (cellValues) => {return  (cellValues.row.hj * cellValues.row.taux).toLocaleString()}
            },

        ]

        const displaymontantTotal =  () => {
            let resultat = 0
            rapport_materiel().map((answer) => {
                resultat = resultat + (answer.taux * answer.hj)
            })
            return resultat
        }
        

        const displayMis =  (id) => {
            let resultat = 0
            const c = rapport_materiel().filter(c => c["id"] === id)
            if(c[0] !== undefined){
                resultat = 100 - ((c[0].arret * 100)/hoursbyday)              
            }
            return (resultat)
        }

        const sss = () => {
            let resultat = 0
            let n = 0
            const c = rapport_materiel()
            if(c[0] !== undefined){
                c.map((answer) => {
                    resultat = resultat + (answer.hj)
                    n = n + 1
                })         
            }

            if(resultat === 0){
                return resultat
            }else{
                return (resultat/n).toFixed(0)
            }            
        }

        const displayHpanne = () => {
            let resultat = 0
            let n = 0
            const c = rapport_materiel()
            if(c[0] !== undefined){
                c.map((answer) => {
                    resultat = resultat + (answer.arret)
                    n = n + 1
                })         
            }
            if(resultat === 0){
                return resultat
            }else{
                return (resultat/n).toFixed(0)
            }            
        }

        const displayHdispo = () => {
            let resultat = 0
            let n = 0
            const c = rapport_materiel()
            if(c[0] !== undefined){
                c.map((answer) => {
                    resultat = resultat + 100 - ((answer.arret * 100)/hoursbyday) 
                    n = n + 1
                })         
            }
            if(resultat === 0){
                return resultat
            }else{
                return (resultat/n).toFixed(0)
            }            
        }

        const displayHutile = () => {
            let resultat = 0
            let n = 0
            const c = rapport_materiel()
            if(c[0] !== undefined){
                c.map((answer) => {
                    resultat = resultat + ((answer.hj * 100)/hoursbyday)
                    n = n + 1
                })         
            }
            if(resultat === 0){
                return resultat
            }else{
                return (resultat/n).toFixed(0)
            }            
        }

        const columnMateriel = [
            { 
                field: 'gm', 
                headerName: 'Machine',
                flex: 1/8,
                renderCell: (cellValues) => {return  cellValues.row.gm }
            },
            { 
                field: 'type', 
                headerName: 'Modèle',
                flex: 1/8,
                renderCell: (cellValues) => {return  cellValues.row.type }
            },
            { 
                field: 'chassis', 
                headerName: 'N° Chassis',
                flex: 1/8,
                renderCell: (cellValues) => {return   cellValues.row.chassis  }
            },
            { 
                field: 'owner', 
                headerName: 'Propriétaire',
                flex: 1/8,
                renderCell: (cellValues) => {return  cellValues.row.owner }
            },
            { 
                field: 'jour', 
                headerName: "Nombre de jours travaillés",
                flex: 1/8,
                type: 'number',
                renderCell: (cellValues) => {return  displayJour(cellValues.row.id).toLocaleString() }
            },
            { 
                field: 'heures', 
                headerName: "Heures de marche",
                flex: 1/8,
                type: 'number',
                renderCell: (cellValues) => {return  displayH(cellValues.row.id).toLocaleString()}
            },
            { 
                field: 'heuress', 
                headerName: "Heures de panne",
                flex: 1/8,
                type: 'number',
                renderCell: (cellValues) => {return  displayHPanne(cellValues.row.id).toLocaleString()}
            },
            { 
                field: 'heuresss', 
                headerName: "Mise à disposition",
                flex: 1/8,
                type: 'number',
                renderCell: (cellValues) => {return (100 - (((displayHPanne(cellValues.row.id)/displayJour(cellValues.row.id)) * 100)/hoursbyday) ).toLocaleString() + "%"}
            },
            { 
                field: 'heuressd', 
                headerName: "Utilisation",
                flex: 1/12,
                type: 'number',
                renderCell: (cellValues) => {return (((displayH(cellValues.row.id)/displayJour(cellValues.row.id)) * 100)/hoursbyday).toLocaleString() + "%"}
            },  
            { 
                field: 'taux', 
                headerName: 'Prix',
                flex: 1/16,
                type: 'number',
                renderCell: (cellValues) => {return  (cellValues.row.taux).toLocaleString()}
            },
            { 
                field: 'montant', 
                headerName: 'Montant',
                flex: 1/8,
                type: 'number',
                renderCell: (cellValues) => {return  (displayH(cellValues.row.id) * cellValues.row.taux).toLocaleString()}
            },
        ]

        const displayHPanne =  (id_materiel) => {
            let resultat = []
            let item = []
            let items = 0   
            resultat = rapport_materiels.filter(c => c["id_materiel"] === id_materiel)
            resultat.map((answer) => {
                const c = rapport.filter(c => c["id"] === answer.id_rapport)
                if(rapport.filter(c => c["id"] === answer.id_rapport)[0] !== undefined){
                    item = item.concat(answer)
                }               
            })

            item.map((answer) => {                
                items = items + answer.arret 
            })
            return items
        }

        const displayH =  (id_materiel) => {
            let resultat = []
            let item = []
            let items = 0   
            resultat = rapport_materiels.filter(c => c["id_materiel"] === id_materiel)
            resultat.map((answer) => {
                const c = rapport.filter(c => c["id"] === answer.id_rapport)
                if(rapport.filter(c => c["id"] === answer.id_rapport)[0] !== undefined){
                    item = item.concat(answer)
                }               
            })

            item.map((answer) => {                
                items = items + answer.hj 
            })
            return items
        }

        const displayJour =  (id_materiel) => {
            let resultat = []
            let item = []
            let items = 0      
            resultat = rapport_materiels.filter(c => c["id_materiel"] === id_materiel)

            resultat.map((answer) => {
                const c = rapport.filter(c => c["id"] === answer.id_rapport)
                c.map((sss) => {
                    item = item.concat(sss)
                })       
            })
            let pp = item.filter( (ele, ind) => ind === item.findIndex(elem => elem.id === ele.id))
            pp = pp.filter( (ele, ind) => ind === pp.findIndex(elem => elem.fait === ele.fait))

            console.log("rrr", pp)
            return pp.length 
        } 

        const  CircularProgressWithLabel = () =>{
            return (
              <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                <CircularProgress 
                    variant="determinate"
                    size={100} 
                    value={displayHdispo()} 
                    thickness={3} 
                    style={{
                        color: '#2f4cdd',
                        [`& .${circularProgressClasses.circle}`]: {
                            strokeLinecap: 'round',
                        },
                    }} 
                />

                <Box
                  sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Typography
                    variant="caption"
                    component="div"
                    color="text.secondary"
                  >{`${Math.round(displayHdispo())}%`}</Typography>
                </Box>
              </Box>
            );
        }

        const CircularProgressWithLabel2 = () =>{
            return (
              <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                <CircularProgress 
                    variant="determinate" 
                    size={100} 
                    value={displayHutile()} 
                    thickness={3} 
                    style={{
                        color: '#2f4cdd',
                        [`& .${circularProgressClasses.circle}`]: {
                            strokeLinecap: 'round',
                        },
                    }} 
                />

                <Box
                  sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Typography
                    variant="caption"
                    component="div"
                    color="text.secondary"
                  >{`${Math.round(displayHutile())}%`}</Typography>
                </Box>
              </Box>
            );
        }

        return ( 
            <React.Fragment>
                <Grid sx={{ flexGrow: 1, mb:2 }} container spacing={6} >
                    <Grid item xs={12} sm={6}  md={5}>
                        <Typography sx={{color:'#46505b', fontSize:28 }}>{pays}, {localite}</Typography>

                        <Typography 
                            component="h1" 
                            variant="h1" 
                            sx={{color:"black", fontWeight: 400, fontSize:24}}
                        >
                            {name.substring(0, 45)}
                        </Typography>                                              
                    </Grid>  

                    <Grid item xs={12} sm={6} md={2}>
                        <ProjetMenu projetId={id_project} onChange={this.idchange} />
                    </Grid>

                    <Grid item xs={12} sm={6} md={3}>
                        <ProjetPeriode startDate={start}  endDate={end} onChange={this.Datechange}/>
                    </Grid>

                    <Grid item xs={12} sm={6} md={2}>
                        <Card elevation={1}  sx={{height:70, p:2, backgroundColor:'#ffffff', borderRadius:3, display: 'flex', alignItems: 'center',}}>
                            <Box sx={{display: 'flex', flexDirection:'column', alignItems: 'left', }} >
                                <Typography sx={{color:'#46505b', pl:1}}>{optionEngin}</Typography>
                            </Box>

                            <Box sx={{ flexGrow: 1 }} />

                            <IconButton onClick={this.handleClick} >
                                <ExpandMoreIcon />
                            </IconButton>

                            <Menu
                                elevation={12}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={() => this.setState({anchorEl: null}) }
                                PaperProps={{
                                    sx: {
                                        //width: 300,
                                        //backgroundColor:'gray',                                    
                                    }
                                }}
                            >
                                <MenuItem 
                                    disableRipple 
                                    onClick={() => this.setState({optionEngin: "Tous les engins", anchorEl: null })} 
                                    sx={optionEngin === "Tous les engins" ? {color:'#2f4cdd'} : null}
                                >
                                    Tous les engins
                                </MenuItem>

                                <MenuItem 
                                    disableRipple 
                                    onClick={() => this.setState({optionEngin: "Un engin", anchorEl: null })} 
                                    sx={optionEngin === "Un engin" ? {color:'#2f4cdd'} : null}
                                >
                                    Un engin
                                </MenuItem>
                            </Menu>
                        </Card>
                    </Grid>

                    {optionEngin === "Un engin" &&
                        <React.Fragment >
                            <Grid item xs={12} sm={6} md={5} sx={{mt:'-20px'}} />

                            <Grid item xs={12} sm={6} md={2} sx={{mt:'-20px'}}>
                                <FormControl  style={{ width: '100%'}}>
                                    <div className="card" style={{ width: '100%'}} >
                                        <InputLabel id="demo-simple-select-helper-label">Machine</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-helper-label"
                                            id="demo-simple-select-helper"
                                            value={id_materiel || ''}
                                            style={{ width: '100%' }}
                                            onChange={this.handleChange}
                                            name="id_materiel"
                                            sx={{bgcolor:'#ffffff'}}
                                        >        
                                            {materiel.map((answer) => { 
                                                return  <MenuItem value={answer.id}>{answer.gm}</MenuItem>
                                            })} 
                                    </Select>
                                    </div>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={6} md={3} sx={{mt:'-20px'}}>
                                <FormControl  style={{ width: '100%'}}>
                                    <div className="card" style={{ width: '100%'}} >
                                        <InputLabel id="demo-simple-select-helper-label">Modèle</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-helper-label"
                                            id="demo-simple-select-helper"
                                            value={id_materiel || ''}
                                            style={{ width: '100%' }}
                                            onChange={this.handleChange}
                                            name="id_materiel"
                                            sx={{bgcolor:'#ffffff'}}
                                        >        
                                            {materiel.map((answer) => { 
                                                return  <MenuItem value={answer.id}>{answer.type}</MenuItem>
                                            })} 
                                    </Select>
                                    </div>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={6} md={2} sx={{mt:'-20px'}}>
                                <FormControl  style={{ width: '100%'}}>
                                    <div className="card" style={{ width: '100%'}} >
                                        <InputLabel id="demo-simple-select-helper-label">N° Chassis</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-helper-label"
                                            id="demo-simple-select-helper"
                                            value={id_materiel || ''}
                                            style={{ width: '100%' }}
                                            onChange={this.handleChange}
                                            name="id_materiel"
                                            sx={{bgcolor:'#ffffff'}}
                                        >        
                                            {materiel.map((answer) => { 
                                                return  <MenuItem value={answer.id}>{answer.chassis}</MenuItem>
                                            })} 
                                    </Select>
                                    </div>
                                </FormControl>
                            </Grid>
                        </React.Fragment>
                    }

                    <Grid item xs={12} md={12}><Typography color="inherit" sx={{color:"black", fontSize:20}}>Temps de travail par jour: {hoursbyday}</Typography></Grid>
                    
                    {optionEngin === "Tous les engins" &&
                        <Grid item xs={12} sm={12} md={12} sx={{mt:'-20px'}}>
                            <div className="card" style={{ height: 470, width: '100%',borderRadius:16 }}>
                                <DataGrid
                                    rows={materiels}
                                    columns={columnMateriel}
                                    elevation={10}
                                    sx={{
                                        boxShadow: 2,
                                        borderRadius:4,
                                        '.MuiDataGrid-columnSeparator': {
                                            display: 'none',
                                            },
                                            '&.MuiDataGrid-root': {
                                            border: 'none',
                                            },
                                        '.css-1jbbcbn-MuiDataGrid-columnHeaderTitle': {
                                            color:'black',
                                            fontWeight: 600,
                                            fontSize:18
                                        }
                                    }}                                    
                                />
                            </div>
                        </Grid>
                    }

                    {optionEngin === "Un engin" &&
                        <React.Fragment >
                            <Grid item xs={12} sm={12} md={6} align="center" sx={{mt:'-20px'}}>
                                <Card elevation={1}  sx={{p:4, backgroundColor:'#ffffff', borderRadius:3,}}>
                                    <h6>Mise à disposition</h6>
                                    <CircularProgressWithLabel  />
                                </Card>
                            </Grid>

                            <Grid item xs={12} sm={12} md={6} align="center" sx={{mt:'-20px'}}>
                                <Card elevation={1}  sx={{p:4, backgroundColor:'#ffffff', borderRadius:3,}}>
                                    <h6>Utilisation</h6>
                                    <CircularProgressWithLabel2  />
                                </Card>
                            </Grid>

                            <Grid item xs={12} sm={12} md={12} sx={{mt:'-20px'}}>
                                <div className="card" style={{ height: 470, width: '100%',borderRadius:16 }}>
                                    <DataGrid
                                        rows={rapport_materiel()}
                                        columns={columnEngin}
                                        elevation={10}
                                        sx={{
                                            boxShadow: 2,
                                            borderRadius:4,
                                            '.MuiDataGrid-columnSeparator': {
                                                display: 'none',
                                                },
                                                '&.MuiDataGrid-root': {
                                                border: 'none',
                                                },
                                            '.css-1jbbcbn-MuiDataGrid-columnHeaderTitle': {
                                                color:'black',
                                                fontWeight: 600,
                                                fontSize:18
                                            }
                                        }}                                    
                                    />
                                </div>
                            </Grid>    
                        </React.Fragment>
                    }
                </Grid>
            </React.Fragment>
        )
    }
}